import React from 'react';
import { Card } from 'react-bootstrap';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate } from '../../../../utils/functions';
import type { CustomerDetailState } from '../CustomerDetailPage';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
}

export type TestIds = keyof State | 'infection-button';

export const SocialInfoCard: React.FC<Props> = ({ customerData }) => {
  const testid = createTestId<TestIds>(SocialInfoCard);
  const { state: $info } = useLargeState<State>(customerData);

  return (
    <Card className="mt-2" style={{ width: '49%' }}>
      <div className="d-flex justify-content-between mt-2">
        <h6>ソーシャルログイン</h6>
      </div>

      {$info.socialLoginInformationOutputs?.map((socialLogin, index) => {
        return (
          <div className="m-2" key={index}>
            <small className="d-block" data-testid="socialLogin">
              ソーシャルログイン先 : {socialLogin.socialLogin}
            </small>
            <small className="text-secondary d-block" data-testid="socialLoginCooperationAt">
              連携日時 :
              {socialLogin.socialLoginCooperationAt
                ? formatISODate(socialLogin.socialLoginCooperationAt, 'yyyy/MM/dd HH:mm:ss')
                : ''}
            </small>
            <small className="text-secondary d-block" data-testid="socialLoginAccount">
              ソーシャルログインアカウントID :{socialLogin.socialLoginAccount}
            </small>
          </div>
        );
      })}
      <small>流入元</small>
      <p data-testid={testid('inflowId')}>{$info.inflowId}</p>
    </Card>
  );
};
