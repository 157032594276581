import React, { useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Button, Form, Row } from 'react-bootstrap';
import {
  CustomerTicketApi,
  CustomerTicketCreateFormResponse,
  CustomerTicketReturnCreateFormResponse,
  IncResultOutputResponse,
} from '../../../../api-client';
import { BillingCommonInputTextWithLabel } from '../../../molecules/BillingCommonInputTextWithLabel';
import { BillingCommonInputSelectWithLabel } from '../../../molecules/BillingCommonInputSelectWithLabel';
import { BillingUpdateResultModal } from '../../../organisms/Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../../BillingConstants';
import { CustomerTicketCreateModal } from '../../../organisms/Modal/CustomerTIcketCreateModal';

export interface SearchConditions {
  shopIds?: string;
  monitorBaseIds?: string;
  applyIds?: string;
  customerTicketReturnRequestStatusCode?: number;
  customerTicketHistoryReasonId?: number;
}

const CUSTOMER_TICKET_TYPE = [
  { id: 1, name: '有料' },
  { id: 2, name: '無料' },
];

export const CustomerTicketCreateForm: React.VFC = () => {
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const customerTicketApi = new CustomerTicketApi();
  const { handleSubmit } = useFormContext<CustomerTicketReturnCreateFormResponse[]>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const fetchData = (data: CustomerTicketCreateFormResponse) => {
    customerTicketApi.customerTicketCreate(data).then((res) => {
      setUpdResult(res.data);
    });
  };

  const onSubmit: SubmitHandler<CustomerTicketCreateFormResponse> = (data) => {
    setIsOpenModal(true);
  };

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      {isOpenModal && (
        <CustomerTicketCreateModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} fetchData={fetchData} />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-2 h2">有料チケット付与</Row>
        <Row>
          <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
            registerName="customerIds"
            labelName="ユーザーID(カンマ区切りで複数可)"
            required
            colNumber={12}
            className="mb-3"
          />
        </Row>
        <Row>
          <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
            registerName="expireAt"
            labelName="利用期限(未入力で1年後)"
            type="date"
            colNumber={12}
            className="mb-3"
            isBeforeNow
          />
        </Row>
        <Row>
          <BillingCommonInputSelectWithLabel<CustomerTicketCreateFormResponse>
            registerName="customerTicketTypeCode"
            labelName="チケットタイプ"
            options={CUSTOMER_TICKET_TYPE}
            required
            colNumber={12}
            className="mb-3"
          />
        </Row>
        <Row>
          <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
            registerName="num"
            labelName="枚数(1度に付与できる上限は5枚)"
            type="number"
            colNumber={12}
            className="mb-3"
            required
            max={5}
          />
        </Row>
        <Row>
          <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
            registerName="commentForInternal"
            labelName="付与理由"
            required
            colNumber={12}
            className="mb-3"
          />
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button variant="secondary" style={{ margin: '20px' }} type="submit">
            登録
          </Button>
        </div>
      </Form>
    </>
  );
};
