import React, { useEffect } from 'react';
import { ListGroup, Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { createTestId } from '../../utils/functions';
import { useLargeState } from '../../hooks/useLargeState';
import { IncCategoryMenuListApi, IncCategoryMenuListOutputResponse } from '../../api-client';

export interface State {
  api: IncCategoryMenuListApi;
  list: IncCategoryMenuListOutputResponse[];
}

export interface Props {
  category: number;
}

export const CategoryTopPage: React.VFC<Props> = ({ category }) => {
  const testid = createTestId(CategoryTopPage);
  const history = useHistory();
  const location = useLocation();

  const { state: $, mergeState } = useLargeState<State>({
    api: new IncCategoryMenuListApi(),
    list: [],
  });

  useEffect(() => {
    if (!location.pathname) return;
    // TODO: location.pathnameに応じてAPIに渡す社内カテゴリIDの値を決定する
    $.api.incCategoryMenuList(category).then((res: AxiosResponse<IncCategoryMenuListOutputResponse[]>) => {
      mergeState({ list: res.data });
    });
  }, [$.api, location, mergeState]);

  const handleTransition = (url: string) => {
    history.push({ pathname: url.startsWith('/') ? url : `/${url}` });
  };

  return (
    <>
      <div className="d-flex flex-wrap gap-4">
        {$.list.map(({ menuGroupName, menus }) => (
          <Card key={menuGroupName} style={{ width: 'calc(33.333% - 1rem)', height: '100%' }}>
            <Card.Header className="fw-bold">{menuGroupName}</Card.Header>
            <ListGroup variant="flush" data-testid={testid('listGroup')}>
              {menus.map(({ id, name, url }) => (
                <ListGroup.Item action key={id} onClick={() => handleTransition(url)}>
                  {name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        ))}
      </div>
    </>
  );
};
