import { faArrowLeft, faArrowRight, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerTagListApi,
  F4DetailInfoApi,
  F4DetailInfoOutputResponse,
  F4DetailUpdateApi,
  F4DetailUpdateFormResponse,
  F4TestDeliveryApi,
  F4TestDeliveryFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  MailMagazinePrefectureFormResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { formatISODate } from '../../../utils/functions';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';

const streamingModeTypeList = [
  {
    id: 0,
    name: '本番配信',
  },
  {
    id: 1,
    name: '配信停止',
  },
];

export const F4MailDetailModifyPage = () => {
  const location = useLocation<{ id: number }>();
  const history = useHistory();

  const infoApi = new F4DetailInfoApi();
  const updateApi = new F4DetailUpdateApi();
  const testApi = new F4TestDeliveryApi();
  const commonMasterListApi = new CommonMasterListApi();
  const customerTagListApi = new CustomerTagListApi();

  const [data, setData] = useState<F4DetailInfoOutputResponse>({
    memo: '',
    title: '',
    topTitle: '',
    bannerText: '',
    bannerHTML: '',
    sendScheduleTimeStamp: '',
    areaList: [],
    itemList1Title: '今おすすめの注目モニター',
    itemList1: '',
    itemList2Title: '新着モニター',
    itemList2: '',
    itemList3Title: '期間限定モニター',
    itemList3: '',
    itemList4Title: '特集',
    itemList4: '',
    customerTagList: { customerTagList: [] },
    sendInvisibleConflictFlg: false,
    sendMode: 0,
    testMailaddressList: [],
    memberRegistrationDateStart: '',
    memberRegistrationDateEnd: '',
  });

  // 配信日時（日付）
  const [streamingDay, setStreamingDay] = useState<string>();
  // 配信日時（時間）
  const [streamingTime, setStreamingTime] = useState<string>();
  // テスト配信
  const [testStreamingText, setTestStreamingText] = useState<string>();

  // 初期値を保存するstate
  const [initialStreamingDay, setInitialStreamingDay] = useState<string>();
  const [initialStreamingTime, setInitialStreamingTime] = useState<string>();

  // 都道府県一覧
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  // 会員属性一覧
  const [customerTagList, setCustomerTagList] = useState<MailMagazineCustomerTagOutputResponse>({
    customerTagList: [],
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [testResult, setTestResult] = useState<IncResultOutputResponse>();

  const [validated, setValidated] = useState(false);
  const [validErrMsg, setValidErrMsg] = useState<string | undefined>();

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };
  const diffInitial = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours());
    return parsedate;
  };

  useEffect(() => {
    // 詳細画面に直接アクセスされた場合はエラー画面に遷移させる。
    if (location.pathname === '/keisai/f4-mail-detail' && location.state === undefined) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (location.state !== undefined) {
      infoApi.f4DetailInfo(location.state.id).then((res: AxiosResponse<F4DetailInfoOutputResponse>) => {
        if (res.data?.sendScheduleTimeStamp) {
          setStreamingDay(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'yyyy-MM-dd')
          );
          setStreamingTime(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'HH:mm:ss')
          );
        } else {
          const now = new Date();
          const nowDay = formatISODate(diffInitial(now).toISOString(), 'yyyy-MM-dd');
          const nowTime = formatISODate(diffInitial(now).toISOString(), 'HH:mm:ss');
          setStreamingDay(nowDay);
          setStreamingTime(nowTime);

          // 初期値の保存（バリデーションに使用する）
          setInitialStreamingDay(nowDay);
          setInitialStreamingTime(nowTime);
        }

        // テスト配信テキストの加工
        if (res.data.testMailaddressList) {
          for (let index = 0; index < res.data.testMailaddressList.length; index += 1) {
            if (res.data.testMailaddressList.length !== index + 1) {
              res.data.testMailaddressList[index] = `${res.data.testMailaddressList[index]}\n`;
            }
          }
          setTestStreamingText(res.data.testMailaddressList.join(''));
        }
        setData(res.data);
      });
    } else {
      const now = new Date();
      const nowDay = formatISODate(diffInitial(now).toISOString(), 'yyyy-MM-dd');
      const nowTime = formatISODate(diffInitial(now).toISOString(), 'HH:mm:ss');
      setStreamingDay(nowDay);
      setStreamingTime(nowTime);

      // 初期値の保存（バリデーションに使用する）
      setInitialStreamingDay(nowDay);
      setInitialStreamingTime(nowTime);
    }

    commonMasterListApi.commonMasterList('prefecture').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      setPrefectures(res.data);
    });

    customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
      const filteredCustomerTagList = res.data.customerTagList.filter(
        (output) => output.tagId !== 5 && output.tagId !== 8 && output.tagId !== 9
      );

      setCustomerTagList({
        customerTagList: filteredCustomerTagList,
      });
    });

    if (streamingDay == null) {
      // 初期値
      const now = new Date();
      const nowDay = formatISODate(diffInitial(now).toISOString(), 'yyyy-MM-dd');
      const nowTime = formatISODate(diffInitial(now).toISOString(), 'HH:mm:ss');
      setStreamingDay(nowDay);
      setStreamingTime(nowTime);

      // 初期値の保存（新規の場合のバリデーションに使用する）
      setInitialStreamingDay(nowDay);
      setInitialStreamingTime(nowTime);
    }
  }, []);

  // 会員属性Select Changeイベント
  const onChangeCustomerSelect = (selectedContents: SingleValue<{ tagId: number; tagName: string }>, ind: number) => {
    if (selectedContents !== null) {
      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
      const targetSelectionList = customerTagList.customerTagList
        .find((r) => {
          return r.tagId === selectedContents.tagId;
        })
        ?.customerTagAnswerList.slice(0, 1);

      if (targetSelectionList) {
        setData({
          ...data,
          customerTagList: {
            customerTagList: addList.customerTagList.map((_, index) => {
              return index === ind
                ? {
                    ..._,
                    tagId: selectedContents.tagId,
                    tagName: selectedContents.tagName,
                    customerTagAnswerList: targetSelectionList,
                  }
                : { ..._ };
            }),
          },
        });
      }
    }
  };

  // 保存イベント
  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidErrMsg(undefined);

    setUpdResult(undefined);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    // 配信日時
    if (streamingDay == null || streamingDay === '') {
      event.stopPropagation();
      setValidErrMsg('配信日時を指定してください。');
      return;
    }
    if (initialStreamingDay != null) {
      // 配信日時のバリデーション
      if (streamingDay === initialStreamingDay && streamingTime === initialStreamingTime) {
        event.stopPropagation();
        setValidErrMsg('配信日時がデフォルト値のままです。変更してください。');
        return;
      }
    }

    // 商品１～４のどれかは入力が必要。
    if (
      (data.itemList1 === '' || data.itemList1 == null) &&
      (data.itemList2 === '' || data.itemList2 == null) &&
      (data.itemList3 === '' || data.itemList3 == null) &&
      (data.itemList4 === '' || data.itemList4 == null)
    ) {
      event.stopPropagation();
      setValidErrMsg('商品リスト1～4のいずれかを指定してください。');
      return;
    }

    // 商品リスト1～4
    let itemListValidateErrMsg = '';
    if (!validateItemList(data.itemList1)) {
      itemListValidateErrMsg = '商品リスト1のフォーマットが不正です。';
    }
    if (!validateItemList(data.itemList2)) {
      itemListValidateErrMsg += '商品リスト2のフォーマットが不正です。';
    }
    if (!validateItemList(data.itemList3)) {
      itemListValidateErrMsg += '商品リスト3のフォーマットが不正です。';
    }
    if (!validateItemList(data.itemList4)) {
      itemListValidateErrMsg += '商品リスト4のフォーマットが不正です。';
    }

    if (itemListValidateErrMsg !== '') {
      event.stopPropagation();
      setValidErrMsg(itemListValidateErrMsg);
      return;
    }

    let sendScheduleTimeStamp = '';

    if (streamingDay !== undefined && streamingDay !== '' && streamingTime !== undefined && streamingTime !== '') {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} ${streamingTime}`))
        .toISOString()
        .slice(0, -5);
    } else if (
      streamingDay !== undefined &&
      streamingDay !== '' &&
      (streamingTime === undefined || streamingTime === '')
    ) {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} 00:00:00`))
        .toISOString()
        .slice(0, -5);
    }

    const param: F4DetailUpdateFormResponse = {
      id: location.state === undefined ? undefined : location.state.id,
      memo: data.memo === undefined ? '' : data.memo,
      title: data.title,
      topTitle: data.topTitle,
      bannerText: data.bannerText === undefined ? '' : data.bannerText,
      bannerHTML: data.bannerHTML === undefined ? '' : data.bannerHTML,
      sendScheduleTimeStamp: sendScheduleTimeStamp === '' ? undefined : sendScheduleTimeStamp,
      itemList1Title: data.itemList1Title == null ? '今おすすめの注目モニター' : data.itemList1Title,
      itemList1: data.itemList1,
      itemList2Title: data.itemList2Title == null ? '新着モニター' : data.itemList2Title,
      itemList2: data.itemList2,
      itemList3Title: data.itemList3Title == null ? '期間限定モニター' : data.itemList3Title,
      itemList3: data.itemList3,
      itemList4Title: data.itemList4Title == null ? '特集' : data.itemList4Title,
      itemList4: data.itemList4,
      areaList: data.areaList,
      customerTagList: data.customerTagList.customerTagList,
      sendInvisibleConflictFlg: data.sendInvisibleConflictFlg === undefined ? false : data.sendInvisibleConflictFlg,
      sendMode: data.sendMode === undefined ? 0 : data.sendMode,
      testMailaddressList: testStreamingText?.split('\n'),
      memberRegistrationDateStart:
        data.memberRegistrationDateStart === '' ? undefined : data.memberRegistrationDateStart,
      memberRegistrationDateEnd: data.memberRegistrationDateEnd === '' ? undefined : data.memberRegistrationDateEnd,
    };

    updateApi.f4DetailUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  // テスト配信イベント
  const testStreaming = () => {
    const param: F4TestDeliveryFormResponse = {
      title: data.title,
      topTitle: data.topTitle,
      bannerText: data.bannerText,
      bannerHTML: data.bannerHTML,
      itemList1: data.itemList1,
      itemList1Title: data.itemList1Title,
      itemList2: data.itemList2,
      itemList2Title: data.itemList2Title,
      itemList3: data.itemList3,
      itemList3Title: data.itemList3Title,
      itemList4: data.itemList4,
      itemList4Title: data.itemList4Title,
      testMailaddressList: testStreamingText !== undefined ? testStreamingText.split('\n') : [],
    };

    testApi.f4TestDelivery(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setTestResult(res.data);
    });
  };

  const handleMovePref = (moveRight: boolean) => {
    const radix = 10;

    if (moveRight) {
      const selectedOptions = document.getElementById('prefectures')?.querySelectorAll('option:checked');

      if (selectedOptions) {
        const selectedPrefectures: MailMagazinePrefectureFormResponse[] = Array.from(selectedOptions).map((option) => ({
          prefectureId: parseInt(option.getAttribute('value') || '0', radix),
          prefectureName: option.textContent || '',
          selectedFlg: true,
        }));

        // 元の選択された都道府県リストに新しく選択された都道府県を追加
        const updatedPrefecturesSelectedList = [...(data.areaList || []), ...selectedPrefectures];

        // 選択された都道府県リストをIDでソートする
        updatedPrefecturesSelectedList.sort((a, b) => a.prefectureId - b.prefectureId);

        const selectedPrefectureIds = selectedPrefectures.map((prefecture) => prefecture.prefectureId);
        const updatedPrefectures = prefectures?.filter((pref) => !selectedPrefectureIds.includes(pref.id));

        // データの更新
        setData({ ...data, areaList: updatedPrefecturesSelectedList });
        setPrefectures(updatedPrefectures);
      }
    } else {
      // 左への移動処理
      // 選択されたアイテムで選択された都道府県をprefecturesSelectedListから削除し、prefecturesに追加する
      const selectedOptions = document.getElementById('selectedPrefectures')?.querySelectorAll('option:checked');

      if (selectedOptions) {
        const selectedPrefectures: CommonMasterListOutputResponse[] = Array.from(selectedOptions).map((option) => ({
          id: parseInt(option.getAttribute('value') || '0', radix),
          name: option.textContent || '',
        }));

        // 選択された都道府県を「選択されたアイテム」から削除し、「こちらからアイテムを選択」に追加
        const updatedPrefecturesSelectedList = data.areaList?.filter(
          (prefecture) => !selectedPrefectures.find((selectedPref) => selectedPref.id === prefecture.prefectureId)
        );

        const updatedPrefectures = [...(prefectures || []), ...selectedPrefectures];
        // 都道府県リストをIDでソートする
        updatedPrefectures?.sort((a, b) => a.id - b.id);

        // データの更新
        setData({ ...data, areaList: updatedPrefecturesSelectedList });
        setPrefectures(updatedPrefectures);
      }
    }
  };

  const handleDelete = (index: number) => {
    // 指定したインデックス以外のアイテムを残して新しいリストを作成
    const newItems = data.customerTagList.customerTagList.filter((_, i) => i !== index);
    setData((prevData) => ({
      ...prevData,
      customerTagList: { customerTagList: newItems },
    }));
  };

  const validateItemList = (input: string | undefined): boolean => {
    // inputが未入力の場合はチェック不要
    if (input === '' || input == null) {
      return true;
    }

    const pattern = /^(monitor_id|brand_id|campaign_id),\d+$/;

    // 各行をチェックする
    const lines = input.split('\n');
    return lines.every((line) => {
      const trimmedLine = line.trim();
      // 空行は無視する
      if (trimmedLine === '') {
        return true;
      }
      return pattern.test(trimmedLine);
    });
  };

  return (
    <>
      <Form validated={validated} onSubmit={submit}>
        <Title className="mb-4">{location.state === undefined ? 'F4メール編集画面' : 'F4メール詳細画面'}</Title>
        <div className="d-flex justify-content-end mb-4">
          <Button type="button" onClick={testStreaming} className="ms-2">
            テスト配信
          </Button>
          <Button type="submit" className="ms-2">
            保存
          </Button>
        </div>
        {validErrMsg && (
          <Alert variant="warning" style={{ marginTop: 10 }}>
            {validErrMsg}
          </Alert>
        )}
        {updResult?.result && (
          <Alert variant="success" style={{ marginTop: 10 }}>
            更新しました。
          </Alert>
        )}
        {testResult?.result && (
          <Alert variant="success" style={{ marginTop: 10 }}>
            テスト配信が完了しました。
          </Alert>
        )}
        {updResult !== undefined && updResult?.result === false && (
          <Alert
            variant="danger"
            style={{ marginTop: 10 }}
          >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
        )}
        {testResult !== undefined && testResult?.result === false && (
          <Alert
            variant="danger"
            style={{ marginTop: 10 }}
          >{`${testResult.errorMessage} (エラーコード：${testResult.errorCode})`}</Alert>
        )}
        <Form.Group className="mb-4">
          <Form.Label>メモ</Form.Label>
          <Form.Control
            as="textarea"
            value={data.memo}
            onChange={(e) => {
              setData({ ...data, memo: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>メール件名</Form.Label>
          <Form.Control
            value={data.title}
            required
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>メールタイトル</Form.Label>
          <Form.Control
            value={data.topTitle}
            required
            onChange={(e) => {
              setData({ ...data, topTitle: e.target.value });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>お知らせバナーテキスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.bannerText}
            onChange={(e) => {
              setData({ ...data, bannerText: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>お知らせバナーHTML</Form.Label>
          <Form.Control
            as="textarea"
            value={data.bannerHTML}
            onChange={(e) => {
              setData({ ...data, bannerHTML: e.target.value === '' ? undefined : e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Label>配信日時</Form.Label>
        <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
          <Datepicker
            value={streamingDay === undefined ? '' : streamingDay}
            className="me-2"
            onChange={(e) => {
              setStreamingDay(e.target.value);
            }}
            data-testid="datepicker"
          />
          <Form.Control
            type="time"
            step="1"
            value={streamingTime === undefined ? '' : streamingTime}
            className="me-2"
            onChange={(e) => {
              setStreamingTime(e.target.value);
            }}
            disabled={streamingDay === undefined || streamingDay === ''}
          />
        </Form.Group>
        <Form.Label>会員登録期間</Form.Label>
        <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
          <Form.Control
            data-testid="memberRegistrationDateStart"
            type="date"
            value={data.memberRegistrationDateStart === undefined ? '' : data.memberRegistrationDateStart}
            onChange={(e) => {
              setData({ ...data, memberRegistrationDateStart: e.target.value });
            }}
          />
          <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
          <Form.Control
            data-testid="memberRegistrationDateEnd"
            type="date"
            value={data.memberRegistrationDateEnd === undefined ? '' : data.memberRegistrationDateEnd}
            onChange={(e) => {
              setData({ ...data, memberRegistrationDateEnd: e.target.value });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>商品リスト1</Form.Label>
          <Form.Group className="ms-4">
            <Form.Label>見出し</Form.Label>
            <Form.Control
              value={data.itemList1Title}
              onChange={(e) => {
                setData({ ...data, itemList1Title: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Group className="ms-4">
            <Form.Label>リスト</Form.Label>
            <Form.Control
              as="textarea"
              value={data.itemList1}
              onChange={(e) => {
                setData({ ...data, itemList1: e.target.value });
              }}
              rows={5}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>商品リスト2</Form.Label>
          <Form.Group className="ms-4">
            <Form.Label>見出し</Form.Label>
            <Form.Control
              value={data.itemList2Title}
              onChange={(e) => {
                setData({ ...data, itemList2Title: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Group className="ms-4">
            <Form.Label>リスト</Form.Label>
            <Form.Control
              as="textarea"
              value={data.itemList2}
              onChange={(e) => {
                setData({ ...data, itemList2: e.target.value });
              }}
              rows={5}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>商品リスト3</Form.Label>
          <Form.Group className="ms-4">
            <Form.Label>見出し</Form.Label>
            <Form.Control
              value={data.itemList3Title}
              onChange={(e) => {
                setData({ ...data, itemList3Title: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Group className="ms-4">
            <Form.Label>リスト</Form.Label>
            <Form.Control
              as="textarea"
              value={data.itemList3}
              onChange={(e) => {
                setData({ ...data, itemList3: e.target.value });
              }}
              rows={5}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>商品リスト4</Form.Label>
          <Form.Group className="ms-4">
            <Form.Label>見出し</Form.Label>
            <Form.Control
              value={data.itemList4Title}
              onChange={(e) => {
                setData({ ...data, itemList4Title: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group>
          <Form.Group className="ms-4">
            <Form.Label>リスト</Form.Label>
            <Form.Control
              as="textarea"
              value={data.itemList4}
              onChange={(e) => {
                setData({ ...data, itemList4: e.target.value });
              }}
              rows={5}
            />
          </Form.Group>
        </Form.Group>
        <br />
        <div className="align-items-center " style={{ width: '49.5%' }}>
          <Row className="mb-4">
            <Form.Label>都道府県</Form.Label>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="prefectures">
                <Form.Label>こちらからアイテムを選択</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {prefectures?.map((prefecture) => (
                    <option key={prefecture.id} value={prefecture.id.toString()}>
                      {prefecture.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              {' '}
              {/* 左右の列の中央揃え */}
              <div className="d-grid">
                <Button variant="light" onClick={() => handleMovePref(true)}>
                  <FontAwesomeIcon icon={faArrowRight} /> {/* 右向き矢印 */}
                </Button>
                <Button variant="light" onClick={() => handleMovePref(false)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {/* 左向き矢印 */}
                </Button>
              </div>
            </Col>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="selectedPrefectures">
                <Form.Label className="fw-bold">選択されたアイテム</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {data.areaList
                    ?.sort((a, b) => a.prefectureId - b.prefectureId)
                    .map((prefecture) => (
                      <option key={prefecture.prefectureId} value={prefecture.prefectureId.toString()}>
                        {prefecture.prefectureName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Label>会員属性</Form.Label>
          {data.customerTagList.customerTagList?.map((_, i) => {
            return (
              <Col key={i.toString()} className="col-12 mt-2">
                <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                  <Form.Label className="mt-2 me-2 ms-4">属性</Form.Label>
                  <Col data-testid="attributeSelect">
                    <Select
                      id="prefecture"
                      onChange={(e) => {
                        onChangeCustomerSelect(e, i);
                      }}
                      closeMenuOnSelect
                      value={data.customerTagList.customerTagList[i]}
                      getOptionLabel={(option) => {
                        return option.tagName;
                      }}
                      getOptionValue={(option) => {
                        return option.tagId.toString();
                      }}
                      options={customerTagList.customerTagList}
                    />
                  </Col>
                  <Form.Label className="mt-2 me-2 ms-2">選択肢</Form.Label>
                  <Col>
                    <Form.Select
                      data-testid="ChoiceSelect"
                      value={data.customerTagList.customerTagList[i].customerTagAnswerList[0].id}
                      onChange={(e) => {
                        const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                        const targetTagName = customerTagList.customerTagList
                          .find((row) => {
                            return row.tagId === Number(data.customerTagList.customerTagList[i].tagId);
                          })
                          ?.customerTagAnswerList.find((r) => {
                            return r.id === Number(e.target.value);
                          })?.tagValue;

                        if (targetTagName) {
                          setData({
                            ...data,
                            customerTagList: {
                              customerTagList: addList.customerTagList.map((row, index) => {
                                return index === i
                                  ? {
                                      ...row,
                                      customerTagAnswerList: [{ id: Number(e.target.value), tagValue: targetTagName }],
                                    }
                                  : { ...row };
                              }),
                            },
                          });
                        }
                      }}
                    >
                      {customerTagList.customerTagList
                        .find((row) => {
                          return row.tagId === _.tagId;
                        })
                        ?.customerTagAnswerList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.tagValue}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Button variant="link" className="text-secondary" onClick={() => handleDelete(i)}>
                      <FontAwesomeIcon data-testid="deleteButton" className="text-secondary" icon={faTimesCircle} />
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            );
          })}
          {customerTagList.customerTagList.length > 0 && (
            <div className="d-flex justify-content-end mb-4 mt-4">
              <Button
                data-testid="add"
                variant="link"
                className="text-secondary p-0"
                onClick={() => {
                  const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                  addList.customerTagList.push({
                    tagId: customerTagList.customerTagList[0].tagId,
                    tagName: customerTagList.customerTagList[0].tagName,
                    customerTagAnswerList: customerTagList.customerTagList[0].customerTagAnswerList,
                  });

                  setData({ ...data, customerTagList: addList });
                }}
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                <span style={{ fontSize: '1rem' }}>追加</span>
              </Button>
            </div>
          )}
          <div className="d-flex mb-4 mt-4">
            <Form.Check.Input
              data-testid="sendInvisibleCheckBox"
              className="flex-shrink-0 me-3"
              type="checkbox"
              name="checkBoxGroup01"
              checked={data.sendInvisibleConflictFlg}
              onChange={(e) => {
                setData({ ...data, sendInvisibleConflictFlg: e.target.checked });
              }}
            />
            <Form.Label className="me-5">クローズド案件を含む</Form.Label>
          </div>
          <Form.Group className="mb-4">
            <Form.Label>配信モード</Form.Label>
            <Form.Select
              value={data.sendMode}
              onChange={(e) => {
                setData({ ...data, sendMode: Number(e.target.value) });
              }}
            >
              {streamingModeTypeList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <Form.Group className="mb-4">
          <Form.Label>テスト配信先</Form.Label>
          <Form.Control
            value={testStreamingText}
            onChange={(e) => {
              setTestStreamingText(e.target.value);
            }}
            as="textarea"
            rows={3}
          />
        </Form.Group>
      </Form>
    </>
  );
};
