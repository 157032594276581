import React, { createContext, createElement } from 'react';
import { format as formatDate, parseISO } from 'date-fns';
import crypto from 'crypto';
import { TextEncoder } from 'util';
import { API_CLIENT_ID_FANCREW } from '../Constants';
/**
 * Base64からファイル変換
 * @param base64
 * @param type
 */
export const toBlob = (base64: string, type: string): Blob | false => {
  const bin = window.atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try {
    return new Blob([buffer.buffer], {
      type,
    });
  } catch (e) {
    return false;
  }
};

/** ダウンロード処理を作成 */
export const execDownload = async (base64: string, mimetype: string, filename: string): Promise<true | never> => {
  return new Promise((resolve, reject) => {
    const blob = toBlob(base64, mimetype);
    if (!blob) {
      const errorMessage = 'Blobを作成できませんでした';
      reject(errorMessage);
      throw new Error(errorMessage);
    }
    const parent = document.createElement('div');
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    parent.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    parent.removeChild(link);
    return resolve(true);
  });
};

/** Latin1範囲外の文字列(日本語など)をbtoa */
export const btoaWithLatin1 = (text: string): string =>
  window.btoa(
    Array.from(new (window.TextEncoder ?? TextEncoder)().encode(text), (x) => String.fromCharCode(x)).join('')
  );

/**
 * テスト用IDの設定補助
 * ```tsx
 * const Component: React.FC = () => {
 *   const testId = createTestId(Component);
 *   return (
 *     <>
 *       <button data-testid={testId("first")}>1</button>
 *       <button data-testid={testId("second")}>2</button>
 *     </>
 *   )
 * }
 * ```
 */
export const createTestId =
  <T = string>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentName: React.ComponentType<any>
  ) =>
  (testId: T, num?: number): string =>
    `${componentName.name}/${testId}:${num !== undefined ? `${num}` : ''}`;

/* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/** 特定の値でなければエラーを返す */
export const mustBeOther = <T = never>(
  value: any,
  than: any[],
  onError?: (value: any, than: any[]) => string
): T | never => {
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */ /* eslint-enable @typescript-eslint/no-explicit-any */
  if (than.includes(value))
    throw new Error(onError ? onError(value, than) : `値( ${value} )は次の値以外でなければなりません: ${than}`);
  return value;
};

/** 配列をシャッフル */
export const shuffle = <T>(x: T[]): T[] => x.sort(() => 0.5 - Math.random());
/** ランダム数字を取得
 * ```js
 * random(100, 200);
 * ```
 */
export const random = (start: number, end: number): number => start + Math.random() * end;
/** ランダムなDateオブジェクトを取得(モック用)
 * ```js
 * // 2022/1/1 0:0:0 - 2022/12/31 23:59:59
 * randDate(new Date(2022, 1, 1), new Date(2022, 12, 31, 23, 59, 59));
 * ```
 */
export const randomDate = (start: Date, end: Date): Date => new Date(random(+start, +end));

/** ISO時間文字列を特定のフォーマットに変換
 * https://date-fns.org/v2.28.0/docs/format
 */
export const formatISODate = (isoDate: string | undefined, format: string): string =>
  isoDate ? formatDate(parseISO(isoDate), format) : '';
/** ISO時間文字列をフォーム向けに変換 */
export const isoDateToInput = (
  type: 'time' | 'date' | 'datetime-local',
  isoDate: string | undefined,
  undefinedIfInvalid = true
): string | undefined | never => {
  try {
    if (type === 'time') return formatISODate(isoDate, 'HH:mm');
    if (type === 'date') return formatISODate(isoDate, 'yyyy-MM-dd');
    // eslint-disable-next-line quotes
    if (type === 'datetime-local') return formatISODate(isoDate, "yyyy-MM-dd'T'HH:mm");
  } catch (e) {
    if (undefinedIfInvalid) return undefined;
    console.error(e);
    throw new Error(`無効な指定値: ${isoDate}`);
  }
  throw new Error('無効なtype');
};

/** `<T>`で指定した型のキーを取得する */
export const nameof = <T>(name: keyof T): keyof T => name;

/**
 * `?id=1234`のようなURLクエリ文字列をオブジェクトに変換する
 *
 * ```ts
 * // ?id=1234&name=abc
 * const { search } = useLocation();
 * const {
 *   id, // "1234"
 *   name, // "abc"
 * } = parseSearchQuery(search);
 * ```
 */
export const parseSearchQuery = (search: string): Record<string, string> =>
  search
    .slice(1)
    .split(/&/)
    .map((x) => x.split('='))
    .reduce((p, [k, v]) => ({ ...p, [k]: v }), {});

/** `condition ? then : <></>` を疑問演算子を使わず厳密に書く
 * これにより曖昧な条件式を避け、末尾の`<></>`を撤廃できる
 * ```tsx
 * // before
 * list.length ? <Anything/> : <></>
 * // after
 * when(list.length === 0, <Anything/>)
 * when(list.length === 0, () => <Anything/>)
 * ```
 */
// eslint-disable-next-line no-undef
export const when = (condition: boolean, then: JSX.Element | (() => JSX.Element)): JSX.Element => {
  const component = typeof then === 'function' ? then() : then;
  return condition ? component : React.createElement(React.Fragment);
};

/** `when`を描画ではなく`CSS:display`で切り替える */
export const displayWhen = (condition: boolean, then: JSX.Element | (() => JSX.Element)): React.ReactNode => {
  const component = typeof then === 'function' ? then() : then;
  return React.createElement('div', { style: { display: condition ? 'block' : 'none' } }, component);
};

/** switch文ライクにコンポーネントを切り替える
 * コンポーネントPropsを渡す場合は React.PropsWithChildren を使用すること
 * ```tsx
 * // before
 * <div>
 *   {(() => {
 *     switch (expression) {
 *       case "one":
 *         return <h1>1</h1>;
 *       case "two":
 *         return (() => {
 *           const r = Math.random() * 22 | 0;
 *           return <h2>{'2'.repeat(r)}</h2>
 *         })();
 *       default:
 *         return <p>hi</p>;
 *     }
 *   })()}
 * </div>
 * // after
 * <div>
 *   {dial(
 *     expression,
 *     {
 *       one: <h1>1</h1>,
 *       two: () => {
 *         const r = Math.random() * 22 | 0;
 *         return <h2>{'2'.repeat(r)}</h2>
 *       },
 *     },
 *     <p>hi</p>
 *   )}
 * </div>
 * ```
 */
export const dial = <T extends string | number, P extends {}>(
  expression: T,
  // eslint-disable-next-line no-undef
  cases: Partial<Record<T, JSX.Element | React.FC<P>>>,
  // eslint-disable-next-line no-undef
  ifDefault?: JSX.Element | React.FC<P>
  // eslint-disable-next-line no-undef
): JSX.Element => {
  // eslint-disable-next-line no-undef
  const f = (C: JSX.Element | React.FC<P> | undefined) => (typeof C === 'function' ? React.createElement(C) : C);
  return f(cases[expression]) ?? f(ifDefault) ?? createElement(React.Fragment);
};

/** useSafeContext のために createContext を引数なしで作成する。
 *
 * デモコードは useSafeContext を参照。 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSafeContext = <T extends any>(): React.Context<T> => createContext<T>(undefined as any as T);

/** UUID v4 を生成する (key用) */
export const uuid = (): string => {
  const getRandomValues = (a: Uint8Array) => {
    // jest対策
    return window.crypto?.getRandomValues(a) ?? crypto.randomBytes(a.length);
  };
  const f = (x: number, y: 0 | 1 = 1) =>
    getRandomValues(new Uint8Array(x)).reduce((p, c) => p + (y ? c >> 4 : (c >> 6) + 8).toString(16), '');
  return `${f(8)}-${f(4)}-4${f(3)}-${f(1, 0)}${f(3)}-${f(12)}`;
};

/** 非同期処理を順次実行 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const promiseSeq = async (promises: (() => Promise<any>)[]): Promise<void> => {
  // eslint-disable-next-line no-restricted-syntax
  for await (const p of promises) {
    await p();
  }
};

/** File型をDataUrlに変換 */
export const fileToDataUrl = async (file: File): Promise<string | null> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  try {
    const { target } = await new Promise<ProgressEvent<FileReader>>((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
    });
    const { result: uploadFile } = target ?? {};
    if (!uploadFile) throw new Error('URL作成失敗');
    return uploadFile as string;
  } catch (e) {
    console.warn('URL変換失敗', e);
    return null;
  }
};

/**
 * 本番環境では出力しない`console.log`
 * `"__TRACE__"`を入れるとスタックトレースも出力する
 */
export const debugLog = (...args: unknown[]): void => {
  if (process.env.NODE_ENV === 'production') return;
  if (args.includes('__TRACE__')) {
    console.groupCollapsed(...args);
    console.trace();
    console.groupEnd();
  } else {
    console.log(...args);
  }
};

/** JSONを使用してオブジェクトを比較する */
export const deepEqual = (a: {}, b: {}): boolean => JSON.stringify(a) === JSON.stringify(b);

/** デバッグ用に仮設定値を使用していると警告を出す(消し忘れ対策) */
export const warnValue = <T>(description: string, value: T): T => {
  console.error(`仮設定値(${description})が設定されています！`, value);
  return value;
};

/**
 * useHistoryで複数のobjectをstateで渡した時に中身を取り出すのに使用
 * @param state
 * @param name
 */
export const getHistoryState = (state: any, name: string): any => (state as any)?.[name];

/**
 * ユニークな配列を返す
 * @param array
 */
export const toUnique = (array: Array<any>) => {
  const result: Array<any> = [];
  array.map((v) => {
    let isUnique = true;
    result.map((vv) => {
      if (deepEqual(vv, v)) {
        isUnique = false;
      }
    });
    if (isUnique) {
      result.push(v);
    }
  });
  return result;
};
/**
 * 正規表現に使っている文字列をエスケープする
 * @param s
 */
export const escapeRegexLiterals = (s: string) => {
  const oneEscapeLiterals = /\\|\*|\+|\?|\^|\$|\[|\]|\(|\)|\||\/|\.|\$|\{|\}/gi;
  return s.replaceAll(oneEscapeLiterals, '\\$&');
};

/**
 * 空の値を先頭に追加する
 * useFormで初期値を空にしたいときに空のoptionを作ってからarrayをmap展開したものを継ぎ足すとうまくいかないので一旦これで対応
 * なぜかこれを使うとうまくいかなくなったので廃止予定。よくわからん
 * @param v
 */
export const addPartialDataToList = <T>(v: T[]): Partial<T>[] => {
  return [...[{}], ...v] as Partial<T>[];
};

export const nothingWithHyphenStr = (v?: string | number) => {
  if (typeof v === 'string') {
    if (v.trim() !== '') {
      return v;
    }
  }
  if (typeof v === 'number') {
    return v.toString();
  }
  return '-';
};

/**
 * oemユーザーかどうか
 * trueならOEMユーザー
 * @param apiClientid
 */
export const isOemUser = (apiClientid?: number) => {
  if (typeof apiClientid === 'number') {
    return apiClientid !== API_CLIENT_ID_FANCREW;
  }
  return false;
};

export const isAllEmpty = (includeEmptyStr: boolean, ...values: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const value of values) {
    if (includeEmptyStr) {
      if (value !== undefined && value !== null && value !== '') {
        return false;
      }
    } else if (value !== undefined && value !== null) {
      return false;
    }
  }
  return true;
};

/**
 * 全て値を持っているかどうか
 * @param allowEmptyString
 * @param values
 */
export const hasAllValue = (allowEmptyString: boolean, ...values: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const value of values) {
    if (typeof value === 'object') {
      const arr = Object.values(value);
      // return できるようにわざわざこうしてる
      // eslint-disable-next-line no-restricted-syntax
      for (const v of arr) {
        if (typeof v === 'object' || Array.isArray(v)) {
          if (!hasAllValue(allowEmptyString, v)) {
            return false;
          }
        }
        if (v === undefined || v === null) {
          return false;
        }
        if (!allowEmptyString && typeof v === 'string' && v === '') {
          return false;
        }
      }
    }
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const v of value) {
        if (typeof v === 'object' || Array.isArray(v)) {
          if (!hasAllValue(allowEmptyString, v)) {
            return false;
          }
        }
        if (v === undefined || v === null) {
          return false;
        }
        if (!allowEmptyString && typeof v === 'string' && v === '') {
          return false;
        }
      }
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (!allowEmptyString && typeof value === 'string' && value === '') {
      return false;
    }
  }
  return true;
};
export const isAllTrue = (...values: (boolean | undefined)[]) => {
  return values.every((v) => v);
};

export default {
  toBlob,
  execDownload,
  btoaWithLatin1,
  createTestId,
  mustBeOther,
  shuffle,
  random,
  randomDate,
  formatISODate,
  isoDateToInput,
  nameof,
  when,
  dial,
  createSafeContext,
  uuid,
  fileToDataUrl,
  warnValue,
  getHistoryState,
  toUnique,
  escapeRegexLITERALS: escapeRegexLiterals,
  addPartialDataToList,
  nothingWithHyphenStr,
  isOemUser,
  isAllEmpty,
  hasAllValue,
  isAllTrue,
};
