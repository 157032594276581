import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { uuid, when } from '../../../../../../utils/functions';
import { TinyHead } from '../../../../../atoms/TinyHead';
import { TemplateFormMode } from '../../../../../pages/Correction/constants';
import { dpToData, getCommonCommentFormList } from '../../../../../pages/Correction/utils';

export const AccordionList: React.FC<{ mode: TemplateFormMode.NG | TemplateFormMode.ESCALE }> = ({ mode }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const list = useMemo(
    () =>
      getCommonCommentFormList(mode, $).map((ng) => ({
        ...ng,
        answer: dpToData(ng.dp).toCommentForm($.commentForms_enqueteText).at(0)?.content,
      })),
    [$, mode]
  );

  const findCustomerAnswer = (answerId: number) => {
    // 変更済みのユーザー回答がある場合はそれを返す
    const modifiedAnswer: string | undefined = $.commentForms_enqueteText?.find(
      (v) => v.dp.targetId === answerId
    )?.content;
    if (modifiedAnswer !== undefined) {
      return modifiedAnswer;
    }
    const modifiedSelections = $.selectedForms?.find((v) => v.dp.targetId === answerId)?.selectionIds;
    if (modifiedSelections !== undefined) {
      let modifiedSelectionContent = '';
      const selectionMaster = $.res_enqueteDisplay?.find((v) => v.answerId === answerId)?.selectionList;
      if (selectionMaster !== undefined) {
        selectionMaster.map((v) => {
          if (modifiedSelections.includes(v.selectionId)) {
            modifiedSelectionContent += `${v.content}\n`;
          }
        });
      }
      return modifiedSelectionContent;
    }
    // 変更してない場合
    const customerAnswerObj = $.res_enqueteDisplay?.find((v) => v.answerId === answerId);
    if (customerAnswerObj !== undefined) {
      let selectionAnswer = '';
      selectionAnswer += customerAnswerObj.answerChoiceList.map((v) => `${v.content}\n`);
      if (selectionAnswer !== '') {
        return selectionAnswer;
      }
    }
    return $.res_enqueteDisplay?.find((v) => v.answerId === answerId)?.answerContent ?? '';
  };
  // TODO 設問のINDEXをバックエンドから渡す。一旦設問の文言から設問Noを取得しソートしてる
  return (
    <>
      {
        // NGの場合
        mode === TemplateFormMode.NG ? (
          <>
            <TinyHead>以下の項目にNGが存在します</TinyHead>
            {list
              .sort((a, b) => {
                const qNumAStr = a.name?.match(/\d+/);
                const qNumBStr = b.name?.match(/\d+/);
                if (qNumAStr && qNumBStr) {
                  const qNuma = Number(qNumAStr[0]);
                  const qNumb = Number(qNumBStr[0]);
                  return qNuma - qNumb;
                }
                return 0;
              })
              .map((ng) => {
                // const { content: answer } = dpToData(ng.dp).toCommentForm($.commentForms_enqueteText).at(0) ?? {};
                const answer = findCustomerAnswer(ng.dp.targetId ?? 0);
                return (
                  <Card body key={uuid()}>
                    <TinyHead>{ng.name}</TinyHead>
                    <div className="bg-white mt-2 p-2 border border-danger text-danger">{ng?.content}</div>
                    {when(!!answer, <div className="bg-white mt-2 p-2 border">{answer}</div>)}
                  </Card>
                );
              })}
          </>
        ) : (
          <>
            <TinyHead>以下の項目にエスカレが存在します</TinyHead>
            <ul>
              {list.map(({ name }) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </>
        )
      }
    </>
  );
};
