import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { UnpackNestedValue, useFormContext } from 'react-hook-form';
import { Modal } from '../../molecules/Modal';
import { CustomerTicketCreateFormResponse } from '../../../api-client';
import { BillingCommonInputTextWithLabel } from '../../molecules/BillingCommonInputTextWithLabel';
import { BillingCommonInputSelectWithLabel } from '../../molecules/BillingCommonInputSelectWithLabel';

export interface Props {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: (data: CustomerTicketCreateFormResponse) => void;
}

const CUSTOMER_TICKET_TYPE = [
  { id: 1, name: '有料' },
  { id: 2, name: '無料' },
];

export const CustomerTicketCreateModal: React.VFC<Props> = ({ isOpenModal, setIsOpenModal, fetchData }) => {
  const methods = useFormContext<CustomerTicketCreateFormResponse>();
  const { getValues, handleSubmit } = methods;

  const onHide = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <Modal
        title="有料チケット付与確認モーダル"
        onHide={onHide}
        isModal={isOpenModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <Form onSubmit={handleSubmit(fetchData)}>
              <Row>
                <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
                  registerName="customerIds"
                  defaultValue={getValues('customerIds')}
                  labelName="ユーザーID(カンマ区切りで複数可)"
                  required
                  colNumber={12}
                  className="mb-3"
                  readOnly
                />
              </Row>
              <Row>
                <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
                  registerName="expireAt"
                  defaultValue={getValues('expireAt')}
                  labelName="利用期限(未入力で1年後)"
                  type="date"
                  colNumber={12}
                  className="mb-3"
                  readOnly
                />
              </Row>
              <Row>
                <BillingCommonInputSelectWithLabel<CustomerTicketCreateFormResponse>
                  registerName="customerTicketTypeCode"
                  labelName="チケットタイプ"
                  options={CUSTOMER_TICKET_TYPE}
                  defaultValue={getValues('customerTicketTypeCode')}
                  required
                  colNumber={12}
                  className="mb-3"
                  disabled
                />
              </Row>
              <Row>
                <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
                  registerName="num"
                  defaultValue={getValues('num')}
                  labelName="枚数"
                  type="number"
                  colNumber={12}
                  className="mb-3"
                  readOnly
                />
              </Row>
              <Row>
                <BillingCommonInputTextWithLabel<CustomerTicketCreateFormResponse>
                  registerName="commentForInternal"
                  defaultValue={getValues('commentForInternal')}
                  labelName="付与理由"
                  required
                  colNumber={12}
                  className="mb-3"
                  readOnly
                />
              </Row>
              <div className="d-flex justify-content-center">
                <Button type="submit" variant="secondary" className="m-1">
                  OK
                </Button>
                <Button variant="secondary" onClick={onHide} className="m-1">
                  キャンセル
                </Button>
              </div>
            </Form>
          </>
        }
      />
    </>
  );
};
