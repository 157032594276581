import React, { useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { CustomerTicketApi, CustomerTicketReturnListOutputResponse } from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { BillingCommonInputTextWithLabel } from '../../../molecules/BillingCommonInputTextWithLabel';
import { CustomerTicketReturnListSearchForm, SearchConditions } from './CustomerTicketReturnListSearchForm';

export const CustomerTicketReturnListPage: React.VFC = () => {
  const methods = useForm<SearchConditions>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  return (
    <>
      <FormProvider {...methods}>
        <CustomerTicketReturnListSearchForm />
      </FormProvider>
    </>
  );
};
