import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { AxiosResponse } from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry } from '@fortawesome/free-regular-svg-icons';
import { createTestId, when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { AgentLogin } from '../../organisms/AgentLogin';
import { CustomerInfoApi, CustomerInformationDetailDisplayOutputResponse } from '../../../api-client';
import { tensakuOem } from '../../../states/tensakuOem';

export type TestIds =
  | 'applicationHistory'
  | 'pointHistory'
  | 'giftTicketList'
  | 'mailHistory'
  | 'loginHistory'
  | 'relatedCustomer'
  | 'authHistory'
  | 'clientNg'
  | 'agentLogin'
  | 'mailOem'
  | 'oemFee'
  | 'customerTicketHistory';

export type ActiveKeyType =
  | 'customerInfo'
  | 'applicationHistory'
  | 'pointHistory'
  | 'giftTicketList'
  | 'mailHistory'
  | 'loginHistory'
  | 'relatedCustomer'
  | 'authHistory'
  | 'mailOem'
  | 'oemFee'
  | 'customerTicketHistory'
  | '';

export interface Props {
  activeKey: ActiveKeyType;
  customerId: number;
}

export const CustomerInfoHeader: React.FC<Props> = React.memo((props) => {
  const { customerId } = props;
  const testid = createTestId<TestIds>(CustomerInfoHeader);

  const [customerName, setCustomerName] = useState('');
  const [oldSystemUrl, setOldSystemUrl] = useState('');
  const [oemFlg, setOemflg] = useState<boolean>();
  const [tensakuOemState, setTensakuOemState] = useRecoilState(tensakuOem);
  const resetTensakuOem = useResetRecoilState(tensakuOem);
  const customerInfoApi = new CustomerInfoApi();
  useEffect(() => {
    customerInfoApi
      .customerInformationDetailDisplay(customerId)
      .then((res: AxiosResponse<CustomerInformationDetailDisplayOutputResponse>) => {
        const lastName = res.data.lastName ? res.data.lastName : '';
        const firstName = res.data.firstName ? res.data.firstName : '';
        setCustomerName(`${lastName} ${firstName}`);
        setOldSystemUrl(res.data.oldSystemCustomerDetailUrl);
        setOemflg(res.data.oemId !== 1);
        if (tensakuOemState?.customerId !== customerId) {
          resetTensakuOem();
          setTensakuOemState({ customerId: customerId, apiClientId: res.data.oemId ?? 1 });
        }
      });
  }, [customerId]);

  return (
    <>
      {oemFlg !== undefined && (
        <div style={{ marginBottom: '10px' }}>
          <div className="d-flex justify-content-between mb-3">
            <div style={{ fontSize: '1.5rem' }}>{`${customerId} ${customerName}`}</div>
            <div>
              <a href={oldSystemUrl} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                旧管理の会員詳細
              </a>
              <Link to={Url.TENSAKU.CLIENT_NG} className="me-2" data-testid={testid('clientNg')}>
                <FontAwesomeIcon icon={faAngry} />
                クライアントNG
              </Link>
              {tensakuOemState?.customerId && (
                <AgentLogin customerId={customerId} apiClientId={tensakuOemState.apiClientId} />
              )}
            </div>
          </div>
          <CustomerTabs oemFlg={oemFlg} props={props} />
        </div>
      )}
    </>
  );
});

const CustomerTabs: React.FC<{ oemFlg: boolean; props: Props }> = ({ oemFlg, props }) => {
  const testid = createTestId<TestIds>(CustomerInfoHeader);
  const { customerId, activeKey } = props;
  const history = useHistory();

  const onSelectTabs = useCallback(
    (key: string) => {
      switch (key) {
        case 'customerInfo':
          history.push(`${Url.TENSAKU.CUSTOMER_INFO}/${customerId}`);
          break;
        case 'applicationHistory':
          history.push(`${Url.TENSAKU.APPLY_HISTORY_LIST}/${customerId}`);
          break;
        case 'pointHistory':
          history.push(`${Url.TENSAKU.POINT_HISTORY}/${customerId}`);
          break;
        case 'giftTicketList':
          history.push(`${Url.TENSAKU.GIFT_TICKET}/${customerId}`);
          break;
        case 'mailHistory':
          history.push(`${Url.TENSAKU.MAIL_HISTORY_ROI}/${customerId}`);
          break;
        case 'mailOem':
          history.push(`${Url.TENSAKU.MAIL_HISTORY_OEM}/${customerId}`);
          break;
        case 'loginHistory':
          history.push(`${Url.TENSAKU.LOGIN_HISTORY}/${customerId}`);
          break;
        case 'relatedCustomer':
          history.push(`${Url.TENSAKU.RELATED_CUSTOMER}/${customerId}`);
          break;
        case 'authHistory':
          history.push(`${Url.TENSAKU.AUTH_HISTORY}/${customerId}`);
          break;
        case 'oemFee':
          history.push(`${Url.TENSAKU.OEM_FEE_LIST}/${customerId}`);
          break;
        // TODO:ToCでファンくるチケット実装後にファンくるチケット分も対応
        case 'customerTicketHistory':
          history.push(`${Url.TENSAKU.CUSTOMER_TICKET_HISTORY}/${customerId}`);
          break;
        default:
      }
    },
    [history, customerId]
  );

  if (oemFlg) {
    return (
      <>
        <Tabs id="customerPageTabs" activeKey={activeKey} onSelect={(key) => onSelectTabs(`${key}`)}>
          <Tab title="会員情報" eventKey="customerInfo" />
          <Tab title="応募履歴" eventKey="applicationHistory" data-testid={testid('applicationHistory')} />
          <Tab title="OEM報酬履歴" eventKey="oemFee" data-testid={testid('oemFee')} />
          <Tab title="EventMessage履歴" eventKey="mailOem" data-testid={testid('mailOem')} />
          <Tab title="ログイン履歴" eventKey="loginHistory" data-testid={testid('loginHistory')} />
          <Tab title="関連会員一覧" eventKey="relatedCustomer" data-testid={testid('relatedCustomer')} />
        </Tabs>
      </>
    );
  }
  return (
    <>
      <Tabs id="customerPageTabs" activeKey={activeKey} onSelect={(key) => onSelectTabs(`${key}`)}>
        <Tab title="会員情報" eventKey="customerInfo" />
        <Tab title="応募履歴" eventKey="applicationHistory" data-testid={testid('applicationHistory')} />
        <Tab title="ポイント履歴" eventKey="pointHistory" data-testid={testid('pointHistory')} />
        <Tab title="ギフト券一覧" eventKey="giftTicketList" data-testid={testid('giftTicketList')} />
        <Tab title="メール履歴" eventKey="mailHistory" data-testid={testid('mailHistory')} />
        <Tab title="ログイン履歴" eventKey="loginHistory" data-testid={testid('loginHistory')} />
        <Tab title="関連会員一覧" eventKey="relatedCustomer" data-testid={testid('relatedCustomer')} />
        <Tab title="電話番号認証履歴" eventKey="authHistory" data-testid={testid('authHistory')} />
        <Tab title="有料サービス履歴" eventKey="customerTicketHistory" data-testid={testid('customerTicketHistory')} />
      </Tabs>
    </>
  );
};
