import React from 'react';
import { Card } from 'react-bootstrap';
import type { ImageRuleDtoResponse, MonitorRuleForCorrectionPageDtoResponse } from '../../../../../api-client';
import { monitorApi } from '../../../../../test/mocks/api/MonitorApi';
import { uuid } from '../../../../../utils/functions';
import { DayOfWeek, MONITOR_RULE_TYPE } from '../../../../../Constants';
import sass from '../../../../../scss/organisms/calendarTable.module.scss';
import { CalenderTable } from '../../../Table/CalenderTable';
import { MONITOR_RULE_TYPES } from '../../../../pages/Correction/constants';

// TODO: [添削] テスト未実装
export const MonitorRule: React.FC<{ monitorRule: MonitorRuleForCorrectionPageDtoResponse; isReadOnly: boolean }> = ({
  monitorRule,
  isReadOnly,
}) => {
  const backgroundColor = isReadOnly ? {} : { backgroundColor: 'rgba(0, 0, 0, 0.05)' };

  const getImageTargetColor = (index: number) => {
    if (index % 3 === 0) {
      return '#a1d5ff';
    }
    if (index % 2 === 0) {
      return '#b7ffa7';
    }
    return '#ffc1c1';
  };
  const imageTargetElements = (imageRule: ImageRuleDtoResponse, index: number) => {
    const imageTargets: JSX.Element[] = [];
    imageRule.imageTargets?.forEach((imageTarget) => {
      imageTargets.push(
        <ImageRuleBaseTag
          style={{ backgroundColor: `${getImageTargetColor(index + 1)}` }}
          contentName={`調査対象${index + 1}`}
          content={imageTarget.name}
          key={`imageTargets_${index + 1}`}
        />
      );
    });
    return imageTargets;
  };

  const imageElements = (imageRule: ImageRuleDtoResponse) => {
    const images: JSX.Element[] = [];
    imageRule.imageRuleImages?.forEach((imageRuleImage) => {
      images.push(
        <p>
          <img src={imageRuleImage.image.path1} alt="" style={{ width: '176px' }} />
        </p>
      );
    });
    return images;
  };

  const content = monitorRule.afterApplyContent
    ? monitorRule.afterApplyContent.split(/\n/).map((line, i, { length }) => (
        <span key={uuid()}>
          {line}
          {i < length ? <br /> : <></>}
        </span>
      ))
    : '';

  return (
    <Card body style={backgroundColor}>
      {monitorRule.type === MONITOR_RULE_TYPE.INVESTIGATION_DATE && (
        <CalenderTable
          monitorRuleSettingAttribute={monitorRule.monitorRuleSettingAttribute}
          supplement={monitorRule.supplements}
        />
      )}
      {monitorRule.type !== MONITOR_RULE_TYPE.INVESTIGATION_DATE &&
        monitorRule.type !== MONITOR_RULE_TYPE.PHOTO_SHOOT && <div style={{ fontWeight: 'bold' }}>{content}</div>}
      {monitorRule.type !== MONITOR_RULE_TYPE.INVESTIGATION_DATE &&
        monitorRule.supplements?.map((v) => {
          return (
            <div
              key={uuid()}
              style={{ whiteSpace: 'pre-wrap' }}
              className="small"
              dangerouslySetInnerHTML={{ __html: v.supplement }}
            />
          );
        })}
      {monitorRule.imageRules.map((imageRule, index) => {
        return (
          <>
            {imageElements(imageRule)}
            {imageTargetElements(imageRule, index)}
            <ImageRuleBaseTag contentName="撮影目的" content={imageRule.objective} />
            <ImageRuleBaseTag contentName="タイミング" content={imageRule.timing} />
            <ImageRuleBaseTag contentName="範囲" content={imageRule.rangeDisplayName} />
            <ImageRuleBaseTag contentName="範囲フリー記述" content={imageRule.rangeFreeText} />
            <ImageRuleBaseTag contentName="撮影角度" content={imageRule.angleDisplayName} />
            <ImageRuleBaseTag contentName="撮影角度フリー記述" content={imageRule.angleFreeText} />
            <ImageRuleBaseTag contentName="その他注意" content={imageRule.other} />
          </>
        );
      })}
    </Card>
  );
};
const ImageRuleBaseTag: React.FC<{ style?: React.CSSProperties; contentName: string; content?: string }> = ({
  style,
  contentName,
  content,
}) => {
  return content ? (
    <p style={style}>
      {contentName}：{content}
    </p>
  ) : (
    <></>
  );
};
