/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomerTicketHistorySearchForm } from './CustomerTicketHistorySearchForm';
import { CustomerInfoHeader } from '../../Customer/CustomerInfoHeader';

interface SearchConditions {
  applyId?: number;
  applyStatusId?: number;
  enqueteStatusId?: number;
  paidServiceCode?: number;
  shopNameMonitorName?: string;
  surveyProofStatusId?: number;
  winningAtEnd?: string;
  winningAtStart?: string;
}

export const CustomerTicketHistoryPage: FC = () => {
  const methods = useForm<SearchConditions>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { id } = useParams<{ id: string | undefined }>();

  return (
    <>
      <CustomerInfoHeader activeKey="customerTicketHistory" customerId={Number(id)} />
      <FormProvider {...methods}>
        <CustomerTicketHistorySearchForm />
      </FormProvider>
    </>
  );
};
