import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomerTicketCreateFormResponse } from '../../../../api-client';
import { CustomerTicketCreateForm } from './CustomerTicketCreateForm';

export const CustomerTicketCreatePage: React.VFC = () => {
  const methods = useForm<CustomerTicketCreateFormResponse>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  return (
    <>
      <FormProvider {...methods}>
        <CustomerTicketCreateForm />
      </FormProvider>
    </>
  );
};
