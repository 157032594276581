import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Url } from '../../constants/Url';
import { useCurrentUser } from '../../hooks/useCurrentUser';
// eslint-disable-next-line import/no-cycle
import { getUser } from '../../services/AuthService';
import { currentUserState } from '../../states/currentUser';
import { AuthState } from '../../store/reducers/authReducer';
// eslint-disable-next-line import/no-cycle
import { Layout } from '../templates/Layout';
import { LAST_PATH } from '../../Constants';
// eslint-disable-next-line import/no-cycle
import { IncAccountApi } from '../../api-client/api/inc-account-api';

// Dispatchに渡すActionを定義しておく。
export const saveAction: AuthState = {
  user: '',
};

const pagesWithoutSidebar: string[] = [
  // サイドバーがデフォルトで閉じた状態になるページ一覧
  Url.TENSAKU.CORRECT_RECEIPT,
  Url.TENSAKU.CORRECT_ENQUETE,
  Url.TENSAKU.ANSWER_LIST,
];

const excludeLayoutUrl: string[] = [
  // レイアウト自体を無視したいページ一覧
  Url.TENSAKU.ANSWER_HISTORY_LIST,
];

const PrivateRoute: React.FC<React.ComponentProps<typeof Route>> = (props) => {
  const { isAuthChecking } = useCurrentUser();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const history = useHistory();
  useEffect(() => {
    const { location } = props;
    if (location && String(location?.pathname) !== Url.LOGIN) {
      const url = String(location?.pathname);
      localStorage.setItem(LAST_PATH, url.startsWith('/') ? url : `/${url}`);
    }
    // /api/userへアクセス
    getUser()
      .then((result) => {
        if (typeof result === 'object') {
          setCurrentUser(result);
          if (typeof result.apiclientId === 'number' && location?.pathname !== Url.LOGOUT) {
            history.push(Url.OEM.TOP);
          }
        } else {
          setCurrentUser(null);
          // history.push('/login');
        }
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [history, setCurrentUser]);

  if (isAuthChecking) return <></>;
  if (excludeLayoutUrl.find((url) => history.location.pathname.match(url))) return <Route {...props} />;

  return (
    <>
      {/* <AppInit/> */}
      <Layout isSidebarHide={!!pagesWithoutSidebar.find((url) => history.location.pathname.match(url))}>
        <Route {...props} />
      </Layout>
    </>
  );
};

export default PrivateRoute;
