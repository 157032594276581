import * as React from 'react';
import { ReactNode } from 'react';
import { FieldPathValue } from 'react-hook-form/dist/types/utils';
import { Col, Form } from 'react-bootstrap';
import { FieldError, FieldPath, Path, UnpackNestedValue, useController, useFormContext } from 'react-hook-form';
import sass from '../../scss/molecules/billingCommon.module.scss';
import useGetValidationRule, { OptionsType } from '../../hooks/useGetValidationRule';

type Props<T> = {
  className?: string;
  registerName: FieldPath<T>;
  labelName: string;
  readOnly?: boolean;
  type?: 'email' | 'number' | 'month' | 'date';
  children?: ReactNode;
  colNumber?: number;
  defaultValue?: string | number | readonly string[] | undefined;
} & OptionsType;

/**
 * FormProviderでラップされたコンポーネントで使うことが前提
 * FIXME setValueで値をセットしているが、それなしで値が更新されるようにしたい
 * @param className
 * @param register
 * @param registerName
 * @param labelName
 * @param readOnly
 * @param type
 * @param required
 * @param min
 * @param max
 * @param minLength
 * @param maxLength
 * @param children
 * @param colNumber
 * @constructor
 */
export const BillingCommonInputTextWithLabel = <T,>({
  className,
  registerName,
  labelName,
  readOnly,
  type,
  required,
  min,
  max,
  minLength,
  maxLength,
  children,
  colNumber = 6,
  defaultValue,
  isBeforeNow,
}: Props<T>) => {
  const { getRules } = useGetValidationRule();

  const initialType = () => {
    if (type === 'number' || type === undefined) {
      return 'text';
    }
    return type;
  };

  const { register, control, setValue } = useFormContext<T>();
  const {
    fieldState: { error },
  } = useController({ name: registerName, control: control });

  const getLabelWidth = () => {
    if (colNumber === 6) {
      return '65%';
    }
    if (colNumber === 12) {
      return '30%';
    }
    return '65%';
  };

  return (
    <Col className={`col-${colNumber}`}>
      {error !== undefined && <span className={sass.errorMessage}>{(error as FieldError).message}</span>}
      <Form.Group className={`d-flex justify-content-start ${className}`}>
        <Form.Label
          htmlFor={registerName.toString()}
          style={{ width: `${getLabelWidth()}`, margin: '0.5rem' }}
          className={required ? sass.required : ''}
        >
          {labelName}
          {children}
        </Form.Label>
        <Form.Control
          readOnly={readOnly}
          id={registerName.toString()}
          type={initialType()}
          {...register(registerName, getRules({ required, min, max, minLength, maxLength, isBeforeNow }, type))}
          defaultValue={defaultValue}
          onBlur={(e) => setValue(registerName, e.target.value as UnpackNestedValue<FieldPathValue<any, Path<T>>>)}
        />
      </Form.Group>
    </Col>
  );
};
