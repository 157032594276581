import React, { useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import {
  CustomerTicketApi,
  CustomerTicketReturnCreateFormResponse,
  CustomerTicketReturnListOutputResponse,
  IncResultOutputResponse,
} from '../../../../api-client';
import { BillingCommonInputTextWithLabel } from '../../../molecules/BillingCommonInputTextWithLabel';
import { BillingCommonInputSelectWithLabel } from '../../../molecules/BillingCommonInputSelectWithLabel';
import { BillingUpdateResultModal } from '../../../organisms/Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../../BillingConstants';
import { Alert } from '../../../atoms/Alert';
import { isAllEmpty } from '../../../../utils/functions';

export interface SearchConditions {
  shopIds?: string;
  monitorBaseIds?: string;
  applyIds?: string;
  customerTicketReturnRequestStatusCode?: number;
  customerTicketHistoryReasonId?: number;
}

const MAIL_TEMPLATE = [
  { id: 1, name: '閉店・休業' },
  { id: 2, name: 'モニター中止' },
];

const TICKET_RETURN_REQUEST_STATUS = [
  { id: 0, name: '未処理' },
  { id: 1, name: '申請中' },
  { id: 2, name: '返還済み' },
];

const SERVICE_TYPE = [
  { id: 4, name: '期限延長' },
  { id: 5, name: 'キャン待ち' },
];

const IS_SEND_MAIL = [
  { id: 1, name: '送信する', booleanValue: true },
  { id: 2, name: '送信しない', booleanValue: false },
];

export const CustomerTicketReturnListSearchForm: React.VFC = () => {
  const [list, setList] = useState<CustomerTicketReturnListOutputResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const customerTicketApi = new CustomerTicketApi();
  const { handleSubmit, formState } = useFormContext<CustomerTicketReturnCreateFormResponse[]>();
  const [mailTemplateAll, setMailTemplateAll] = useState(1); // デフォで閉店休業
  const [isSendMailAll, setIsSendMailAll] = useState(1); // デフォで送信
  const [reasonAll, setReasonAll] = useState(''); // デフォで空文字

  const fetchData = (data: SearchConditions) => {
    customerTicketApi
      .customerTicketReturnList(
        data.shopIds,
        data.monitorBaseIds,
        data.applyIds,
        data.customerTicketReturnRequestStatusCode,
        data.customerTicketHistoryReasonId
      )
      .then((res) => {
        setList(res.data);
      });
  };

  const onSubmit: SubmitHandler<SearchConditions> = (data) => {
    setErrorMessage((prev) => '');
    if (isAllEmpty(true, ...Object.values(data))) {
      setErrorMessage((prev) => '検索項目はどれか一つ以上値を設定してください');
      return false;
    }
    fetchData(data);
  };

  /**
   * メールテンプレートを変更した場合
   * @param index
   * @param code
   */
  const handleChangeMailTemplate = (index: number, code?: string) => {
    const numCode = typeof code === 'string' ? Number(code) : undefined;
    setList((prev) =>
      prev.map((v, idx) => {
        if (index === idx) {
          return { ...v, customerTicketReturnMailTemplateCode: numCode };
        }
        return v;
      })
    );
  };

  /**
   * メール送信フラグを変更した場合
   * @param index
   * @param code
   */
  const handleChangeIsSendMail = (index: number, code?: string) => {
    const numCode = typeof code === 'string' ? Number(code) : undefined;
    const isSendMail = IS_SEND_MAIL.find((v) => v.id === numCode)?.booleanValue;
    setList((prev) =>
      prev.map((v, idx) => {
        if (index === idx) {
          return { ...v, isSendMail: isSendMail ?? false };
        }
        return v;
      })
    );
  };

  /**
   * 理由を変更した場合
   * @param index
   * @param reason
   */
  const handleChangeReason = (index: number, reason: string) => {
    setList((prev) =>
      prev.map((v, idx) => {
        if (index === idx) {
          return { ...v, reason: reason };
        }
        return v;
      })
    );
  };

  /**
   * 申請内容を登録する
   */
  const handleSubmitApply = () => {
    setErrorMessage((prev) => '');
    // 未申請のもののみ取得
    const createDatas: CustomerTicketReturnCreateFormResponse[] = [];
    // 未申請のデータのみバックエンドに投げる
    const unApplyList = list.filter((v) => v.customerTicketReturnRequestStatus === '未処理');
    // eslint-disable-next-line no-restricted-syntax
    for (const element of unApplyList) {
      if (element.reason === undefined || element.reason === null || element.reason?.trim().length === 0) {
        setErrorMessage((prev) => `理由が入力されていません。応募ID:${element.applyId}`);
        return;
      }
      if (element.isSendMail === true && typeof element.customerTicketReturnMailTemplateCode !== 'number') {
        setErrorMessage(
          (prev) => `メール送信設定になっていますが、テンプレートが選択されていません。応募ID:${element.applyId}`
        );
        return;
      }
      createDatas.push({
        applyId: element.applyId,
        customerTicketHistoryId: element.customerTicketHistoryId,
        customerTicketId: element.customerTicketId,
        isSendMail: element.isSendMail ?? false,
        mailTemplateCode: element.customerTicketReturnMailTemplateCode,
        reason: element.reason ?? '',
      });
    }
    if (createDatas.length > 0) {
      customerTicketApi.customerTicketReturnCreate(createDatas).then((res) => {
        setUpdResult(res.data);
      });
    } else {
      setErrorMessage((prevState) => 'バックエンドに送信するデータがありませんでした。');
    }
  };

  const handleClickSetAllMailTemplate = () => {
    setList((prev) =>
      prev.map((v) => {
        if (v.customerTicketReturnRequestStatus !== '未処理') {
          return v;
        }
        return { ...v, customerTicketReturnMailTemplateCode: mailTemplateAll };
      })
    );
  };

  const handleClickSetAllIsSendMail = () => {
    setList((prev) =>
      prev.map((v) => {
        if (v.customerTicketReturnRequestStatus !== '未処理') {
          return v;
        }
        return { ...v, isSendMail: isSendMailAll === 1 };
      })
    );
  };

  const handleClickSetAllReason = () => {
    setList((prev) =>
      prev.map((v) => {
        if (v.customerTicketReturnRequestStatus !== '未処理') {
          return v;
        }
        return { ...v, reason: reasonAll };
      })
    );
  };

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-2 h2">有料チケット返還</Row>
        <Row>
          <BillingCommonInputTextWithLabel<SearchConditions>
            registerName="shopIds"
            labelName="契約店舗ID(カンマ区切りOK)"
          />
          <BillingCommonInputTextWithLabel<SearchConditions>
            registerName="monitorBaseIds"
            labelName="モニターベースID(カンマ区切りOK)"
          />
        </Row>
        <div style={{ fontSize: 'small', color: 'red' }}>
          ※契約店舗IDまたはモニターベースIDを指定した場合、当選中かつ１度もすべての提出物が出ていない応募が表示されます
        </div>
        <Row>
          <BillingCommonInputTextWithLabel<SearchConditions>
            registerName="applyIds"
            labelName="応募ID(カンマ区切りOK)"
          />
          <BillingCommonInputSelectWithLabel<SearchConditions>
            registerName="customerTicketHistoryReasonId"
            labelName="チケット区分"
            options={SERVICE_TYPE}
          />
        </Row>
        <Row>
          <BillingCommonInputSelectWithLabel<SearchConditions>
            registerName="customerTicketReturnRequestStatusCode"
            labelName="チケット返還ステータス"
            options={TICKET_RETURN_REQUEST_STATUS}
          />
          <Col>{}</Col>
        </Row>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <div className="d-flex justify-content-center m-2">
          <Button variant="secondary" style={{ margin: '20px' }} type="submit">
            検索
          </Button>
        </div>
      </Form>
      <div>
        <div>
          <div style={{ display: 'inline-flex' }}>
            <Form.Label style={{ margin: '0.5rem' }} htmlFor="mailtemplateAll">
              メールテンプレート
            </Form.Label>
            <Form.Select
              id="mailtemplateAll"
              value={mailTemplateAll}
              onChange={(e) => setMailTemplateAll(Number(e.target.value))}
              style={{ width: 'fit-content' }}
            >
              {MAIL_TEMPLATE.map((v) => {
                return (
                  <option key={`mailtemplate_all_${v.id}`} value={v.id}>
                    {v.name}
                  </option>
                );
              })}
            </Form.Select>
            <Button onClick={handleClickSetAllMailTemplate}>一括設定</Button>
          </div>
          <div style={{ display: 'inline-flex' }}>
            <Form.Label style={{ margin: '0.5rem' }} htmlFor="isSendMailAll">
              メール送信
            </Form.Label>
            <Form.Select
              id="isSendMailAll"
              value={isSendMailAll}
              onChange={(e) => setIsSendMailAll(Number(e.target.value))}
              style={{ width: 'fit-content' }}
            >
              {IS_SEND_MAIL.map((v) => {
                return (
                  <option key={`is_send_mail_all_${v.id}`} value={v.id}>
                    {v.name}
                  </option>
                );
              })}
            </Form.Select>
            <Button onClick={handleClickSetAllIsSendMail}>一括設定</Button>
          </div>
          <div style={{ display: 'inline-flex', width: '100%' }}>
            <Form.Label style={{ margin: '0.5rem' }} htmlFor="reasonAll">
              理由
            </Form.Label>
            <Form.Control
              type="text"
              value={reasonAll}
              onChange={(e) => setReasonAll(e.target.value)}
              style={{ width: '80%' }}
            />
            <Button onClick={handleClickSetAllReason}>一括設定</Button>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>契約店舗ID</th>
              <th>契約店舗名</th>
              <th>モニターベースID</th>
              <th>モニターベース名</th>
              <th>応募ID</th>
              <th>応募ステータス</th>
              <th>ユーザーID</th>
              <th>ステータス</th>
              <th>返還登録日</th>
              <th>返還登録者</th>
              <th>チケット区分</th>
              <th>メールテンプレ</th>
              <th>メール</th>
              <th>理由</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item: CustomerTicketReturnListOutputResponse, idx: number) => {
              return (
                <tr key={idx}>
                  <td>{item.shopId}</td>
                  <td>{item.shopName}</td>
                  <td>{item.monitorBaseId}</td>
                  <td>{item.monitorBaseName}</td>
                  <td>{item.applyId}</td>
                  <td>{item.applyStatus}</td>
                  <td>{item.customerId}</td>
                  <td>{item.customerTicketReturnRequestStatus}</td>
                  <td>{item.createAt}</td>
                  <td>{item.createBy}</td>
                  <td>{item.customerTicketServiceType}</td>
                  <td>
                    <Form.Select
                      value={item.customerTicketReturnMailTemplateCode === 2 ? 2 : 1}
                      disabled={item.customerTicketReturnRequestStatus !== '未処理'}
                      onChange={(e) => handleChangeMailTemplate(idx, e.target.value)}
                    >
                      <option>{}</option>
                      {MAIL_TEMPLATE.map((v) => {
                        return (
                          <option key={`mailtemplate_${v.id}`} value={v.id}>
                            {v.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      value={item.isSendMail === false ? 2 : 1}
                      disabled={item.customerTicketReturnRequestStatus !== '未処理'}
                      onChange={(e) => handleChangeIsSendMail(idx, e.target.value)}
                    >
                      {IS_SEND_MAIL.map((v) => {
                        return (
                          <option key={`isSendMail_${v.id}`} value={v.id}>
                            {v.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Control
                      disabled={item.customerTicketReturnRequestStatus !== '未処理'}
                      type="text"
                      value={item.reason}
                      onChange={(e) => handleChangeReason(idx, e.target.value)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center">
          {formState.isSubmitted && list.length === 0 && <p>データはありませんでした。</p>}
          {formState.isSubmitted && list.length > 0 && <Button onClick={handleSubmitApply}>登録</Button>}
        </div>
      </div>
    </>
  );
};
