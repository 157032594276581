import * as React from 'react';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSettingAttributeDtoResponse,
} from '../../../api-client';
import sass from '../../../scss/organisms/calendarTable.module.scss';
import { DayOfWeek } from '../../../Constants';
import { isAllTrue } from '../../../utils/functions';

export const CalenderTable = ({
  monitorRuleSettingAttribute,
  supplement,
}: {
  monitorRuleSettingAttribute?: MonitorRuleSettingAttributeDtoResponse | MonitorRuleSetInfoMonitorRuleOutputResponse;
  supplement?: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[];
}) => {
  const canAllwaysCome = isAllTrue(
    monitorRuleSettingAttribute?.mondayVisitsFlg,
    monitorRuleSettingAttribute?.tuesdayVisitsFlg,
    monitorRuleSettingAttribute?.wednesdayVisitsFlg,
    monitorRuleSettingAttribute?.thursdayVisitsFlg,
    monitorRuleSettingAttribute?.fridayVisitsFlg,
    monitorRuleSettingAttribute?.saturdayVisitsFlg,
    monitorRuleSettingAttribute?.sundayVisitsFlg,
    monitorRuleSettingAttribute?.publicHolidayVisitsFlg
  );
  return (
    <>
      <table className={sass.calender_table}>
        <thead>
          <tr>
            {DayOfWeek.map((day, index) => {
              if (
                (typeof monitorRuleSettingAttribute?.publicHolidayVisitsFlg === 'boolean' && !canAllwaysCome) ||
                day !== '祝'
              ) {
                return <th key={`${day}_${index}`}>{day}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{monitorRuleSettingAttribute?.mondayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.tuesdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.wednesdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.thursdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.fridayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.saturdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.sundayVisitsFlg ? '○' : '×'}</td>
            {typeof monitorRuleSettingAttribute?.publicHolidayVisitsFlg === 'boolean' && !canAllwaysCome && (
              <td>{monitorRuleSettingAttribute?.publicHolidayVisitsFlg ? '○' : '×'}</td>
            )}
          </tr>
        </tbody>
      </table>
      <div className={sass.supplement}>{supplement && supplement.map((v) => <span>{v.supplement}</span>)}</div>
    </>
  );
};
