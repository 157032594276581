// - 独自定義

export enum TemplateFormMode {
  NOTE = 'NOTE',
  NG = 'NG',
  ESCALE = 'ESCALE',
  REJECT = 'REJECT',
  ANSWER = 'ANSWER',
}

export enum ResultModalMode {
  OK = 'OK',
  RESEND = 'RESEND',
  REJECT = 'REJECT',
  ESCALE = 'ESCALE',
}

export interface TemplateFormBodyState {
  content: string;
  templateId?: number;
  templateContent: string;
  shopReviewDisplayFlg?: boolean;
}

// - Kotlin実装から引用

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/receiptCorrection/vo/ApplyReceiptCheckType.kt
 */
export enum ApplyReceiptCheckType {
  COMMON,
  /** 当選店舗はあってますか？ */
  VISITS_SHOP,
  /** 指定の来店証明ですか？ */
  TARGET_SURVEY_PROOF,
  /** 当選後に来店したレシートですか？ */
  VISIT_AT,
  /** レシートNoの重複はないですか？ */
  RECEIPT_NO,
  /** 重複画像の確認 */
  DUPLICATE_IMAGE,
  /** モラルが悪いユーザとの重複チェック */
  LINK_ILLEGAL_MORAL_CHECK,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/note/vo/DisplayPointType.kt
 */
export enum DisplayPointType {
  FOR_ALL,
  APPLY_RECEIPT_CHECK_POINT,
  QUESTION,
  ANSWER,
  MONITOR_RULE,
  MONITOR_RULE_TYPE,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/orange-inc-back/src/main/kotlin/com/roi/fancrew/inc/usecase/interactor/receiptCorrection/ReceiptCorrectRejectApprovalExecuteUseCase.kt
 */
export enum RejectAnswerCTIdType {
  /** approve */
  APPROVE,
  /** remand */
  BACK,
  /** ユーコミへのエスカレ */
  UCOMI_ESCALE,
  /** 営業へのエスカレ */
  SALES_ESCALE,
  /** 開発へのエスカレ TODO いつか使えるようにする */
  DEV_ESCALE,
  /** SVへの回答 */
  ANSWER_TO_SV,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/correction/vo/EscalationWorkType.kt
 */
export enum DisplayControlEscalate {
  /** DISABLE 作業不可(履歴のみ) */
  DISABLE,
  /** ESCALATION エスカレーション(履歴 + 事務所へ質問) */
  DISPLAY,
  /** ESCALATION_ANSWER エスカレ回答(履歴 + エスカレ回答) */
  ANSWER,
  /** ESCALATION_OTHER_DISPATCHER_AND_ESCALATION_ANSWER エスカレ回答及び他部署へのエスカレ(未使用) */
  // OTHER,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/correction/vo/RejectWorkType.kt
 */
export enum DisplayControlReject {
  /** DISABLE 作業不可 */
  DISABLE,
  /** REJECT_APPROVE_REQUEST 却下承認依頼 */
  REQUEST,
  /** REJECT_ANSWER 却下承認 */
  APPROVE_WITH_ESCALE,
  /** ESCALATION_OTHER_DISPATCHER_AND_REJECT_APPROVE 却下承認及び他部署へのエスカレ */
  APPROVE_WITH_ANSWER,
  /** REJECT_EXECUTE 却下実施 */
  EXECUTE,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/correction/vo/CorrectionWorkType.kt
 */
export enum CorrectionWorkType {
  /** 添削OK */
  CHECK_OK,
  /** 再提出依頼 */
  RESUBMIT_REQUEST,
  /** 却下承認依頼 */
  REJECT_REQUEST,
  /** 却下承認 */
  REJECT_APPROVE,
  /** 却下実施 */
  REJECT,
  /** エスカレ(事務所へ質問？) */
  ESCALATION,
  /** エスカレ回答 */
  ESCALATION_ANSWER,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/correction/vo/CorrectionTargetType.kt
 */
export enum CorrectionTargetType {
  /** レシート */
  RECEIPT,
  /** 納品書 */
  DELIVERY_SLIP,
  /** アンケート */
  ENQUETE,
  /** 再提出アンケート */
  RESUBMIT_ENQUETE,
  /** レシート(データ入力) */
  RECEIPT_DATA_INPUT,
  /** レシートNG（アンケートもNG）要 */
  RECEIPT_ALSO_ENQUETE,
  /** レシート(データ入力)NGのアンケートNG用 */
  RECEIPT_DATA_INPUT_ALSO_ENQUETE,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/enquete/vo/AnswerMethodSummary.kt
 */
export enum AnswerMethodSummary {
  /** 画像系 */
  IMAGE,
  /** 自由記述系 */
  TEXT,
  /** 選択肢系 */
  SELECT,
}

export enum AnswerMethod {
  TEXT = 1,
  RADIO_SINGLE = 2,
  DROP_SINGLE = 3,
  MULTI = 4,
  MATRIX_SINGLE = 5,
  MATRIX_MULTI = 6,
  IMAGE = 7,
  SLIDER = 8,
  DATE = 9,
  TIME = 10,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/orange-inc-back/src/main/kotlin/com/roi/fancrew/inc/usecase/interactor/correctionTemplate/CorrectionTypeEnum.kt
 */
export enum CorrectionType {
  /** レシート添削Flg */
  RECEIPT_CORRECTION_FLG,
  /** 納品書添削Flg */
  INVOICE_CORRECTION_FLG,
  /** アンケート添削Flg */
  ENQUETE_CORRECTION_FLG,
  /** レシートデータ入力Flg */
  RECEIPT_DATA_ENTRY_FLG,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/correction/vo/OperationTypeGroup.kt
 */
export enum OperationTypeGroup {
  /** NG */
  NG = 0,

  // BULK_ENQUETE_NG = 1,
  // ATTENTION = 2,

  /** エスカレ */
  ESCALATION = 3,
  /** 回答 */
  ESCALATION_ANSWER = 4,
  /** 却下 */
  REJECT_REQUEST_MESSAGE_TO_CUSTOMER = 5,

  // REJECT_REQUEST_MESSAGE_TO_EXECUTIVE_STAFF = 6,
  // REJECT_ANSWER = 7,
  // AUTO_ESCALATION = 8,

  /** ノート */
  NOTE = 9,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/orange-inc-back/src/main/kotlin/com/roi/fancrew/inc/adapter/presenter/enqueteCorrection/AnswerAndHistoryType.kt
 */
export enum AnswerAndHisotryType {
  NG,
  CUSTOMER_EDIT,
  INC_EDIT,
}

/**
 * https://github.com/roi-dev-organization/orange-backend/blob/develop/core/src/main/kotlin/com/roi/fancrew/core/domain/model/monitorRule/vo/MonitorRuleType.kt
 */
export const MONITOR_RULE_TYPES = {
  MONITOR_RULE: { code: 1, displayName: 'モニタールール' },
  INVESTIGATION_DATE: { code: 2, displayName: '調査日' },
  TIME: { code: 3, displayName: '時間' },
  NUMBER_OF_PEOPLE_OR_COMPANION: { code: 4, displayName: '人数・同伴者' },
  RESERVE: { code: 5, displayName: '予約' },
  TARGET_ITEM: { code: 6, displayName: '指定商品' },
  PHOTO_SHOOT: { code: 7, displayName: '写真撮影' },
  CLERK_INVESTIGATION: { code: 8, displayName: '店員調査' },
  TOILET_INVESTIGATION: { code: 9, displayName: 'トイレ調査' },
  CLEANING_INVESTIGATION: { code: 10, displayName: '清掃状況調査' },
  OTHER_INVESTIGATION: { code: 11, displayName: 'その他調査' },
  PAYMENT: { code: 12, displayName: '支払方法' },
  COUPON_OR_DISCOUNT: { code: 13, displayName: 'クーポン・割引' },
  INVESTIGATION_EVIDENCE: { code: 14, displayName: '調査証明' },
  REMARK: { code: 15, displayName: '特記事項' },
  NOTE: { code: 16, displayName: '注意事項' },
  QUALIFICATION: { code: 17, displayName: '応募条件' },
  ORDER_METHOD: { code: 18, displayName: '注文方法' },
  TARGET_BRAND: { code: 19, displayName: '対象ブランド' },
  APPLICATION_METHOD: { code: 20, displayName: '申込方法' },
  APPEARANCE_INVESTIGATION: { code: 21, displayName: '外観調査' },
  PURCHASE_STORE: { code: 22, displayName: '購入店舗' },
  OPERATION_INVESTIGATION: { code: 23, displayName: '施術調査' },
  EXPERIENCE_INVESTIGATION: { code: 24, displayName: '体験調査' },
  OTHER: { code: 99, displayName: 'その他' },
} as const;

export const ReceiptComplicateCheckType = [
  ApplyReceiptCheckType.VISITS_SHOP,
  ApplyReceiptCheckType.TARGET_SURVEY_PROOF,
  ApplyReceiptCheckType.VISIT_AT,
  ApplyReceiptCheckType.RECEIPT_NO,
  ApplyReceiptCheckType.DUPLICATE_IMAGE,
  ApplyReceiptCheckType.LINK_ILLEGAL_MORAL_CHECK,
];

export enum CanCorrectType {
  // 添削中
  CORRECTING,
  // 未アサイン
  CAN_ASSIGN,
  // 他者のアサイン
  OTHERS_ASSIGNED,
  // 添削対象外のステータス
  CANT_CORRECT_STATUS,
  // 権限不足
  NO_AUTH,
}

export enum EscalationRejectStatus {
  WAITING_EXECUTIVE_STAFF_ANSWER,
  WAITING_UCOMI_ANSWER,
  WAITING_OTHER_DEPARTMENT_ANSWER,
  COMPLETE,
}

export enum FormEditStatus {
  INIT,
  EDITING,
  SAVED,
}
