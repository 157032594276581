/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Card, FloatingLabel, Form, FormControl, ListGroup } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { MonitorRuleCard } from '../../organisms/MonitorRuleCard';
import { MonitorRuleQuestionAssociationModal } from '../../organisms/Modal/MonitorRuleQuestionAssociationModal';
import {
  ImageUpdateFormResponse,
  IncResultOutputResponse,
  MonitorRuleApi,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoOutputResponse,
  MonitorRuleSetUpdateFormResponse,
  MonitorRuleSetUpdateMonitorRuleFormResponse,
  MonitorRuleSetUpdateMonitorRuleImageRuleFormResponse,
  MonitorRuleSetUpdateMonitorRuleImageRuleImageFormResponse,
  MonitorRuleSetUpdateMonitorRuleSupplementFormResponse,
  MonitorRuleSetUpdateMonitorRuleTargetItemFormResponse,
  MonitorRuleSetUpdateMonitorRuleVisitTimeSetFormResponse,
} from '../../../api-client';
import { getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../../utils/s3';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../Constants';

export interface MonitorRuleSetKind {
  id: number;
  name: string;
}

export interface ImageListData {
  imageId: number;
  imageURL: string;
}

export interface TargetItemData {
  itemName: string;
  orderQuantity: number;
  supplement: string;
  imageflg: boolean;
  image_quantity: number;
  excludedItem: string;
}

export interface ImageRuleListData {
  objective: string;
  timing: string;
  range: string;
  rangeFreeText: string;
  angle: string;
  angleFreeText: string;
  other: string;
  imageTargetList?: string[];
  imageList?: ImageListData[];
}

export interface VisitTimeSetList {
  timeForm?: Date;
  timeTo?: Date;
}

export interface SupplementListData {
  id: number;
  supplement: string;
}

export interface MonitorRuleListData {
  monitorRuleType: number;
  monitorRuleId: number;
  monitorRuleName: string;
  beforeApplyContent?: string;
  afterApplyContent?: string;
  url?: string;
  phoneNumber?: number;
  supplementList?: SupplementListData[];
  imageId?: number;
  imageUrl: string;
  iconId?: number;
  questionId?: number;
  index?: number;
  staying_time?: number;
  minimum_visits_count?: number;
  maximum_visits_count?: number;
  minor_accompany_not_possible_flg?: boolean;
  monday_visits_flg?: boolean;
  tuesday_visits_flg?: boolean;
  wednesday_visits_flg?: boolean;
  thursday_visits_flg?: boolean;
  friday_visits_flg?: boolean;
  saturday_visits_flg?: boolean;
  sunday_visits_flg?: boolean;
  public_holiday_visits_flg?: boolean;
  celebration_previous_day_visits_flg?: boolean;
  payment_type_cash_flg?: boolean;
  payment_type_credit_card_flg?: boolean;
  payment_type_coupon_ok_flg?: boolean;
  reserve_type?: number;
  reserve_phone_number?: string;
  reserve_url?: string;
  sameday_reservations_flg?: boolean;
  dedicated_reservation_site_flg?: boolean;
  coupon_use_ok_flg?: boolean;
  evidence_type?: number;
  male_flg?: boolean;
  female_flg?: boolean;
  age_10s_flg?: boolean;
  age_20s_flg?: boolean;
  age_30s_flg?: boolean;
  age_40s_flg?: boolean;
  age_50s_flg?: boolean;
  age_60s_flg?: boolean;
  age_70s_flg?: boolean;
  age_80s_flg?: boolean;
  age_90s_flg?: boolean;
  ageFrom?: number;
  ageTo?: number;
  targetItemList?: TargetItemData[];
  ngItemList?: string[];
  imageRuleList?: ImageRuleListData[];
  monitorRuleImageList?: [{ imageId: number; imageURL: string }];
  visitTimeSetList?: VisitTimeSetList[];
  imageRuleId?: number;
  target?: string;
  objective?: string;
  timing?: string;
  range?: string;
  angle?: string;
  other?: string;
  imageRuleImageList?: [{ imageId: number; imageFileURL: string }];
  purchaseShopFileName?: string;
  work?: string;
  other_condition?: string;
}

export interface MonitorRuleSetData {
  setName: string;
  description: string;
  monitorRuleSetType: number;
  monitorRuleSetList: MonitorRuleListData[];
}

export interface ImageProp {
  imageList?: {
    isDefault: boolean;
    id?: number;
    name?: string;
    path?: string;
    file?: File;
    contentType?: string;
    width?: number;
    height?: number;
    format?: string;
    monitorRuleListIdx?: number;
    imageRuleListIdx?: number;
  }[];
}
interface UploadImageObj {
  id?: number;
  path: string;
  monitorRuleListIdx: number;
  imageRuleListIdx: number;
}
interface UploadImageFormObj {
  id: number;
  path: string;
  monitorRuleListIdx: number;
  imageRuleListIdx: number;
}

export const sampleMonitorRuleSetKindData: MonitorRuleSetKind[] = [
  {
    id: 1,
    name: 'グルメ（イートイン）',
  },
  {
    id: 2,
    name: 'グルメ（テイクアウト）',
  },
  {
    id: 3,
    name: 'グルメ（デリバリー）',
  },
  {
    id: 4,
    name: '通販',
  },
  {
    id: 5,
    name: 'ショッピング',
  },
  {
    id: 6,
    name: '美容',
  },
  {
    id: 9,
    name: 'その他',
  },
];

export const MonitorRuleSetModifyPage: React.VFC = () => {
  const [data, setData] = useState<MonitorRuleSetInfoOutputResponse & ImageProp>({
    id: 0,
    name: '',
    monitorRuleList: [],
    monitorRuleSetType: 1,
    description: undefined,
    linkedMonitors: [],
    imageList: [],
  });
  const [monitorRuleKindChangeFlg, setMonitorRuleKindChangeFlg] = useState<boolean>(false);
  const [isQuestionAssociationModalFlg, setIsQuestionAssociationModalFlg] = useState<boolean>(false);

  const [selectedMonitorRuleId, setSelectedMonitorRuleId] = useState<number>(0);

  const [previousMonitorRuleSetType, setPreviousMonitorRuleSetType] = useState<number | undefined>();

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  // ルールセット名入力フラグ
  const [ruleSetNameInputFlg, setRuleSetNameInputFlg] = useState<boolean>(true);

  // バリデーションメッセージ
  const [validationErrorMsgs, setValidationErrorMsgs] = useState<string[]>();

  const history = useHistory();

  const { ruleSetId } = useParams<{
    ruleSetId: string | undefined;
  }>();
  const monitorRuleApi = new MonitorRuleApi();

  // 初期表示処理
  useEffect(() => {
    // 編集時
    if (ruleSetId != null) {
      monitorRuleApi
        .monitorRuleSetInfo(Number(ruleSetId))
        .then((res: AxiosResponse<MonitorRuleSetInfoOutputResponse>) => {
          const defaultImageList: ImageProp['imageList'] = [];

          res.data.monitorRuleList.forEach((monitorRuleList, idx) => {
            monitorRuleList.imageRuleList.forEach((imageRuleList, ind) => {
              imageRuleList.imageList.forEach(({ imageId, imageURL }) => {
                defaultImageList.push({
                  id: imageId,
                  path: imageURL,
                  monitorRuleListIdx: idx,
                  imageRuleListIdx: ind,
                  isDefault: !!imageId,
                });
              });
            });
          });
          setData({ ...res.data, imageList: defaultImageList });
          setRuleSetNameInputFlg(true);
          setPreviousMonitorRuleSetType(res.data.monitorRuleSetType);
        });
    } else {
      setData({
        ...data,
        monitorRuleList: newInitialSetting(),
      });
    }
  }, []);

  useEffect(() => {
    if (previousMonitorRuleSetType !== data.monitorRuleSetType) {
      changeMonitorRuleSetType(data.monitorRuleSetType);
    }
  }, [data.monitorRuleSetType]);

  // 「モニタールールセット種別」変更時の処理
  const changeMonitorRuleSetType = (monitorRuleSetNumber: number) => {
    const copyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Object.assign(
      [],
      data?.monitorRuleList
    );

    const monitorRule = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 1;
    });
    const surveyDate = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 2;
    });
    const time = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 3;
    });
    const numberPeople = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 4;
    });
    const reserve = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 5;
    });
    const item = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 6;
    });
    const photoShot = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 7;
    });
    const clerkSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 8;
    });
    const toiletSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 9;
    });
    const cleaningSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 10;
    });
    const otherSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 11;
    });
    const paymentMethod = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 12;
    });
    const coupon = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 13;
    });
    const surveyProof = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 14;
    });
    const remarks = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 15;
    });
    const notes = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 16;
    });
    const applicationCondition = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 17;
    });
    const orderCondition = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 18;
    });
    const targetBrand = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 19;
    });
    const applicationProcess = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 20;
    });
    const exteriorSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 21;
    });
    const purchaseShop = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 22;
    });
    const menu = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 23;
    });

    const newCopyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = [];

    // 入力された「モニタールールセット種別」によって分岐
    switch (monitorRuleSetNumber) {
      case 2:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 3);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 4);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);

        break;
      case 3:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 注文方法
        settingData(newCopyMonitorRuleSetList, orderCondition, 18, 0, '注文方法', 3);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 4);
        // 対象ブランド
        settingData(newCopyMonitorRuleSetList, targetBrand, 19, 0, '対象ブランド', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);
        break;
      case 4:
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 1);
        // 申込方法
        settingData(newCopyMonitorRuleSetList, applicationProcess, 20, 0, '申込方法', 2);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 3);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 4);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 5);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 6);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 7);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 8);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 9);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 10);
        break;
      case 1:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 3);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 4);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // 外観調査
        settingData(newCopyMonitorRuleSetList, exteriorSurvey, 21, 0, '外観調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);
        break;
      case 5:
        // 購入店舗
        settingData(newCopyMonitorRuleSetList, purchaseShop, 22, 0, '購入店舗', 1);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 2);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 3);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 4);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 5);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 6);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 7);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 8);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 9);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 10);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 11);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 12);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 13);
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 14);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 15);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 16);
        break;
      case 6:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 3);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 4);
        // 指定メニュー・コース
        settingData(newCopyMonitorRuleSetList, menu, 23, 0, '指定メニュー・コース', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // 外観調査
        settingData(newCopyMonitorRuleSetList, exteriorSurvey, 21, 0, '外観調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);
        break;
      default:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 2);
        // 指定メニュー・コース
        settingData(newCopyMonitorRuleSetList, menu, 23, 0, '指定メニュー・コース', 3);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 4);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 5);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 6);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 7);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 8);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 9);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 10);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 11);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 12);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 13);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 14);
        break;
    }

    setData({ ...data, monitorRuleList: newCopyMonitorRuleSetList });
  };

  const settingData = (
    addList: MonitorRuleSetInfoMonitorRuleOutputResponse[],
    targetList: MonitorRuleSetInfoMonitorRuleOutputResponse | undefined,
    monitorRuleType: number,
    monitorRuleId: number,
    monitorRuleName: string,
    index: number
  ) => {
    // 写真撮影でない場合
    if (targetList?.monitorRuleName !== '写真撮影') {
      console.log(targetList?.monitorRuleName);
      // 入力データが存在している場合
      if (targetList !== undefined) {
        // ルールセット種別を変更した場合、注意事項の補足文はクリアする。
        if (monitorRuleType === 16 && monitorRuleKindChangeFlg) {
          addList.push({
            ...targetList,
            monitorRuleId,
            supplementList: [
              {
                id: 0,
                supplement: '',
              },
            ],
          });
        } else {
          addList.push({ ...targetList, monitorRuleId });
        }

        return;
      }
      addList.push({
        monitorRuleType,
        monitorRuleId,
        monitorRuleName,
        disableFlg: false,
        evidenceType: monitorRuleType === 14 ? 0 : undefined,
        imageRuleList: [],
        index,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: monitorRuleType === 3 ? [{ timeFrom: undefined, timeTo: undefined }] : [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    } else {
      // 入力データが存在している && 「モニタールール種別」の変更がない場合（編集初期時）
      if (targetList !== undefined && !monitorRuleKindChangeFlg) {
        addList.push({ ...targetList, monitorRuleId });
        return;
      }
      addList.push({
        monitorRuleType,
        monitorRuleId,
        monitorRuleName,
        disableFlg: false,
        imageRuleList: [],
        index,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    }
  };

  // 新規追加時、初期設定関数
  const newInitialSetting = () => {
    const copyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Object.assign(
      [],
      data?.monitorRuleList
    );
    // モニタールール種別「グルメ（イートイン）」のContents項目をセットする

    for (let index = 0; index < 17; index += 1) {
      let monitorRuleName = '';
      let monitorRuleType = 0;

      switch (index) {
        case 1:
          monitorRuleName = '調査日';
          monitorRuleType = 2;
          break;
        case 2:
          monitorRuleName = '時間';
          monitorRuleType = 3;
          break;
        case 3:
          monitorRuleName = '人数・同伴者';
          monitorRuleType = 4;
          break;
        case 4:
          monitorRuleName = '予約';
          monitorRuleType = 5;
          break;
        case 5:
          monitorRuleName = '指定商品';
          monitorRuleType = 6;
          break;
        case 6:
          monitorRuleName = '写真撮影';
          monitorRuleType = 7;
          break;
        case 7:
          monitorRuleName = '店員調査';
          monitorRuleType = 8;
          break;
        case 8:
          monitorRuleName = 'トイレ調査';
          monitorRuleType = 9;
          break;
        case 9:
          monitorRuleName = '清掃状況調査';
          monitorRuleType = 10;
          break;
        case 10:
          monitorRuleName = '外観調査';
          monitorRuleType = 11;
          break;
        case 11:
          monitorRuleName = '支払方法';
          monitorRuleType = 12;
          break;
        case 12:
          monitorRuleName = 'クーポン・割引';
          monitorRuleType = 13;
          break;
        case 13:
          monitorRuleName = '調査証明';
          monitorRuleType = 14;
          break;
        case 14:
          monitorRuleName = '特記事項';
          monitorRuleType = 15;
          break;
        case 15:
          monitorRuleName = '注意事項';
          monitorRuleType = 16;
          break;
        default:
          monitorRuleName = '応募条件';
          monitorRuleType = 17;
          break;
      }

      copyMonitorRuleSetList.push({
        monitorRuleType,
        monitorRuleId: 0,
        monitorRuleName,
        disableFlg: false,
        imageRuleList: [],
        index: 0,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    }

    return copyMonitorRuleSetList;
  };

  const uploadImage = async (image: any) => {
    const { isDefault, path, contentType, file, width, height, format } = image;
    const imageId = image.id;
    const imageName = image.name;

    const returnObj: UploadImageObj = {
      id: imageId,
      path,
      monitorRuleListIdx: image.monitorRuleListIdx,
      imageRuleListIdx: image.imageRuleListIdx,
    };

    try {
      if (file && imageName && contentType && width && height && format && !isDefault) {
        const presignedUrl = await getPresignedUrl({
          contentType,
          fileKey: imageName,
          objectType: OBJECT_TYPE.MONITOR_RULE,
        });
        const uploadTime = await s3Upload(presignedUrl, contentType, file);

        const alignmentParam: ImageUpdateFormResponse = { width, height, format };
        if (isDefault) {
          alignmentParam.imageId = imageId;
        } else {
          alignmentParam.imageTypeId = IMAGE_TYPE_ID.MONITOR_RULE_PHOTO;
          const presignedPath = presignedUrl.replace(/\?.*$/, '');
          alignmentParam.path = presignedPath.substring(
            presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
          );
          alignmentParam.uploadTime = uploadTime;
        }

        await s3UploadAlignment(alignmentParam).then((res: IncResultOutputResponse) => {
          if (!res?.result) {
            setUpdResult(res);
          }
          if (res.id && res.id > 0) {
            returnObj.id = res.id;
          }
        });
      } else if (isDefault && !path && imageId) {
        await s3Delete(imageId).then((res) => {
          if (!res?.result) {
            setUpdResult(res);
            return;
          }
          setData({ ...data, imageList: data.imageList?.filter((list) => list.id !== imageId) });
          returnObj.id = undefined;
        });
      }

      return returnObj;
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  // 保存イベント
  const onSubmit = async (uploadImageObjList: UploadImageFormObj[]) => {
    const deleteMonitorRuleList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = data.monitorRuleList.filter((_) => {
      return _.disableFlg;
    });
    const deleteMonitorRuleIds: number[] = [];
    const dataList: MonitorRuleSetUpdateMonitorRuleFormResponse[] = [];

    // モニタールールセット名必須チェック
    if (data.name == null || data.name === '') {
      setRuleSetNameInputFlg(false);
      return;
    }

    // モニタールールのバリデーション。
    // 取りあえずtoc側のモニター検索条件に使用される「応募条件」ルールだけ入力チェックを行う。
    const applicationConditionRule = data.monitorRuleList.find((rule) => rule.monitorRuleType === 17);
    if (applicationConditionRule) {
      const maleFlg = applicationConditionRule.maleFlg || false;
      const femaleFlg = applicationConditionRule.femaleFlg || false;

      const ageFlgs = [
        applicationConditionRule.age10sFlg || false,
        applicationConditionRule.age20sFlg || false,
        applicationConditionRule.age30sFlg || false,
        applicationConditionRule.age40sFlg || false,
        applicationConditionRule.age50sFlg || false,
        applicationConditionRule.age60sFlg || false,
        applicationConditionRule.age70sFlg || false,
        applicationConditionRule.age80sFlg || false,
        applicationConditionRule.age90sFlg || false,
      ];

      const ageFrom = applicationConditionRule.ageFrom ?? undefined;
      const ageTo = applicationConditionRule.ageTo ?? undefined;

      const errorMsgs = [];

      // 性別は必須
      if (!maleFlg && !femaleFlg) {
        errorMsgs.push('応募条件の性別を選択してください。');
      }

      // 年代と年齢From-To どちらも指定するのは NG
      if (ageFlgs.some((ageFlg) => ageFlg) && (ageFrom || ageTo)) {
        errorMsgs.push('応募条件の年代と年齢範囲は両方を指定することはできません。');
      }

      // 年齢From-Toを指定する場合、両方必須。※from-toの整合性まではチェックしない。
      if (ageFlgs.every((ageFlg) => !ageFlg)) {
        if ((ageFrom != null && ageTo == null) || (ageFrom == null && ageTo != null)) {
          errorMsgs.push('応募条件の年齢範囲を指定する場合、From-Toの両方を指定してください。');
        }
      }

      if (errorMsgs.length !== 0) {
        setValidationErrorMsgs(errorMsgs);
        return;
      }
    }

    // 論理削除予定モニタールールセットを詰める TODO 本当に必要？
    // for (let index = 0; index < deleteMonitorRuleList.length; index += 1) {
    //   deleteMonitorRuleIds.push(deleteMonitorRuleList[index].monitorRuleId);
    // }

    // モニタールールセットを詰める
    for (let i = 0; i < data.monitorRuleList.length; i += 1) {
      const supplementList: MonitorRuleSetUpdateMonitorRuleSupplementFormResponse[] = [];
      const targetItemList: MonitorRuleSetUpdateMonitorRuleTargetItemFormResponse[] = [];
      const targetImageRuleList: MonitorRuleSetUpdateMonitorRuleImageRuleFormResponse[] = [];
      const visitTimeSetList: MonitorRuleSetUpdateMonitorRuleVisitTimeSetFormResponse[] = [];
      // 補足リスト取得
      for (let index = 0; index < data.monitorRuleList[i].supplementList.length; index += 1) {
        supplementList.push({
          id:
            data.monitorRuleList[i].supplementList[index].id === 0
              ? undefined
              : data.monitorRuleList[i].supplementList[index].id,
          supplement: data.monitorRuleList[i].supplementList[index].supplement,
        });
      }
      // 指定商品リスト取得
      for (let index = 0; index < data.monitorRuleList[i].targetItemList.length; index += 1) {
        targetItemList.push({
          itemName: data.monitorRuleList[i].targetItemList[index].itemName,
          orderQuantity: data.monitorRuleList[i].targetItemList[index].orderQuantity,
          supplement: data.monitorRuleList[i].targetItemList[index].supplement,
          imageFlg: data.monitorRuleList[i].targetItemList[index].imageFlg,
          imageQuantity: data.monitorRuleList[i].targetItemList[index].imageQuantity,
          excludedItem: data.monitorRuleList[i].targetItemList[index].excludedItem,
        });
      }
      // 指定商品リスト取得
      for (let index = 0; index < data.monitorRuleList[i].imageRuleList.length; index += 1) {
        const imageList: MonitorRuleSetUpdateMonitorRuleImageRuleImageFormResponse[] = [];
        // 画像リスト取得
        uploadImageObjList
          .filter((obj) => obj.monitorRuleListIdx === i && obj.imageRuleListIdx === index)
          .forEach((obj) => {
            imageList.push({ imageId: obj.id, imageURL: obj.path });
          });

        const imageTargetList = Object.assign([], data.monitorRuleList[i].imageRuleList[index].imageTargetList);
        targetImageRuleList.push({
          objective: data.monitorRuleList[i].imageRuleList[index].objective,
          timing: data.monitorRuleList[i].imageRuleList[index].timing,
          range: data.monitorRuleList[i].imageRuleList[index].range,
          rangeFreeText: data.monitorRuleList[i].imageRuleList[index].rangeFreeText,
          angle: data.monitorRuleList[i].imageRuleList[index].angle,
          angleFreeText: data.monitorRuleList[i].imageRuleList[index].angleFreeText,
          other: data.monitorRuleList[i].imageRuleList[index].other,
          imageList,
          imageTargetList: imageTargetList === undefined ? [] : imageTargetList,
        });
      }
      // 来店時間リスト取得
      for (let index = 0; index < data.monitorRuleList[i].visitTimeSetList.length; index += 1) {
        if (
          !(
            data.monitorRuleList[i].visitTimeSetList[index].timeFrom === undefined &&
            data.monitorRuleList[i].visitTimeSetList[index].timeTo === undefined
          )
        ) {
          visitTimeSetList.push({
            timeFrom: {
              hour: data.monitorRuleList[i].visitTimeSetList[index].timeFrom?.hour ?? 0,
              minute: data.monitorRuleList[i].visitTimeSetList[index].timeFrom?.minute ?? 0,
            },
            timeTo: {
              hour: data.monitorRuleList[i].visitTimeSetList[index].timeTo?.hour ?? 0,
              minute: data.monitorRuleList[i].visitTimeSetList[index].timeTo?.minute ?? 0,
            },
          });
        }
      }

      dataList.push({
        monitorRuleType: data.monitorRuleList[i].monitorRuleType,
        monitorRuleId: data.monitorRuleList[i].monitorRuleId === 0 ? undefined : data.monitorRuleList[i].monitorRuleId,
        monitorRuleName: data.monitorRuleList[i].monitorRuleName,
        beforeApplyContent: data.monitorRuleList[i].beforeApplyContent,
        afterApplyContent: data.monitorRuleList[i].afterApplyContent,
        supplementList,
        imageId: data.monitorRuleList[i].imageId,
        iconType: data.monitorRuleList[i].iconType,
        questionId: data.monitorRuleList[i].questionId,
        stayingTime: data.monitorRuleList[i].stayingTime,
        minimumVisitsCount: data.monitorRuleList[i].minimumVisitsCount,
        maximumVisitsCount: data.monitorRuleList[i].maximumVisitsCount,
        minorAccompanyNotPossibleFlg: data.monitorRuleList[i].minorAccompanyNotPossibleFlg,
        mondayVisitsFlg: data.monitorRuleList[i].mondayVisitsFlg,
        tuesdayVisitsFlg: data.monitorRuleList[i].tuesdayVisitsFlg,
        wednesdayVisitsFlg: data.monitorRuleList[i].wednesdayVisitsFlg,
        thursdayVisitsFlg: data.monitorRuleList[i].thursdayVisitsFlg,
        fridayVisitsFlg: data.monitorRuleList[i].fridayVisitsFlg,
        saturdayVisitsFlg: data.monitorRuleList[i].saturdayVisitsFlg,
        sundayVisitsFlg: data.monitorRuleList[i].sundayVisitsFlg,
        publicHolidayVisitsFlg: data.monitorRuleList[i].publicHolidayVisitsFlg,
        celebrationPreviousDayVisitsFlg: data.monitorRuleList[i].celebrationPreviousDayVisitsFlg,
        paymentTypeCashFlg: data.monitorRuleList[i].paymentTypeCashFlg,
        paymentTypeCreditCardFlg: data.monitorRuleList[i].paymentTypeCreditCardFlg,
        paymentTypeCouponOkFlg: data.monitorRuleList[i].paymentTypeCouponOkFlg,
        reserveType: data.monitorRuleList[i].reserveType,
        reservePhoneNumber: data.monitorRuleList[i].reservePhoneNumber,
        reserveURL: data.monitorRuleList[i].reserveURL,
        samedayReservationsFlg: data.monitorRuleList[i].samedayReservationsFlg,
        dedicatedReservationSiteFlg: data.monitorRuleList[i].dedicatedReservationSiteFlg,
        couponUseOkFlg: data.monitorRuleList[i].couponUseOkFlg,
        evidenceType: data.monitorRuleList[i].evidenceType,
        maleFlg: data.monitorRuleList[i].maleFlg,
        femaleFlg: data.monitorRuleList[i].femaleFlg,
        age10sFlg: data.monitorRuleList[i].age10sFlg,
        age20sFlg: data.monitorRuleList[i].age20sFlg,
        age30sFlg: data.monitorRuleList[i].age30sFlg,
        age40sFlg: data.monitorRuleList[i].age40sFlg,
        age50sFlg: data.monitorRuleList[i].age50sFlg,
        age60sFlg: data.monitorRuleList[i].age60sFlg,
        age70sFlg: data.monitorRuleList[i].age70sFlg,
        age80sFlg: data.monitorRuleList[i].age80sFlg,
        age90sFlg: data.monitorRuleList[i].age90sFlg,
        ageFrom: data.monitorRuleList[i].ageFrom,
        ageTo: data.monitorRuleList[i].ageTo,
        work: data.monitorRuleList[i].work,
        otherCondition: data.monitorRuleList[i].otherCondition,
        orderMethodURL: data.monitorRuleList[i].orderMethodURL,
        orderMethodPhoneNumber: data.monitorRuleList[i].orderMethodPhoneNumber,
        reservePhoneFlg: data.monitorRuleList[i].reservePhoneFlg,
        reserveWebFlg: data.monitorRuleList[i].reserveWebFlg,
        targetItemList,
        ngItemList: data.monitorRuleList[i].ngItemList,
        imageRuleList: targetImageRuleList,
        visitTimeSetList,
        orderMethodPhoneNumberFlg: data.monitorRuleList[i].orderMethodPhoneNumberFlg ?? false,
        orderMethodUrlFlg: data.monitorRuleList[i].orderMethodUrlFlg ?? false,
        disableFlg: data.monitorRuleList[i].disableFlg,
      });
    }

    const updParam: MonitorRuleSetUpdateFormResponse = {
      id: data.id !== 0 ? data.id : undefined,
      name: data.name,
      monitorRuleSetType: data.monitorRuleSetType,
      description: data.description,
      deleteMonitorRuleIdList: deleteMonitorRuleIds,
      monitorRuleList: dataList,
    };

    await monitorRuleApi.monitorRuleSetUpdate(updParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setData({ ...data, id: Number(res.data.id) });
      setUpdResult(res.data);
      history.push({ pathname: Url.KEISAI.MONITOR_RULE_SET });
    });
  };

  return (
    <>
      {isQuestionAssociationModalFlg && (
        <MonitorRuleQuestionAssociationModal
          data={data}
          setData={setData}
          selectedBeforeApplyContent={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleId === selectedMonitorRuleId;
            })?.beforeApplyContent
          }
          selectedAfterApplyContent={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleId === selectedMonitorRuleId;
            })?.afterApplyContent
          }
          selectedMonitorRuleId={selectedMonitorRuleId}
          selectedQuestionId={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleId === selectedMonitorRuleId;
            })?.questionId
          }
          isModal={isQuestionAssociationModalFlg}
          setIsModal={setIsQuestionAssociationModalFlg}
        />
      )}
      <Form>
        <Card className="position-sticky mb-4" style={{ top: '-1.5rem', zIndex: 2 }}>
          <Card.Body className="d-flex justify-content-between p-3">
            <div />

            <div className="d-flex justify-content-end">
              {/* <Button variant="link" className="text-secondary" onClick={() => {}}>
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth className="me-1" />
                削除
              </Button> */}
              <Button
                type="button"
                onClick={() => {
                  if (data.imageList && data.imageList.length > 0) {
                    Promise.all(
                      data.imageList.map(
                        (imageData): Promise<any> =>
                          new Promise((resolve, reject) => {
                            uploadImage(imageData)
                              .then((res) => resolve(res))
                              .catch((err) => reject(new Error(err)));
                          })
                      )
                    ).then((res) => {
                      const uploadImageInfoList: UploadImageFormObj[] = res.filter((obj) => obj.id);
                      onSubmit(uploadImageInfoList);
                    });
                  } else {
                    const uploadImageInfoList: UploadImageFormObj[] = [];
                    onSubmit(uploadImageInfoList);
                  }
                }}
                className="ms-2"
              >
                保存
              </Button>
            </div>
          </Card.Body>
        </Card>
        {updResult?.result && (
          <Alert variant="success" style={{ marginTop: 10 }}>
            更新しました。
          </Alert>
        )}
        {updResult !== undefined && updResult?.result === false && (
          <Alert
            variant="danger"
            style={{ marginTop: 10 }}
          >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
        )}
        {!ruleSetNameInputFlg && (
          <Alert variant="danger" style={{ marginTop: 10 }}>
            モニタールールセット名は入力必須項目です。
          </Alert>
        )}
        {validationErrorMsgs && (
          <Alert variant="danger" style={{ marginTop: 10 }}>
            {validationErrorMsgs.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </Alert>
        )}
        <Title className="mb-4">{TITLE.KEISAI.MONITOR_RULE_SET_MODIFY}</Title>
        <Card className="mb-4">
          <Card.Body>
            <h4 className="mb-4">モニタールールセット</h4>

            <FloatingLabel controlId="name" label="モニタールールセット名" className="mb-4">
              <FormControl
                type="text"
                placeholder="モニタールールセット名"
                defaultValue={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
              <Form.Control.Feedback type="invalid">モニタールールセット名は必須入力項目です。</Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel controlId="description" label="説明" className="mb-4">
              <FormControl
                type="text"
                placeholder="説明"
                defaultValue={data.description}
                onChange={(e) => {
                  setData({ ...data, description: e.target.value === '' ? undefined : e.target.value });
                }}
              />
            </FloatingLabel>

            <div className="d-flex gap-4 mb-4">
              <FloatingLabel controlId="industryId" label="モニタールールセット種別" className="w-100">
                <Form.Select
                  data-testid="monitorRuleSetSelect"
                  value={data.monitorRuleSetType}
                  onChange={(e) => {
                    setData({ ...data, monitorRuleSetType: Number(e.target.value) });
                    setMonitorRuleKindChangeFlg(true);
                  }}
                >
                  {sampleMonitorRuleSetKindData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </div>
            {/* <div>
                <span className="d-block mb-2">紐付けアンケート</span>
                <span className="bg-light p-2">テスト屋号のランチアンケート</span>
              </div> */}
          </Card.Body>
        </Card>

        <MonitorRuleCard
          data={data}
          setData={setData}
          setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
          setSelectedMonitorRuleId={setSelectedMonitorRuleId}
        />
      </Form>
      <div className="mb-4">
        <label className="mb-2">紐付いているモニター</label>
        {data.linkedMonitors.length > 0 && (
          <div className="d-flex pt-2 pb-2 ps-3 pe-3" style={{ width: '100%', height: '100%' }}>
            <div className="me-3" style={{ width: '7%' }}>
              実店舗ID
            </div>
            <div className="me-4" style={{ width: '20%' }}>
              実店舗名
            </div>
            <div className="me-3" style={{ width: '10%' }}>
              モニターベースID
            </div>
            <div className="me-3" style={{ width: '63%' }}>
              モニターベース名
            </div>
          </div>
        )}
        <ListGroup className="mb-3" style={{ maxHeight: '206px', overflowY: 'scroll' }}>
          {data.linkedMonitors.map((monitor) => {
            return (
              <ListGroup.Item key={`${monitor.monitorBaseId}-${monitor.shopId}`}>
                <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                  <div className="me-3" style={{ width: '7%' }}>
                    {monitor.shopId}
                  </div>
                  <div className="me-4" style={{ width: '20%' }}>
                    {monitor.shopName}
                  </div>
                  <div className="me-3" style={{ width: '10%' }}>
                    {monitor.monitorBaseId}
                  </div>
                  <div style={{ width: '63%' }}>{monitor.monitorBaseName}</div>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </>
  );
};
