// FloatingRewardTable.tsx
import React from 'react';
import { Table } from 'react-bootstrap';
import { FloatingRewardUploadRecordResponse } from '../../../api-client';

interface FloatingRewardTableProps {
  list: FloatingRewardUploadRecordResponse[];
}

const REWARD_TYPE = {
  FIXED: { CODE: 0, STR: '固定' },
  RATIO: { CODE: 1, STR: '割合' },
} as const;

const getRewardType = (code: number): string =>
  code === REWARD_TYPE.FIXED.CODE ? REWARD_TYPE.FIXED.STR : REWARD_TYPE.RATIO.STR;

export const FloatingRewardUploadConfirmTable: React.FC<FloatingRewardTableProps> = ({ list }) => {
  return (
    <div style={{ width: 'calc(100vw - 20rem)', overflowX: 'auto', overflowY: 'hidden' }} className="mb-4">
      <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
        <Table>
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <tr className="text-nowrap">
              <th
                style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '100px',
                }}
              >
                クライアントID
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '100px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '250px',
                  maxWidth: '250px',
                }}
              >
                クライアント名
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '350px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '100px',
                  maxWidth: '100px',
                }}
              >
                派遣マスト数
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '450px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '100px',
                }}
              >
                店舗ID
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '550px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '350px',
                  maxWidth: '350px',
                }}
              >
                店舗名
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '900px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '100px',
                }}
              >
                モニターベースID
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '1000px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '100px',
                }}
              >
                モニターID
              </th>
              <th
                style={{
                  position: 'sticky',
                  left: '1100px',
                  backgroundColor: 'white',
                  zIndex: 3,
                  minWidth: '250px',
                  maxWidth: '250px',
                }}
              >
                モニター名
              </th>
              <th>変動謝礼設定</th>
              <th>枠数</th>
              <th>残枠数</th>
              <th>マスト納品数(更新可)</th>
              <th>【元の謝礼】謝礼タイプ</th>
              <th>【元の謝礼】謝礼割合/固定料金</th>
              <th>【元の謝礼】謝礼上限</th>
              <th>【現在の変動謝礼】ID</th>
              <th>【現在の変動謝礼】謝礼タイプ</th>
              <th>【現在の変動謝礼】謝礼割合/固定料金</th>
              <th>【現在の変動謝礼】謝礼上限</th>
              <th>【現在の変動謝礼】枠数(変更可)</th>
              <th>【追加の変動謝礼】謝礼タイプ</th>
              <th>【追加の変動謝礼】謝礼割合/固定料金</th>
              <th>【追加の変動謝礼】謝礼上限</th>
              <th>【追加の変動謝礼】枠数</th>
              <th>エラーリスト</th>
            </tr>
          </thead>
          <tbody>
            {list.map((record, index) => (
              <tr key={index} className="text-nowrap align-middle">
                <td
                  style={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '100px',
                  }}
                >
                  {record.clientId}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '100px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '250px',
                    maxWidth: '250px',
                  }}
                >
                  {record.clientName}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '350px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '100px',
                    maxWidth: '100px',
                  }}
                >
                  {record.clientMustDeliveryNumber}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '450px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '100px',
                  }}
                >
                  {record.shopId}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '550px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '350px',
                    maxWidth: '350px',
                  }}
                >
                  {record.shopName}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '900px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '100px',
                  }}
                >
                  {record.monitorBaseId}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '1000px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '100px',
                  }}
                >
                  {record.monitorId}
                </td>
                <td
                  style={{
                    position: 'sticky',
                    left: '1100px',
                    backgroundColor: 'white',
                    zIndex: 1,
                    minWidth: '250px',
                    maxWidth: '250px',
                  }}
                >
                  {record.monitorName}
                </td>
                <td>{record.floatingRewardPreference}</td>
                <td>{record.number}</td>
                <td>{record.remainingNumber}</td>
                <td>{record.mustDeliveryNumber}</td>
                <td>{getRewardType(record.userRewardType)}</td>
                <td>{record.userRewardRate}</td>
                <td>{record.userRewardAmount}</td>
                <td>{record.floatingRewardId}</td>
                <td>{record.floatingRewardType != null && getRewardType(record.floatingRewardType)}</td>
                <td>{record.floatingRewardRate}</td>
                <td>{record.floatingRewardAmount}</td>
                <td>{record.floatingRewardNumber}</td>
                <td>{record.newFloatingRewardType != null && getRewardType(record.newFloatingRewardType)}</td>
                <td>{record.newFloatingRewardRate}</td>
                <td>{record.newFloatingRewardAmount}</td>
                <td>{record.newFloatingRewardNumber}</td>
                <td>
                  <ul>
                    {record.errorList.map((error, index2) => (
                      <li key={index2} style={{ color: 'red' }}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
