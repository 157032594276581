import { faArrowLeft, faArrowRight, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerTagListApi,
  F3DetailInfoApi,
  F3DetailInfoOutputResponse,
  F3DetailUpdateApi,
  F3DetailUpdateFormResponse,
  F3TestDeliveryApi,
  F3TestDeliveryFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  MailMagazinePrefectureFormResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { formatISODate } from '../../../utils/functions';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';

const streamingModeTypeList = [
  {
    id: 0,
    name: '本番配信',
  },
  {
    id: 1,
    name: '配信停止',
  },
];

const ageList = [...Array(100)].map((_: any, i) => {
  return {
    id: i + 1,
    name: `${i + 1}歳`,
  };
});

export const F3MailDetailModifyPage = () => {
  const location = useLocation<{ id: number }>();
  const history = useHistory();

  const infoApi = new F3DetailInfoApi();
  const updateApi = new F3DetailUpdateApi();
  const testApi = new F3TestDeliveryApi();
  const commonMasterListApi = new CommonMasterListApi();
  const customerTagListApi = new CustomerTagListApi();

  const [validated, setValidated] = useState(false);
  const [validErrMsg, setValidErrMsg] = useState<string | undefined>();

  // 配信対象ユーザー用ラジオフラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  // 配信対象ユーザー用checkboxフラグ
  const [applicationcheckBoxDisableFlg, setApplicationCheckBoxDisableFlg] = useState<boolean>(false);
  const [memberRegistrationCheckBoxDisableFlg, setmemberRegistrationCheckBoxDisableFlg] = useState<boolean>(false);

  const [data, setData] = useState<F3DetailInfoOutputResponse>({
    memo: '',
    bannerText: '',
    bannerHTML: '',
    sendScheduleTimeStamp: '',
    memberRegistrationDateStart: '',
    memberRegistrationDateEnd: '',
    applicationDateStart: '',
    applicationDateEnd: '',
    customerList: [],
    standardShopCount: 1,
    maxSendShopCount: 1,
    minMonitorLimit: 1,
    customerTagList: { customerTagList: [] },
    rangeFromShop1: 1,
    areaList1: [],
    rangeFromShop2: 0,
    areaList2: [],
    sendMode: 0,
    sendType: 0,
    testMailaddressList: [],
    maxSendBeautyCount: 0,
  });

  // 配信日時（日付）
  const [streamingDay, setStreamingDay] = useState<string>();
  // 配信日時（時間）
  const [streamingTime, setStreamingTime] = useState<string>();

  // 初期値を保存するstate
  const [initialStreamingDay, setInitialStreamingDay] = useState<string>();
  const [initialStreamingTime, setInitialStreamingTime] = useState<string>();

  // テスト配信
  const [testStreamingText, setTestStreamingText] = useState<string>();
  // ユーザーID
  const [userIdsText, setUserIdsText] = useState<string>('');
  // 都道府県一覧1
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();

  // 都道府県一覧2
  const [prefectures2, setPrefectures2] = useState<CommonMasterListOutputResponse[]>();

  // 会員属性一覧
  const [customerTagList, setCustomerTagList] = useState<MailMagazineCustomerTagOutputResponse>({
    customerTagList: [],
  });
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [testResult, setTestResult] = useState<IncResultOutputResponse>();

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };

  const diffInitial = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours());
    return parsedate;
  };

  useEffect(() => {
    // 詳細画面に直接アクセスされた場合はエラー画面に遷移させる。
    if (location.pathname === '/keisai/f3-mail-detail' && location.state === undefined) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (location.state !== undefined) {
      infoApi.f3DetailInfo(location.state.id).then((res: AxiosResponse<F3DetailInfoOutputResponse>) => {
        if (res.data.sendType === 0) {
          if (res.data.memberRegistrationDateStart != null || res.data.memberRegistrationDateEnd != null) {
            setmemberRegistrationCheckBoxDisableFlg(true);
          }
          if (res.data.applicationDateStart != null || res.data.applicationDateEnd != null) {
            setApplicationCheckBoxDisableFlg(true);
          }
        } else {
          setRadioDisableFlg(false);
        }

        if (res.data?.sendScheduleTimeStamp) {
          setStreamingDay(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'yyyy-MM-dd')
          );
          setStreamingTime(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'HH:mm:ss')
          );
        } else {
          const now = new Date();
          const nowDay = formatISODate(diffInitial(now).toISOString(), 'yyyy-MM-dd');
          const nowTime = formatISODate(diffInitial(now).toISOString(), 'HH:mm:ss');
          setStreamingDay(nowDay);
          setStreamingTime(nowTime);

          // 初期値の保存（バリデーションに使用する）
          setInitialStreamingDay(nowDay);
          setInitialStreamingTime(nowTime);
        }

        // テスト配信テキストの加工
        if (res.data.testMailaddressList) {
          for (let index = 0; index < res.data.testMailaddressList.length; index += 1) {
            if (res.data.testMailaddressList.length !== index + 1) {
              res.data.testMailaddressList[index] = `${res.data.testMailaddressList[index]}\n`;
            }
          }
          setTestStreamingText(res.data.testMailaddressList.join(''));
        }
        // ユーザーIDテキストの加工
        let userIds = '';
        if (res.data.customerList) {
          for (let index = 0; index < res.data.customerList.length; index += 1) {
            if (index !== 0) {
              userIds = `${userIds}${res.data.customerList[index]},`;
            } else {
              userIds = `${res.data.customerList[index]},`;
            }
          }
          setUserIdsText(userIds.substring(0, userIds.length - 1));
        }

        setData(res.data);

        // 共通APIで各種マスタ一覧を取得する。
        Promise.all(
          [{ key: 'prefecture' }].map(
            ({ key }): Promise<CommonMasterListOutputResponse[]> =>
              new Promise((resolve, reject) => {
                commonMasterListApi
                  .commonMasterList(key)
                  .then((res2: AxiosResponse<CommonMasterListOutputResponse[]>) => resolve(res2.data))
                  .catch((err: any) => reject(new Error(err)));
              })
          )
        ).then(([allPrefectures]) => {
          // 初期表示時にprefecturesSelectedListに含まれている都道府県をprefecturesから除外する
          // 都道府県１
          const filteredPrefectures = allPrefectures.filter(
            (prefecture) => !res.data.areaList1?.find((selected) => selected.prefectureId === prefecture.id)
          );
          setPrefectures(filteredPrefectures);

          // 都道府県２
          const filteredPrefectures2 = allPrefectures.filter(
            (prefecture) => !res.data.areaList2?.find((selected) => selected.prefectureId === prefecture.id)
          );
          setPrefectures2(filteredPrefectures2);
        });
      });
    } else {
      const now = new Date();
      const nowDay = formatISODate(diffInitial(now).toISOString(), 'yyyy-MM-dd');
      const nowTime = formatISODate(diffInitial(now).toISOString(), 'HH:mm:ss');
      setStreamingDay(nowDay);
      setStreamingTime(nowTime);

      // 初期値の保存（バリデーションに使用する）
      setInitialStreamingDay(nowDay);
      setInitialStreamingTime(nowTime);

      // 共通APIで各種マスタ一覧を取得する。
      Promise.all(
        [{ key: 'prefecture' }].map(
          ({ key }): Promise<CommonMasterListOutputResponse[]> =>
            new Promise((resolve, reject) => {
              commonMasterListApi
                .commonMasterList(key)
                .then((res2: AxiosResponse<CommonMasterListOutputResponse[]>) => resolve(res2.data))
                .catch((err: any) => reject(new Error(err)));
            })
        )
      ).then(([allPrefectures]) => {
        // 都道府県１
        setPrefectures(allPrefectures);
        // 都道府県２
        setPrefectures2(allPrefectures);
      });
    }

    customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
      const filteredCustomerTagList = res.data.customerTagList.filter(
        (output) => output.tagId !== 5 && output.tagId !== 8 && output.tagId !== 9
      );

      setCustomerTagList({
        customerTagList: filteredCustomerTagList,
      });
    });
  }, []);

  // エリアSelect Changeイベント
  const onChangeMultiSelect = (selectedContents: MultiValue<{ id: number; name: string }>, areaNumber: number) => {
    const copyAreaList: MailMagazinePrefectureFormResponse[] = [];

    for (let index = 0; index < selectedContents.length; index += 1) {
      copyAreaList.push({
        prefectureId: selectedContents[index].id,
        prefectureName: selectedContents[index].name,
        selectedFlg: true,
      });
    }

    if (areaNumber === 1) {
      setData({ ...data, areaList1: copyAreaList });
    } else {
      setData({ ...data, areaList2: copyAreaList });
    }
  };

  // 会員属性Select Changeイベント
  const onChangeCustomerSelect = (selectedContents: SingleValue<{ tagId: number; tagName: string }>, ind: number) => {
    if (selectedContents !== null) {
      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
      const targetSelectionList = customerTagList.customerTagList
        .find((r) => {
          return r.tagId === selectedContents.tagId;
        })
        ?.customerTagAnswerList.slice(0, 1);

      if (targetSelectionList) {
        setData({
          ...data,
          customerTagList: {
            customerTagList: addList.customerTagList.map((_, index) => {
              return index === ind
                ? {
                    ..._,
                    tagId: selectedContents.tagId,
                    tagName: selectedContents.tagName,
                    customerTagAnswerList: targetSelectionList,
                  }
                : { ..._ };
            }),
          },
        });
      }
    }
  };

  // 保存イベント
  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidErrMsg(undefined);

    setUpdResult(undefined);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    // 配信日時
    if (streamingDay == null || streamingDay === '') {
      event.stopPropagation();
      setValidErrMsg('配信日時を指定してください。');
      return;
    }
    if (initialStreamingDay != null) {
      // 配信日時のバリデーション
      if (streamingDay === initialStreamingDay && streamingTime === initialStreamingTime) {
        event.stopPropagation();
        setValidErrMsg('配信日時がデフォルト値のままです。変更してください。');
        return;
      }
    }

    // 配信対象ユーザ
    // 期間指定 or ユーザ指定 どちらかは必須。
    if (data.sendType === 0) {
      // 期間指定
      if (!memberRegistrationCheckBoxDisableFlg && !applicationcheckBoxDisableFlg) {
        event.stopPropagation();
        setValidErrMsg('期間指定の場合、会員登録期間か応募案件期間のどちらかを指定してください。');
        return;
      }

      if (memberRegistrationCheckBoxDisableFlg) {
        if (
          (data.memberRegistrationDateStart == null || data.memberRegistrationDateStart === '') &&
          (data.memberRegistrationDateEnd == null || data.memberRegistrationDateEnd === '')
        ) {
          event.stopPropagation();
          setValidErrMsg('会員登録期間を指定する場合、年月日From-Toを指定してください。※どちらか１つでも良いです。');
          return;
        }
      } else {
        // 入力された値をクリアする。
        setData({ ...data, memberRegistrationDateStart: undefined, memberRegistrationDateEnd: undefined });
      }

      if (applicationcheckBoxDisableFlg) {
        if (
          (data.applicationDateStart == null || data.applicationDateStart === '') &&
          (data.applicationDateEnd == null || data.applicationDateEnd === '')
        ) {
          event.stopPropagation();
          setValidErrMsg('応募案件期間を指定する場合、年月日From-Toを指定してください。※どちらか１つでも良いです。');
          return;
        }
      } else {
        // 入力された値をクリアする。
        setData({ ...data, applicationDateStart: undefined, applicationDateEnd: undefined });
      }
    } else if (userIdsText === '') {
      // ユーザID指定
      event.stopPropagation();
      setValidErrMsg('ユーザID指定の場合、ユーザIDを指定してください。');
      return;
    }

    // 都道府県1
    if (data.areaList1.length === 0) {
      setValidErrMsg('都道府県1を指定してください。');
      return;
    }

    let sendScheduleTimeStamp = '';

    if (streamingDay !== undefined && streamingDay !== '' && streamingTime !== undefined && streamingTime !== '') {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} ${streamingTime}`))
        .toISOString()
        .slice(0, -5);
    } else if (
      streamingDay !== undefined &&
      streamingDay !== '' &&
      (streamingTime === undefined || streamingTime === '')
    ) {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} 00:00:00`))
        .toISOString()
        .slice(0, -5);
    }

    const param: F3DetailUpdateFormResponse = {
      id: location.state === undefined ? undefined : location.state.id,
      memo: data.memo === undefined ? '' : data.memo,
      title: data.title,
      bannerText: data.bannerText,
      bannerHTML: data.bannerHTML,
      sendScheduleTimeStamp: sendScheduleTimeStamp === '' ? undefined : sendScheduleTimeStamp,
      sendType: data.sendType,
      memberRegistrationDateStart:
        data.memberRegistrationDateStart === '' ? undefined : data.memberRegistrationDateStart,
      memberRegistrationDateEnd: data.memberRegistrationDateEnd === '' ? undefined : data.memberRegistrationDateEnd,
      applicationDateStart: data.applicationDateStart === '' ? undefined : data.applicationDateStart,
      applicationDateEnd: data.applicationDateEnd === '' ? undefined : data.applicationDateEnd,
      standardShopCount: data.standardShopCount,
      maxSendShopCount: data.maxSendShopCount,
      minMonitorLimit: data.minMonitorLimit,
      areaList1: data.areaList1,
      rangeFromShop1: data.rangeFromShop1,
      areaList2: data.areaList2,
      rangeFromShop2: data.rangeFromShop2,
      customerTagList: data.customerTagList.customerTagList,
      sendMode: data.sendMode === undefined ? 0 : data.sendMode,
      testMailaddressList: testStreamingText?.split('\n'),
      userIdList: userIdsText === '' ? undefined : userIdsText,
      maxSendBeautyCount: data.maxSendBeautyCount,
    };

    updateApi.f3DetailUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  // テスト配信イベント
  const testStreaming = () => {
    const param: F3TestDeliveryFormResponse = {
      bannerText: data.bannerText === undefined ? '' : data.bannerText,
      bannerHTML: data.bannerHTML === undefined ? '' : data.bannerHTML,
      title: data.title === undefined ? '' : data.title,
      testMailaddressList: testStreamingText !== undefined ? testStreamingText.split('\n') : [],
    };

    testApi.f3TestDelivery(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setTestResult(res.data);
    });
  };

  const handleMovePref = (moveRight: boolean, isPref1: boolean) => {
    const radix = 10;

    const prefDocumentObjName = isPref1 ? 'prefectures' : 'prefectures2';
    const selectedPrefObjName = isPref1 ? 'selectedPrefectures' : 'selectedPrefectures2';
    const targetPrefectures = isPref1 ? prefectures : prefectures2;

    if (moveRight) {
      const selectedOptions = document.getElementById(prefDocumentObjName)?.querySelectorAll('option:checked');

      if (selectedOptions) {
        const selectedPrefectures: MailMagazinePrefectureFormResponse[] = Array.from(selectedOptions).map((option) => ({
          prefectureId: parseInt(option.getAttribute('value') || '0', radix),
          prefectureName: option.textContent || '',
          selectedFlg: true,
        }));

        // 元の選択された都道府県リストに新しく選択された都道府県を追加
        const updatedPrefecturesSelectedList = [
          ...(isPref1 ? data.areaList1 : data.areaList2 || []),
          ...selectedPrefectures,
        ];

        // 選択された都道府県リストをIDでソートする
        updatedPrefecturesSelectedList.sort((a, b) => a.prefectureId - b.prefectureId);

        const selectedPrefectureIds = selectedPrefectures.map((prefecture) => prefecture.prefectureId);
        const updatedPrefectures = targetPrefectures?.filter((pref) => !selectedPrefectureIds.includes(pref.id));

        // データの更新
        if (isPref1) {
          setData({ ...data, areaList1: updatedPrefecturesSelectedList });
          setPrefectures(updatedPrefectures);
        } else {
          setData({ ...data, areaList2: updatedPrefecturesSelectedList });
          setPrefectures2(updatedPrefectures);
        }
      }
    } else {
      // 左への移動処理
      // 選択されたアイテムで選択された都道府県をprefecturesSelectedListから削除し、prefecturesに追加する
      const selectedOptions = document.getElementById(selectedPrefObjName)?.querySelectorAll('option:checked');

      if (selectedOptions) {
        const selectedPrefectures: CommonMasterListOutputResponse[] = Array.from(selectedOptions).map((option) => ({
          id: parseInt(option.getAttribute('value') || '0', radix),
          name: option.textContent || '',
        }));

        // 選択された都道府県を「選択されたアイテム」から削除し、「こちらからアイテムを選択」に追加
        const updatedPrefecturesSelectedList = (isPref1 ? data.areaList1 : data.areaList2)?.filter(
          (prefecture) => !selectedPrefectures.find((selectedPref) => selectedPref.id === prefecture.prefectureId)
        );

        const updatedPrefectures = [...(targetPrefectures || []), ...selectedPrefectures];
        // 都道府県リストをIDでソートする
        updatedPrefectures?.sort((a, b) => a.id - b.id);

        // データの更新
        if (isPref1) {
          setData({ ...data, areaList1: updatedPrefecturesSelectedList });
          setPrefectures(updatedPrefectures);
        } else {
          setData({ ...data, areaList2: updatedPrefecturesSelectedList });
          setPrefectures2(updatedPrefectures);
        }
      }
    }
  };

  const handleDelete = (index: number) => {
    // 指定したインデックス以外のアイテムを残して新しいリストを作成
    const newItems = data.customerTagList.customerTagList.filter((_, i) => i !== index);
    setData((prevData) => ({
      ...prevData,
      customerTagList: { customerTagList: newItems },
    }));
  };

  return (
    <>
      <Form validated={validated} onSubmit={submit}>
        <Title className="mb-4">{location.state === undefined ? 'F3メール編集画面' : 'F3メール詳細画面'}</Title>
        <div className="d-flex justify-content-end mb-4">
          <Button type="button" onClick={testStreaming} className="ms-2">
            テスト配信
          </Button>
          <Button type="submit" className="ms-2">
            保存
          </Button>
        </div>
        {validErrMsg && (
          <Alert variant="warning" style={{ marginTop: 10 }}>
            {validErrMsg}
          </Alert>
        )}
        {updResult?.result && (
          <Alert variant="success" style={{ marginTop: 10 }}>
            更新しました。
          </Alert>
        )}
        {testResult?.result && (
          <Alert variant="success" style={{ marginTop: 10 }}>
            テスト配信が完了しました。
          </Alert>
        )}
        {updResult !== undefined && updResult?.result === false && (
          <Alert
            variant="danger"
            style={{ marginTop: 10 }}
          >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
        )}
        {testResult !== undefined && testResult?.result === false && (
          <Alert
            variant="danger"
            style={{ marginTop: 10 }}
          >{`${testResult.errorMessage} (エラーコード：${testResult.errorCode})`}</Alert>
        )}
        <Form.Group className="mb-4">
          <Form.Label>メモ</Form.Label>
          <Form.Control
            as="textarea"
            value={data.memo}
            onChange={(e) => {
              setData({ ...data, memo: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>メール件名</Form.Label>
          <Form.Control
            value={data.title}
            required
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>お知らせバナーテキスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.bannerText}
            onChange={(e) => {
              setData({ ...data, bannerText: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>お知らせバナーHTML</Form.Label>
          <Form.Control
            as="textarea"
            value={data.bannerHTML}
            onChange={(e) => {
              setData({ ...data, bannerHTML: e.target.value === '' ? undefined : e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
        <Form.Label>配信日時</Form.Label>
        <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
          <Datepicker
            value={streamingDay === undefined ? '' : streamingDay}
            className="me-2"
            onChange={(e) => {
              setStreamingDay(e.target.value);
            }}
            data-testid="datepicker"
          />
          <Form.Control
            type="time"
            step="1"
            value={streamingTime === undefined ? '' : streamingTime}
            className="me-2"
            onChange={(e) => {
              setStreamingTime(e.target.value);
            }}
            disabled={streamingDay === undefined || streamingDay === ''}
          />
        </Form.Group>
        <Form.Check.Label>配信対象ユーザー</Form.Check.Label>
        <Form.Check id="radio1" className="d-flex ms-4 mt-2">
          <Form.Check.Input
            data-testid="customerRegisterRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup01"
            value={0}
            onChange={(e) => {
              setData({ ...data, sendType: Number(e.target.value) });
              setRadioDisableFlg(true);
            }}
            checked={data.sendType === 0}
          />
          <Form.Check.Label>期間指定</Form.Check.Label>
        </Form.Check>
        <Form.Check.Label className="mt-2" style={{ marginLeft: '6rem' }}>
          会員登録期間
        </Form.Check.Label>
        <div className="d-flex align-items-center " style={{ width: '49.5%' }}>
          <Col className="col-12">
            <Form.Group className="d-flex align-items-center ms-5 mt-2" controlId="readOnly1">
              <Col>
                <Form.Check.Input
                  data-testid="couponUseOkCheckBox"
                  className="flex-shrink-0 ms-5"
                  type="checkbox"
                  name="checkBoxGroup01"
                  disabled={!radioDisableFlg}
                  checked={memberRegistrationCheckBoxDisableFlg}
                  onChange={(e) => {
                    setmemberRegistrationCheckBoxDisableFlg(!memberRegistrationCheckBoxDisableFlg);
                  }}
                />
              </Col>
              <Col className="col-5">
                <Form.Control
                  data-testid="enteringTimeText"
                  disabled={!(radioDisableFlg && memberRegistrationCheckBoxDisableFlg)}
                  type="date"
                  value={data.memberRegistrationDateStart === undefined ? '' : data.memberRegistrationDateStart}
                  onChange={(e) => {
                    setData({ ...data, memberRegistrationDateStart: e.target.value });
                  }}
                />
              </Col>
              <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
              <Col className="col-5">
                <Form.Control
                  data-testid="exitTimeText"
                  disabled={!(radioDisableFlg && memberRegistrationCheckBoxDisableFlg)}
                  type="date"
                  value={data.memberRegistrationDateEnd === undefined ? '' : data.memberRegistrationDateEnd}
                  onChange={(e) => {
                    setData({ ...data, memberRegistrationDateEnd: e.target.value });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </div>
        <Form.Check.Label className="mt-2" style={{ marginLeft: '6rem' }}>
          応募案件期間
        </Form.Check.Label>
        <div className="d-flex align-items-center " style={{ width: '49.5%' }}>
          <Col className="col-12">
            <Form.Group className="d-flex align-items-center ms-5 mt-2" controlId="readOnly1">
              <Col>
                <Form.Check.Input
                  data-testid="couponUseOkCheckBox"
                  className="flex-shrink-0 ms-5"
                  type="checkbox"
                  name="checkBoxGroup01"
                  checked={applicationcheckBoxDisableFlg}
                  disabled={!radioDisableFlg}
                  onChange={(e) => {
                    setApplicationCheckBoxDisableFlg(!applicationcheckBoxDisableFlg);
                  }}
                />
              </Col>
              <Col className="col-5">
                <Form.Control
                  data-testid="enteringTimeText"
                  disabled={!(radioDisableFlg && applicationcheckBoxDisableFlg)}
                  type="date"
                  value={data.applicationDateStart === undefined ? '' : data.applicationDateStart}
                  onChange={(e) => {
                    setData({ ...data, applicationDateStart: e.target.value });
                  }}
                />
              </Col>
              <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
              <Col className="col-5">
                <Form.Control
                  data-testid="exitTimeText"
                  disabled={!(radioDisableFlg && applicationcheckBoxDisableFlg)}
                  type="date"
                  value={data.applicationDateEnd === undefined ? '' : data.applicationDateEnd}
                  onChange={(e) => {
                    setData({ ...data, applicationDateEnd: e.target.value });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </div>
        <div className="align-items-center " style={{ width: '49.5%' }}>
          <Form.Check id="radio2" className="d-flex ms-4 mt-2 mb-2">
            <Form.Check.Input
              data-testid="specifyTimeRadio"
              className="flex-shrink-0 me-2"
              type="radio"
              name="radioGroup01"
              value={1}
              onChange={(e) => {
                setData({ ...data, sendType: Number(e.target.value) });
                setRadioDisableFlg(false);
              }}
              checked={data.sendType === 1}
            />
            <Form.Check.Label>
              {' '}
              ユーザーID指定&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              ※（複数の場合は半角カンマ（,）で入力してください。例： 12,20）
            </Form.Check.Label>
          </Form.Check>
          <div className="ms-5">
            <Form.Control
              disabled={radioDisableFlg}
              value={userIdsText}
              onChange={(e) => {
                setUserIdsText(e.target.value);
              }}
            />
          </div>
          <Form.Group className="mb-4 mt-4">
            <Form.Label>基準店舗件数</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={data.standardShopCount}
              onChange={(e) => {
                setData({ ...data, standardShopCount: Number(e.target.value) });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>最大配信店舗数</Form.Label>
            <Form.Control
              type="number"
              min="4"
              value={data.maxSendShopCount}
              onChange={(e) => {
                setData({ ...data, maxSendShopCount: Number(e.target.value) });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>最大配信店舗数の内、美容の配信店舗数</Form.Label>
            <Form.Control
              type="number"
              min="0"
              value={data.maxSendBeautyCount}
              onChange={(e) => {
                setData({ ...data, maxSendBeautyCount: Number(e.target.value) });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>配信対象店舗の空き枠数</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={data.minMonitorLimit}
              onChange={(e) => {
                setData({ ...data, minMonitorLimit: Number(e.target.value) });
              }}
            />
          </Form.Group>
          <Row className="mb-4">
            <Form.Label>都道府県1</Form.Label>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="prefectures">
                <Form.Label>こちらからアイテムを選択</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {prefectures?.map((prefecture) => (
                    <option key={prefecture.id} value={prefecture.id.toString()}>
                      {prefecture.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              {' '}
              {/* 左右の列の中央揃え */}
              <div className="d-grid">
                <Button variant="light" onClick={() => handleMovePref(true, true)}>
                  <FontAwesomeIcon icon={faArrowRight} /> {/* 右向き矢印 */}
                </Button>
                <Button variant="light" onClick={() => handleMovePref(false, true)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {/* 左向き矢印 */}
                </Button>
              </div>
            </Col>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="selectedPrefectures">
                <Form.Label className="fw-bold">選択されたアイテム</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {data.areaList1
                    ?.sort((a, b) => a.prefectureId - b.prefectureId)
                    .map((prefecture) => (
                      <option key={prefecture.prefectureId} value={prefecture.prefectureId.toString()}>
                        {prefecture.prefectureName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="ms-4 mt-4">
            <Form.Label>対象店舗の範囲1</Form.Label>
            <Form.Group className="d-flex align-items-center" controlId="readOnly1">
              応募店舗からの抽出対象範囲 半径
              <Col className="col-7.5">
                <Form.Control
                  type="number"
                  min="1"
                  value={data.rangeFromShop1}
                  onChange={(e) => {
                    setData({ ...data, rangeFromShop1: Number(e.target.value) });
                  }}
                />
              </Col>
              km
            </Form.Group>
          </Form.Group>
          <Row className="mb-4 mt-4">
            <Form.Label>都道府県2</Form.Label>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="prefectures2">
                <Form.Label>こちらからアイテムを選択</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {prefectures2?.map((prefecture) => (
                    <option key={prefecture.id} value={prefecture.id.toString()}>
                      {prefecture.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              {' '}
              {/* 左右の列の中央揃え */}
              <div className="d-grid">
                <Button variant="light" onClick={() => handleMovePref(true, false)}>
                  <FontAwesomeIcon icon={faArrowRight} /> {/* 右向き矢印 */}
                </Button>
                <Button variant="light" onClick={() => handleMovePref(false, false)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {/* 左向き矢印 */}
                </Button>
              </div>
            </Col>
            <Col style={{ height: '400px' }}>
              <Form.Group className="mb-4" controlId="selectedPrefectures2">
                <Form.Label className="fw-bold">選択されたアイテム</Form.Label>
                <Form.Control as="select" multiple style={{ height: '360px' }}>
                  {data.areaList2
                    ?.sort((a, b) => a.prefectureId - b.prefectureId)
                    .map((prefecture) => (
                      <option key={prefecture.prefectureId} value={prefecture.prefectureId.toString()}>
                        {prefecture.prefectureName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="ms-4 mt-4 mb-4">
            <Form.Label>対象店舗の範囲2</Form.Label>
            <Form.Group className="d-flex align-items-center" controlId="readOnly1">
              応募店舗からの抽出対象範囲 半径
              <Col className="col-7.5">
                <Form.Control
                  type="number"
                  min="0"
                  value={data.rangeFromShop2}
                  onChange={(e) => {
                    setData({ ...data, rangeFromShop2: Number(e.target.value) });
                  }}
                />
              </Col>
              km
            </Form.Group>
          </Form.Group>
          <Form.Label>会員属性</Form.Label>
          {data.customerTagList.customerTagList?.map((_, i) => {
            return (
              <Col key={i.toString()} className="col-12 mt-2">
                <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                  <Form.Label className="mt-2 me-2 ms-4">属性</Form.Label>
                  <Col data-testid="attributeSelect">
                    <Select
                      id="prefecture"
                      onChange={(e) => {
                        onChangeCustomerSelect(e, i);
                      }}
                      closeMenuOnSelect
                      value={data.customerTagList.customerTagList[i]}
                      getOptionLabel={(option) => {
                        return option.tagName;
                      }}
                      getOptionValue={(option) => {
                        return option.tagId.toString();
                      }}
                      options={customerTagList.customerTagList}
                    />
                  </Col>
                  <Form.Label className="mt-2 me-2 ms-2">選択肢</Form.Label>
                  <Col>
                    <Form.Select
                      data-testid="ChoiceSelect"
                      value={data.customerTagList.customerTagList[i].customerTagAnswerList[0].id}
                      onChange={(e) => {
                        const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                        const targetTagName = customerTagList.customerTagList
                          .find((row) => {
                            return row.tagId === Number(data.customerTagList.customerTagList[i].tagId);
                          })
                          ?.customerTagAnswerList.find((r) => {
                            return r.id === Number(e.target.value);
                          })?.tagValue;

                        if (targetTagName) {
                          setData({
                            ...data,
                            customerTagList: {
                              customerTagList: addList.customerTagList.map((row, index) => {
                                return index === i
                                  ? {
                                      ...row,
                                      customerTagAnswerList: [{ id: Number(e.target.value), tagValue: targetTagName }],
                                    }
                                  : { ...row };
                              }),
                            },
                          });
                        }
                      }}
                    >
                      {customerTagList.customerTagList
                        .find((row) => {
                          return row.tagId === _.tagId;
                        })
                        ?.customerTagAnswerList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.tagValue}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Button variant="link" className="text-secondary" onClick={() => handleDelete(i)}>
                      <FontAwesomeIcon data-testid="deleteButton" className="text-secondary" icon={faTimesCircle} />
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            );
          })}
          {customerTagList.customerTagList.length > 0 && (
            <div className="d-flex justify-content-end mb-4 mt-4">
              <Button
                data-testid="add"
                variant="link"
                className="text-secondary p-0"
                onClick={() => {
                  const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                  addList.customerTagList.push({
                    tagId: customerTagList.customerTagList[0].tagId,
                    tagName: customerTagList.customerTagList[0].tagName,
                    customerTagAnswerList: customerTagList.customerTagList[0].customerTagAnswerList,
                  });

                  setData({ ...data, customerTagList: addList });
                }}
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                <span style={{ fontSize: '1rem' }}>追加</span>
              </Button>
            </div>
          )}
          <Form.Group className="mb-4">
            <Form.Label>配信モード</Form.Label>
            <Form.Select
              value={data.sendMode}
              onChange={(e) => {
                setData({ ...data, sendMode: Number(e.target.value) });
              }}
            >
              {streamingModeTypeList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <Form.Group className="mb-4">
          <Form.Label>テスト配信先</Form.Label>
          <Form.Control
            value={testStreamingText}
            onChange={(e) => {
              setTestStreamingText(e.target.value);
            }}
            as="textarea"
            rows={3}
          />
        </Form.Group>
      </Form>
    </>
  );
};
