import { RegisterOptions } from 'react-hook-form';
import { parse, isBefore } from 'date-fns';
import { LOCALDATE_HYPHEN_FORMAT, LOCALDATE_HYPHEN_SHORT_FORMAT } from '../Constants';

export type OptionsType = {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  isBeforeNow?: boolean;
};
export default function useGetValidationRule() {
  const getRules = (options: OptionsType = {}, type?: 'number' | 'email' | 'month' | 'date') => {
    let rules: RegisterOptions = {};
    if (type === 'number') {
      // rules = { ...rules, valueAsNumber: true };
      rules = {
        // ...rules,
        pattern: {
          value: /^\d+$/,
          message: '数値のみ入力してください。',
        },
      };
    }
    if (type === 'email') {
      rules = {
        // ...rules,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: 'emailの形式が正しくありません。',
        },
      };
    }
    if (options.required) {
      rules = {
        ...rules,
        required: '入力必須項目です。',
      };
    }
    if (options.min !== undefined && type === 'number') {
      rules = {
        ...rules,
        min: {
          value: options.min,
          message: `${options.min}以上の値を入力してください。`,
        },
      };
    }
    if (options?.max !== undefined && type === 'number') {
      rules = {
        ...rules,
        max: {
          value: options.max,
          message: `${options.max}以下の値を入力してください。`,
        },
      };
    }
    if (options.minLength !== undefined) {
      rules = {
        ...rules,
        minLength: {
          value: options.minLength,
          message: `${options.minLength}以上の文字を入力してください。`,
        },
      };
    }
    if (options.maxLength !== undefined) {
      rules = {
        ...rules,
        maxLength: {
          value: options.maxLength,
          message: `${options.maxLength}以下の文字を入力してください。`,
        },
      };
    }
    if (type === 'date' && options.isBeforeNow) {
      rules = {
        ...rules,
        validate: (value) => {
          if (value === '') return true;
          const inputDay = parse(value, LOCALDATE_HYPHEN_FORMAT, new Date());
          const now = new Date();
          if (isBefore(inputDay, now.setHours(0, 0, 0, 0))) {
            return '過去日が入力されています。翌日以降の日付を入れてください。';
          }
          return true;
        },
      };
    }
    if (type === 'month' && options.isBeforeNow) {
      rules = {
        ...rules,
        validate: (value) => {
          const inputDay = parse(value, LOCALDATE_HYPHEN_SHORT_FORMAT, new Date());
          const now = new Date();
          if (isBefore(inputDay, new Date(now.getFullYear(), now.getMonth(), 0, 0, 0, 0))) {
            return '過去月が入力されています。翌月以降の日付を入れてください。';
          }
          return true;
        },
      };
    }
    return rules;
  };

  return { getRules };
}
