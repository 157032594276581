import React, { useState, useEffect } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  CustomerInfoApi,
  CustomerInformationDetailDisplayOutputResponse,
  CustomerTicketApi,
  CustomerTicketHistoryDetailOutputResponse,
  CustomerTicketHistoryOutputResponse,
  IncResultOutputResponse,
} from '../../../../api-client';
import { BillingCommonInputTextWithLabel } from '../../../molecules/BillingCommonInputTextWithLabel';
import { BillingUpdateResultModal } from '../../../organisms/Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../../BillingConstants';
import { BillingCommonInputSelect } from '../../../molecules/BillingCommonInputSelect';
import { BillingCommonInputText } from '../../../molecules/BillingCommonInputText';
import { PaginationWithEllipsisBilling } from '../../../molecules/PaginationWithEllipsisBilling';

interface SearchConditions {
  customerTicketId?: number;
  applyId?: number;
  dateSearchType?: 1 | 2;
  startAt?: string;
  endAt?: string;
}

const DATE_SEARCH_TYPE_OPTIONS = [
  { id: 1, name: '発行日' },
  { id: 2, name: '利用日' },
];

export const CustomerTicketHistorySearchForm: React.VFC = () => {
  const [data, setData] = useState<CustomerTicketHistoryOutputResponse>();
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const customerTicketApi = new CustomerTicketApi();
  const customerApi = new CustomerInfoApi();
  const { handleSubmit, formState, getValues } = useFormContext<SearchConditions>();
  const [customerInfoDetail, setCustomerInfoDetail] = useState<CustomerInformationDetailDisplayOutputResponse>();
  const { id: customerId } = useParams<{ id: string | undefined }>();
  const MAX_RECORD_PER_PAGE = 20;
  const fetchData = (searchConditions: SearchConditions, pageNum = 1) => {
    customerTicketApi
      .customerTicketHistory(
        customerId === undefined ? 0 : Number(customerId),
        pageNum,
        MAX_RECORD_PER_PAGE,
        searchConditions.customerTicketId,
        searchConditions.applyId,
        searchConditions.startAt,
        searchConditions.endAt,
        searchConditions.dateSearchType
      )
      .then((res) => {
        setData(res.data);
      });
  };

  const fetchTicketCount = () => {
    customerApi.customerInformationDetailDisplay(Number(customerId)).then((res) => {
      setCustomerInfoDetail(res.data);
    });
  };

  const onClickPageNation = (clickPage: number) => {
    fetchData(
      {
        customerTicketId: getValues('customerTicketId'),
        applyId: getValues('applyId'),
        dateSearchType: getValues('dateSearchType'),
        startAt: getValues('startAt'),
        endAt: getValues('endAt'),
      },
      clickPage
    );
  };

  useEffect(() => {
    fetchData({});
    fetchTicketCount();
  }, []);

  const onSubmit: SubmitHandler<SearchConditions> = (searchConditions) => {
    fetchData(searchConditions);
  };

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>無料チケット：{customerInfoDetail?.freeCustomerTicketCount}枚</div>
          <div>有料チケット：{customerInfoDetail?.paidCustomerTicketCount}枚</div>
        </div>
        <Row className="m-2 h2">チケット履歴</Row>
        <Row>
          <BillingCommonInputTextWithLabel<SearchConditions>
            registerName="customerTicketId"
            labelName="チケットID"
            type="number"
          />
          <BillingCommonInputTextWithLabel<SearchConditions> registerName="applyId" labelName="応募ID" type="number" />
        </Row>
        <Row style={{ marginTop: '0.5rem' }}>
          <Col className="col-2">
            <BillingCommonInputSelect<SearchConditions>
              registerName="dateSearchType"
              options={DATE_SEARCH_TYPE_OPTIONS}
            />
          </Col>
          <Col className="col-3">
            <BillingCommonInputText<SearchConditions> registerName="startAt" type="date" />
          </Col>
          <Col className="col-1" style={{ marginTop: '0.5rem' }}>
            ～
          </Col>
          <Col className="col-3">
            <BillingCommonInputText<SearchConditions> registerName="endAt" type="date" />
          </Col>
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button variant="secondary" style={{ margin: '20px' }} type="submit">
            絞り込み
          </Button>
        </div>
      </Form>
      <div>
        <Table>
          <thead>
            <tr>
              <th>レコードNo</th>
              <th>チケットID</th>
              <th>発行日</th>
              <th>
                取引タイプ
                <br />
                (付与/購入/使用/返却)
              </th>
              <th>
                サービス種別
                <br />
                (キャン待ち/期限延長)
              </th>
              <th>
                チケット区分
                <br />
                (無料/有料)
              </th>
              <th>有効期限</th>
              <th>利用日時</th>
              <th>応募ID</th>
              <th>実店舗ID</th>
              <th>実店舗名</th>
              <th>
                返還種別
                <br />
                (社内orToC)
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.list.map((item: CustomerTicketHistoryDetailOutputResponse, idx: number) => {
              return (
                <tr key={idx}>
                  <td>{item.customerTicketHistoryId}</td>
                  <td>{item.customerTicketId}</td>
                  <td>{item.createAt}</td>
                  <td>{item.customerTicketHistoryReasonType}</td>
                  <td>{item.customerTicketHistoryReason}</td>
                  <td>{item.customerTicketType}</td>
                  <td>{item.expiredAt}</td>
                  <td>{item.useAt}</td>
                  <td style={{ whiteSpace: 'pre-wrap' }}>
                    {item.applyId}
                    {typeof item.applyStatusName === 'string' && `\n${item.applyStatusName}`}
                  </td>
                  <td>{item.shopId}</td>
                  <td>{item.shopName}</td>
                  <td>{item.ticketReturnType}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {formState.isSubmitted && data?.totalCount === 0 && <p>データはありませんでした。</p>}
        {data?.totalCount !== undefined && (
          <div className="d-flex justify-content-center">
            <PaginationWithEllipsisBilling
              maxRecordPerPage={MAX_RECORD_PER_PAGE}
              totalDataNum={data.totalCount}
              handleClick={(e) => onClickPageNation(e.page)}
            />
          </div>
        )}
      </div>
    </>
  );
};
