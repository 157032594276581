import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「人数・同伴者」Contents
export const MonitorRuleSetNumberPeopleContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 最小人数・最大人数テキスト有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(false);

  useEffect(() => {
    const flg =
      !data?.monitorRuleList?.find((r) => {
        return r.monitorRuleType === 4;
      })?.minimumVisitsCount &&
      !data?.monitorRuleList?.find((r) => {
        return r.monitorRuleType === 4;
      })?.maximumVisitsCount;
    setRadioDisableFlg(flg);
  }, [data]);

  return (
    <>
      <div className="d-flex mb-4 ">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          人数指定
        </Form.Label>
      </div>
      <Form.Check id="radio1" className="d-flex">
        <Form.Check.Input
          data-testid="unspecifiedNumberPopleRadio"
          className="flex-shrink-0 me-2"
          type="radio"
          name="radioGroup02"
          onChange={() => {
            setRadioDisableFlg(true);
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((_, i) => {
                return i === index
                  ? {
                      ..._,
                      maximumVisitsCount: undefined,
                      minimumVisitsCount: undefined,
                    }
                  : { ..._ };
              }),
            });
          }}
          checked={radioDisableFlg}
        />
        <Form.Check.Label>指定なし</Form.Check.Label>
      </Form.Check>
      <Form.Group>
        <Form.Check id="radio2" className="d-flex">
          <Form.Check.Input
            data-testid="specifiedNumberPopleRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup02"
            onChange={() => {
              setRadioDisableFlg(false);
            }}
            checked={!radioDisableFlg}
          />
          <Form.Check.Label>人数範囲を指定</Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <Col className="col-5">
        <Form.Group className="d-flex align-items-center ms-5 mt-3">
          <Form.Label className="mt-2 me-2">最小人数</Form.Label>
          <Col>
            <Form.Control
              data-testid="minimumPeopleText"
              value={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 4;
                })?.minimumVisitsCount !== undefined
                  ? data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 4;
                    })?.minimumVisitsCount
                  : ''
              }
              disabled={radioDisableFlg}
              type="number"
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? {
                          ..._,
                          minimumVisitsCount: Number(e.target.value) >= 1 ? Number(e.target.value) : undefined,
                        }
                      : { ..._ };
                  }),
                });
              }}
            />
          </Col>
          <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
          <Form.Label className="mt-2 me-2">最大人数</Form.Label>
          <Col>
            <Form.Control
              data-testid="maxPeopleText"
              value={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 4;
                })?.maximumVisitsCount !== undefined
                  ? data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 4;
                    })?.maximumVisitsCount
                  : ''
              }
              disabled={radioDisableFlg}
              type="number"
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? {
                          ..._,
                          maximumVisitsCount: Number(e.target.value) >= 1 ? Number(e.target.value) : undefined,
                        }
                      : { ..._ };
                  }),
                });
              }}
            />
          </Col>
        </Form.Group>
      </Col>
      <Form.Group className="mt-4 mb-4">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          未成年同伴可否
        </Form.Label>
        <Form.Group className="d-flex">
          <Form.Check className="pt-2" id="checkBoxNotPossible">
            <Form.Check.Input
              data-testid="minorAccompanyNotPossibleCheckBox"
              className="flex-shrink-0 ms-2 me-3"
              type="checkbox"
              checked={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 4;
                })?.minorAccompanyNotPossibleFlg === undefined
                  ? false
                  : data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 4;
                    })?.minorAccompanyNotPossibleFlg || false
              }
              onChange={() => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? { ..._, minorAccompanyNotPossibleFlg: !_.minorAccompanyNotPossibleFlg }
                      : { ..._ };
                  }),
                });
              }}
            />
            <Form.Check.Label>19歳以下同伴不可</Form.Check.Label>
          </Form.Check>
          <Button
            className="ms-5"
            variant="secondary"
            onClick={() => {
              let reflectionText1 = '';
              let reflectionText2 = '';
              let supplementText = '';
              const minVisits: number | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 4;
              })?.minimumVisitsCount;
              const maxVisits: number | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 4;
              })?.maximumVisitsCount;
              const minorAccompanyNotPossibleFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 4;
              })?.minorAccompanyNotPossibleFlg;

              // 未成年者同伴不可かどうか
              if (minorAccompanyNotPossibleFlg) {
                reflectionText2 = '【同伴者】 19歳以下同伴不可';
              } else if (!radioDisableFlg) {
                reflectionText2 = '【同伴者】 19歳以下の同伴は可能';
              }

              // 最小人数と最大人数が入力されている場合
              if (!radioDisableFlg) {
                if (minVisits !== undefined && minVisits !== null && maxVisits !== undefined && maxVisits !== null) {
                  if (minVisits === maxVisits) {
                    reflectionText1 = `${minVisits}名`;
                  } else {
                    reflectionText1 = `${minVisits}名～${maxVisits}名`;
                  }
                  // 最少人数が１人以下の場合
                  if (minVisits <= 1) {
                    supplementText = `${supplementText}※${
                      maxVisits + 1
                    }名以上で来店された場合、謝礼はお支払いできません。\n`;
                  } else if (minVisits === 2) {
                    supplementText = `${supplementText}※1名または${
                      maxVisits + 1
                    }名以上で来店された場合、謝礼はお支払いできません。\n`;
                  } else {
                    supplementText = `${supplementText}※${minVisits - 1}名以下または${
                      maxVisits + 1
                    }名以上で来店された場合、謝礼はお支払いできません。\n`;
                  }
                }
                // 最小人数のみ入力されている場合
                else if (
                  minVisits !== undefined &&
                  minVisits !== null &&
                  (maxVisits === undefined || maxVisits === null)
                ) {
                  reflectionText1 = `${minVisits}名以上`;
                }
                // 最大人数のみ入力されている場合
                else if (
                  (minVisits === undefined || minVisits === null) &&
                  maxVisits !== undefined &&
                  maxVisits !== null
                ) {
                  // 最大人数が１人以下の場合
                  if (maxVisits <= 1) {
                    reflectionText1 = '1名';
                    supplementText = `${supplementText}※2名以上で来店された場合、謝礼はお支払いできません。\n`;
                  } else {
                    reflectionText1 = `${maxVisits}名まで`;
                    supplementText = `${supplementText}※${
                      maxVisits + 1
                    }名以上で来店された場合、謝礼はお支払いできません。\n`;
                  }
                } else {
                  reflectionText1 = '1名以上';
                }
              }
              // 最小人数と最大人数が入力されていない場合
              else {
                reflectionText1 = '1名以上';
              }

              // 未成年者同伴不可かどうか
              // 同伴不可の場合
              if (minorAccompanyNotPossibleFlg) {
                supplementText = `${supplementText}※19歳以下を同伴した場合、謝礼はお支払いできません。\n`;
              }
              // 同伴可能で人数指定ありの場合
              else if (!radioDisableFlg) {
                // 最小人数のみ入力されている場合
                if (minVisits !== undefined && minVisits !== null && (maxVisits === undefined || maxVisits === null)) {
                  supplementText = `${supplementText}※「20歳以上の方が${minVisits}名以上」であれば19歳以下の同伴は可能です。\n`;
                }
                // 最大人数のみ入力されている場合
                else if (
                  (minVisits === undefined || minVisits === null) &&
                  maxVisits !== undefined &&
                  maxVisits !== null
                ) {
                  supplementText = `${supplementText}※「20歳以上の方が1名以上」であれば19歳以下の同伴は可能です。\n`;
                }
                // 最小人数と最大人数が入力されている場合
                else if (
                  minVisits !== undefined &&
                  minVisits !== null &&
                  maxVisits !== undefined &&
                  maxVisits !== null
                ) {
                  supplementText = `${supplementText}※「20歳以上の方が${minVisits}名以上」であれば19歳以下の同伴は可能です。\n`;
                }
              }
              // 同伴可能で人数指定なしの場合
              else {
                supplementText = '※「20歳以上の方が1名以上」であれば19歳以下の同伴は可能です。\n';
              }

              supplementText = `${supplementText}※入店から退店まで、条件を満たした状態で調査してください。`;

              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              copySupplementList = copySupplementList.map((r, i) => {
                return i === 0 ? { ...r, supplement: supplementText } : { ...r };
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 4
                    ? {
                        ...row,
                        beforeApplyContent: `【人数】 ${reflectionText1}\n${reflectionText2}`,
                        afterApplyContent: reflectionText1,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </Form.Group>
      </Form.Group>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
