import React, { useEffect, useState } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import {
  ReceiptPriceApi,
  ReceiptPriceInfoSearchOutputResponse,
  ReceiptPriceModifyingDisplayOutputResponse,
  ReceiptPriceModifyingDisplayFormResponse,
} from '../../../api-client';
import { formatISODate, hasAllValue, isAllEmpty, isOemUser } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { ReceiptPriceModifyConfirmModal } from '../Correction/Modal/ReceiptPriceModifyConfirmModal';
import { RateView } from '../RateView';

interface Props {
  applyId: number;
  apiClientId: number;
  receiptPriceInfo: ReceiptPriceInfoSearchOutputResponse | undefined;
}

// 金額修正の現情報と金額入力画面
export const ReceiptPriceContens: React.FC<Props> = ({ applyId, apiClientId, receiptPriceInfo }) => {
  const [receiptPriceParam, setReceiptPriceParam] = useState<Partial<ReceiptPriceModifyingDisplayFormResponse>>({
    applyId,
    priceAfterModify: undefined,
    workingMemo: undefined,
  });
  const [reciptPriceModifyDisplay, setReciptPriceModifyDisplay] =
    useState<ReceiptPriceModifyingDisplayOutputResponse>();
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const convertRequired = (
    v: Partial<ReceiptPriceModifyingDisplayFormResponse>
  ): ReceiptPriceModifyingDisplayFormResponse => {
    return {
      applyId: v.applyId!,
      priceAfterModify: v.priceAfterModify!,
      workingMemo: v.workingMemo!,
    };
  };

  const confirmParam = () => {
    if (!hasAllValue(false, receiptPriceParam)) {
      setErrorMessage('修正後利用金額または変更理由詳細がブランクです。入力してください。');
      return;
    }
    setErrorMessage('');
    const api = new ReceiptPriceApi();
    (async () => {
      await api
        .receiptPriceModifyingDisplay(convertRequired(receiptPriceParam))
        .then((res: AxiosResponse<ReceiptPriceModifyingDisplayOutputResponse>) => {
          setReciptPriceModifyDisplay(res.data);
          setIsConfirmModal(true);
        });
    })();
  };
  return (
    <>
      {isConfirmModal && (
        <ReceiptPriceModifyConfirmModal
          isModal={isConfirmModal}
          setIsModal={setIsConfirmModal}
          receiptPriceParam={convertRequired(receiptPriceParam)}
          receiptPriceModifyDisplay={reciptPriceModifyDisplay}
          apiClientId={apiClientId}
        />
      )}
      <div>
        <Card>
          <Card.Header>利用金額</Card.Header>
          <Card.Body>
            <h5>ユーザー入力金額</h5>
            <p>{receiptPriceInfo?.userInputAmount}円</p>
            <h5>承認済み利用金額</h5>
            <p>{receiptPriceInfo?.approvedUseAmount}円</p>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>謝礼条件</Card.Header>
          <Card.Body>
            <h5>謝礼タイプ</h5> <p>{receiptPriceInfo?.reward?.rewardType}</p>
            <RateView props={{ ...receiptPriceInfo!.reward }} />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>その他</Card.Header>
          <Card.Body>
            <h5>承認日時</h5>
            <p>
              {receiptPriceInfo?.approvalDate &&
                formatISODate(receiptPriceInfo.approvalDate, 'yyyy年MM月dd日 HH時mm分')}
            </p>
            <h5>謝礼</h5>
            {!isOemUser(apiClientId) && receiptPriceInfo?.pointAmount && (
              <p>ポイント {receiptPriceInfo?.pointAmount}pt</p>
            )}
            {isOemUser(apiClientId) && receiptPriceInfo?.pointAmount && <p>謝礼 {receiptPriceInfo?.pointAmount}円</p>}
            {receiptPriceInfo?.giftTicketAmount && <p>ギフト券 {receiptPriceInfo?.giftTicketAmount}円</p>}
            <h5>OEM手数料</h5>
            {receiptPriceInfo?.oemFee && <p>{receiptPriceInfo?.oemFee}円</p>}
          </Card.Body>
        </Card>

        <Form.Group className="mt-2">
          <h5>修正後利用金額（必須）</h5>
          <Form.Control
            className="mb-2"
            value={receiptPriceParam.priceAfterModify ?? ''}
            type="text"
            onChange={(e) => {
              const numberStr = e.target.value.replace(/^0+|[^\d]/g, '');
              setReceiptPriceParam((prev) => ({
                ...prev,
                priceAfterModify: numberStr === '' ? undefined : Number(numberStr),
              }));
            }}
          />
          <h5>変更理由詳細（必須）</h5>
          <Form.Control
            className="mb-2"
            as="textarea"
            onChange={(e) => setReceiptPriceParam({ ...receiptPriceParam, workingMemo: e.target.value })}
          />
        </Form.Group>
        {errorMessage.length > 0 && (
          <Alert className="me-2 ms-2 mb-4" variant="danger">
            {errorMessage}
          </Alert>
        )}
        <Button onClick={confirmParam}>確認</Button>
      </div>
    </>
  );
};
