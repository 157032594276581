/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerTicketCreateFormResponse } from '../domains';
// @ts-ignore
import { CustomerTicketHistoryOutputResponse } from '../domains';
// @ts-ignore
import { CustomerTicketReturnCreateFormResponse } from '../domains';
// @ts-ignore
import { CustomerTicketReturnListOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * CustomerTicketApi - axios parameter creator
 * @export
 */
export const CustomerTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 有料課金チケットを付与するAPI
         * @summary 有料課金チケット付与
         * @param {CustomerTicketCreateFormResponse} customerTicketCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketCreate: async (customerTicketCreateFormResponse: CustomerTicketCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerTicketCreateFormResponse' is not null or undefined
            assertParamExists('customerTicketCreate', 'customerTicketCreateFormResponse', customerTicketCreateFormResponse)
            const localVarPath = `/api/customer-ticket/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerTicketCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料課金チケット履歴を取得するAPI
         * @summary 有料課金チケット履歴
         * @param {number} customerId 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerTicketId] 
         * @param {number} [applyId] 
         * @param {string} [startAt] 
         * @param {string} [endAt] 
         * @param {number} [customerTicketSearchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketHistory: async (customerId: number, pageNum: number, pagePerItems: number, customerTicketId?: number, applyId?: number, startAt?: string, endAt?: string, customerTicketSearchType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerTicketHistory', 'customerId', customerId)
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('customerTicketHistory', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('customerTicketHistory', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/customer-ticket/history/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerTicketId !== undefined) {
                localVarQueryParameter['customerTicketId'] = customerTicketId;
            }

            if (applyId !== undefined) {
                localVarQueryParameter['applyId'] = applyId;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['startAt'] = startAt;
            }

            if (endAt !== undefined) {
                localVarQueryParameter['endAt'] = endAt;
            }

            if (customerTicketSearchType !== undefined) {
                localVarQueryParameter['customerTicketSearchType'] = customerTicketSearchType;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料課金チケット返還申請を登録するAPI
         * @summary 有料課金チケット返還申請登録
         * @param {Array<CustomerTicketReturnCreateFormResponse>} customerTicketReturnCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketReturnCreate: async (customerTicketReturnCreateFormResponse: Array<CustomerTicketReturnCreateFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerTicketReturnCreateFormResponse' is not null or undefined
            assertParamExists('customerTicketReturnCreate', 'customerTicketReturnCreateFormResponse', customerTicketReturnCreateFormResponse)
            const localVarPath = `/api/customer-ticket/return/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerTicketReturnCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料課金チケット返還用のリストを表示するAPI
         * @summary 有料課金チケット返還申請リスト表示
         * @param {string} [shopIds] 
         * @param {string} [monitorBaseIds] 
         * @param {string} [applyIds] 
         * @param {number} [customerTicketReturnRequestSearchStatusCode] 
         * @param {number} [customerTicketHistoryReasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketReturnList: async (shopIds?: string, monitorBaseIds?: string, applyIds?: string, customerTicketReturnRequestSearchStatusCode?: number, customerTicketHistoryReasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer-ticket/return/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (shopIds !== undefined) {
                localVarQueryParameter['shopIds'] = shopIds;
            }

            if (monitorBaseIds !== undefined) {
                localVarQueryParameter['monitorBaseIds'] = monitorBaseIds;
            }

            if (applyIds !== undefined) {
                localVarQueryParameter['applyIds'] = applyIds;
            }

            if (customerTicketReturnRequestSearchStatusCode !== undefined) {
                localVarQueryParameter['customerTicketReturnRequestSearchStatusCode'] = customerTicketReturnRequestSearchStatusCode;
            }

            if (customerTicketHistoryReasonId !== undefined) {
                localVarQueryParameter['customerTicketHistoryReasonId'] = customerTicketHistoryReasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerTicketApi - functional programming interface
 * @export
 */
export const CustomerTicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerTicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 有料課金チケットを付与するAPI
         * @summary 有料課金チケット付与
         * @param {CustomerTicketCreateFormResponse} customerTicketCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTicketCreate(customerTicketCreateFormResponse: CustomerTicketCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTicketCreate(customerTicketCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料課金チケット履歴を取得するAPI
         * @summary 有料課金チケット履歴
         * @param {number} customerId 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerTicketId] 
         * @param {number} [applyId] 
         * @param {string} [startAt] 
         * @param {string} [endAt] 
         * @param {number} [customerTicketSearchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTicketHistory(customerId: number, pageNum: number, pagePerItems: number, customerTicketId?: number, applyId?: number, startAt?: string, endAt?: string, customerTicketSearchType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTicketHistoryOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTicketHistory(customerId, pageNum, pagePerItems, customerTicketId, applyId, startAt, endAt, customerTicketSearchType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料課金チケット返還申請を登録するAPI
         * @summary 有料課金チケット返還申請登録
         * @param {Array<CustomerTicketReturnCreateFormResponse>} customerTicketReturnCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTicketReturnCreate(customerTicketReturnCreateFormResponse: Array<CustomerTicketReturnCreateFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTicketReturnCreate(customerTicketReturnCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料課金チケット返還用のリストを表示するAPI
         * @summary 有料課金チケット返還申請リスト表示
         * @param {string} [shopIds] 
         * @param {string} [monitorBaseIds] 
         * @param {string} [applyIds] 
         * @param {number} [customerTicketReturnRequestSearchStatusCode] 
         * @param {number} [customerTicketHistoryReasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTicketReturnList(shopIds?: string, monitorBaseIds?: string, applyIds?: string, customerTicketReturnRequestSearchStatusCode?: number, customerTicketHistoryReasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTicketReturnListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTicketReturnList(shopIds, monitorBaseIds, applyIds, customerTicketReturnRequestSearchStatusCode, customerTicketHistoryReasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerTicketApi - factory interface
 * @export
 */
export const CustomerTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerTicketApiFp(configuration)
    return {
        /**
         * 有料課金チケットを付与するAPI
         * @summary 有料課金チケット付与
         * @param {CustomerTicketCreateFormResponse} customerTicketCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketCreate(customerTicketCreateFormResponse: CustomerTicketCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerTicketCreate(customerTicketCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料課金チケット履歴を取得するAPI
         * @summary 有料課金チケット履歴
         * @param {number} customerId 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerTicketId] 
         * @param {number} [applyId] 
         * @param {string} [startAt] 
         * @param {string} [endAt] 
         * @param {number} [customerTicketSearchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketHistory(customerId: number, pageNum: number, pagePerItems: number, customerTicketId?: number, applyId?: number, startAt?: string, endAt?: string, customerTicketSearchType?: number, options?: any): AxiosPromise<CustomerTicketHistoryOutputResponse> {
            return localVarFp.customerTicketHistory(customerId, pageNum, pagePerItems, customerTicketId, applyId, startAt, endAt, customerTicketSearchType, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料課金チケット返還申請を登録するAPI
         * @summary 有料課金チケット返還申請登録
         * @param {Array<CustomerTicketReturnCreateFormResponse>} customerTicketReturnCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketReturnCreate(customerTicketReturnCreateFormResponse: Array<CustomerTicketReturnCreateFormResponse>, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerTicketReturnCreate(customerTicketReturnCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料課金チケット返還用のリストを表示するAPI
         * @summary 有料課金チケット返還申請リスト表示
         * @param {string} [shopIds] 
         * @param {string} [monitorBaseIds] 
         * @param {string} [applyIds] 
         * @param {number} [customerTicketReturnRequestSearchStatusCode] 
         * @param {number} [customerTicketHistoryReasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTicketReturnList(shopIds?: string, monitorBaseIds?: string, applyIds?: string, customerTicketReturnRequestSearchStatusCode?: number, customerTicketHistoryReasonId?: number, options?: any): AxiosPromise<Array<CustomerTicketReturnListOutputResponse>> {
            return localVarFp.customerTicketReturnList(shopIds, monitorBaseIds, applyIds, customerTicketReturnRequestSearchStatusCode, customerTicketHistoryReasonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerTicketApi - object-oriented interface
 * @export
 * @class CustomerTicketApi
 * @extends {BaseAPI}
 */
export class CustomerTicketApi extends BaseAPI {
    /**
     * 有料課金チケットを付与するAPI
     * @summary 有料課金チケット付与
     * @param {CustomerTicketCreateFormResponse} customerTicketCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTicketApi
     */
    public customerTicketCreate(customerTicketCreateFormResponse: CustomerTicketCreateFormResponse, options?: AxiosRequestConfig) {
        return CustomerTicketApiFp(this.configuration).customerTicketCreate(customerTicketCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料課金チケット履歴を取得するAPI
     * @summary 有料課金チケット履歴
     * @param {number} customerId 
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [customerTicketId] 
     * @param {number} [applyId] 
     * @param {string} [startAt] 
     * @param {string} [endAt] 
     * @param {number} [customerTicketSearchType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTicketApi
     */
    public customerTicketHistory(customerId: number, pageNum: number, pagePerItems: number, customerTicketId?: number, applyId?: number, startAt?: string, endAt?: string, customerTicketSearchType?: number, options?: AxiosRequestConfig) {
        return CustomerTicketApiFp(this.configuration).customerTicketHistory(customerId, pageNum, pagePerItems, customerTicketId, applyId, startAt, endAt, customerTicketSearchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料課金チケット返還申請を登録するAPI
     * @summary 有料課金チケット返還申請登録
     * @param {Array<CustomerTicketReturnCreateFormResponse>} customerTicketReturnCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTicketApi
     */
    public customerTicketReturnCreate(customerTicketReturnCreateFormResponse: Array<CustomerTicketReturnCreateFormResponse>, options?: AxiosRequestConfig) {
        return CustomerTicketApiFp(this.configuration).customerTicketReturnCreate(customerTicketReturnCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料課金チケット返還用のリストを表示するAPI
     * @summary 有料課金チケット返還申請リスト表示
     * @param {string} [shopIds] 
     * @param {string} [monitorBaseIds] 
     * @param {string} [applyIds] 
     * @param {number} [customerTicketReturnRequestSearchStatusCode] 
     * @param {number} [customerTicketHistoryReasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTicketApi
     */
    public customerTicketReturnList(shopIds?: string, monitorBaseIds?: string, applyIds?: string, customerTicketReturnRequestSearchStatusCode?: number, customerTicketHistoryReasonId?: number, options?: AxiosRequestConfig) {
        return CustomerTicketApiFp(this.configuration).customerTicketReturnList(shopIds, monitorBaseIds, applyIds, customerTicketReturnRequestSearchStatusCode, customerTicketHistoryReasonId, options).then((request) => request(this.axios, this.basePath));
    }
}
