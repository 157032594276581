import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Card, Table, Tabs, Tab, ToggleButtonGroup, ToggleButton, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { createTestId, uuid, when } from '../../../utils/functions';
// eslint-disable-next-line import/no-cycle
import { MonitorContractRsvInfoApi } from '../../../api-client';
import type { MonitorContractRsvInfoOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ModalConfig, ContractShop, MonitorBase, PRODUCT_TYPE } from '../PostingRequest/PostingRequestPage';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';

const TAB_KEY = {
  NORMAL: 'normal',
  BORDER: 'border',
} as const;

const PRODUCT_TYPE_RETURN = 2;

type TabKey = typeof TAB_KEY[keyof typeof TAB_KEY];

type AdditionalOutput = {
  monitorName: string;
};

export interface State {
  api: MonitorContractRsvInfoApi;
  rsvId: number;
  tabKey: TabKey;
  output: Partial<MonitorContractRsvInfoOutputResponse>;
  additionalOutput: AdditionalOutput;
  disableFlg: boolean;
}

// export interface NormalForm {
//   isOpenAccordion?: boolean;
//   monitorName?: string;
//   goodsType?: string;
//   startAt?: string;
//   spot?: number;
//   stock?: number;
//   soukyakuNum?: number;
//   giftDuration?: number;
//   imageOption?: number;
//   priceRemarks?: string;
// }

// export interface BorderForm {
//   isOpenAccordion?: boolean;
//   monitorName?: string;
//   startAt?: string;
//   endAt?: string;
// }

export const ContractReservationPage: React.VFC = () => {
  const testid = createTestId(ContractReservationPage);
  const state = JSON.parse(localStorage.getItem('key') || '');
  const { rsvId, monitorName } = state;

  const initialState: State = {
    api: new MonitorContractRsvInfoApi(),
    rsvId,
    tabKey: TAB_KEY.NORMAL,
    output: {},
    additionalOutput: { monitorName },
    disableFlg: true,
  };
  const { state: $, mergeState, onChangeSet, useBindSet } = useLargeState<State>(initialState);
  // 編集可能になったら実装
  // const setForms = useBindSet('forms');

  // useEffect(() => {
  //   console.log($);
  // }, [$]);

  useEffect(() => {
    if (!rsvId) return;
    $.api.monitorContractRsvInfo(rsvId).then((res: AxiosResponse<MonitorContractRsvInfoOutputResponse>) => {
      mergeState({ output: res.data, tabKey: res.data.wakumoniFlg ? TAB_KEY.BORDER : TAB_KEY.NORMAL });
    });
  }, []);

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.CONTRACT_RESERVATION}</Title>

      <Tabs
        activeKey={$.tabKey}
        // 編集可能になったら実装
        // onSelect={(key) => {
        //   mergeState({ tabKey: key as TabKey });
        // }}
        className="mb-4"
        data-testid={testid('tabs')}
      >
        {when($.tabKey === 'normal', <Tab eventKey="normal" title="通常モニター" />)}
        {when($.tabKey === 'border', <Tab eventKey="border" title="枠モニター" />)}
      </Tabs>

      {/* 編集可能になったら実装  */}
      {/*  <Form onSubmit={onSave}> */}
      <Form>
        {/* 編集可能になったら実装  */}
        {/* <div className="d-flex justify-content-end mb-4">
          <Button
            type="submit"
            className="ms-2"
            disabled={cr.customerTransferFeeType === undefined || !applicationFormFlg}
            data-testid={testid('save-button')}
          >
            保存
          </Button>
        </div> */}

        <Table className="mb-4" data-testid={testid('table')}>
          <thead>
            <tr>
              <th>モニター名</th>
              <th>商品タイプ</th>
              <th>運用開始日</th>
              {$.tabKey === TAB_KEY.BORDER ? <th>終了日</th> : <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            <tr className="align-middle">
              <td data-testid={testid('name')}>
                <Form.Control
                  required
                  type="text"
                  value={$.additionalOutput.monitorName || ''}
                  disabled={$.disableFlg}
                  readOnly
                />
              </td>
              <td data-testid={testid('productType')}>
                <Form.Select
                  className="w-auto"
                  value={$.output.productType || 0}
                  onChange={(e) => {}}
                  disabled={$.disableFlg}
                >
                  {[...Array(3)].map((_, i) => (
                    <option key={i} value={i}>
                      {PRODUCT_TYPE[i]}
                    </option>
                  ))}
                </Form.Select>
              </td>

              <td data-testid={testid('applicationStartDate')}>
                <Form.Control
                  required
                  type="date"
                  className="w-auto"
                  value={$.output.applicationStartDate || ''}
                  disabled={$.disableFlg}
                  readOnly
                />
              </td>
              {$.tabKey === TAB_KEY.BORDER ? (
                <td data-testid={testid('wakumoniEndAt')}>
                  <Form.Control
                    type="date"
                    className="w-auto"
                    value={$.output.wakumoniEndAt || ''}
                    disabled={$.disableFlg}
                    readOnly
                  />
                </td>
              ) : (
                <td>&nbsp;</td>
              )}
            </tr>
          </tbody>
        </Table>

        <Card className="p-4">
          {$.tabKey === TAB_KEY.NORMAL && (
            <>
              <Form.Group
                className="d-flex align-items-center border-bottom pb-3"
                data-testid={testid('monitorTargetMonth')}
              >
                <Form.Label className="col-4 mb-0">モニター実施月</Form.Label>
                <div className="d-flex flex-wrap gap-2">
                  {Object.entries($.output.monitorTargetMonth || {})
                    .filter(([key]) => key !== 'id')
                    .map(([key, checked], i) => (
                      <ToggleButton
                        key={key}
                        id={key}
                        type="checkbox"
                        variant="outline-primary"
                        value={key}
                        checked={checked || false}
                        onChange={() => {}}
                        style={{ width: '72.7px' }}
                        disabled={$.disableFlg}
                      >
                        {i + 1}
                      </ToggleButton>
                    ))}
                </div>
              </Form.Group>
              <Form.Group
                className="d-flex align-items-center border-bottom py-3"
                data-testid={testid('monitorPeriod')}
              >
                <Form.Label className="col-4 mb-0">モニター期間</Form.Label>
                <Form.Control
                  required
                  type="number"
                  value={$.output.period || ''}
                  min={0}
                  // onChange={crOnChangeSet('period', Number)}
                  className="w-50 me-2"
                  disabled={$.disableFlg}
                />
                か月
              </Form.Group>
            </>
          )}
          {$.output.productType !== PRODUCT_TYPE_RETURN && (
            <Form.Group
              className={`d-flex align-items-center border-bottom ${$.tabKey === TAB_KEY.NORMAL ? 'py-3' : 'pb-3'}`}
            >
              <Form.Label className="col-4 mb-0">請求謝礼(割合/上限)</Form.Label>
              <div className="w-100">
                <ToggleButtonGroup
                  type="radio"
                  name="billingRewardType"
                  data-testid={testid('billingRewardType')}
                  value={$.output.billingRewardType}
                  // onChange={(val) =>
                  //   crMergeState({
                  //     billingRewardType: val,
                  //     billingRewardValue: undefined,
                  //     billingRewardUpperLimit: undefined,
                  //   })
                  // }
                  className="w-100 mb-2"
                >
                  <ToggleButton
                    id="billingRewardType1"
                    variant="outline-primary"
                    value={1}
                    data-testid={testid('billingRewardType1')}
                  >
                    割合
                  </ToggleButton>
                  <ToggleButton
                    id="billingRewardType0"
                    variant="outline-primary"
                    value={0}
                    data-testid={testid('billingRewardType0')}
                  >
                    固定
                  </ToggleButton>
                </ToggleButtonGroup>
                <div className="d-flex align-items-center gap-2 bg-light p-2">
                  {$.output.billingRewardType === 1 && (
                    <>
                      <FloatingLabel className="flex-grow-1" label="割合" data-testid={testid('billingRewardValue')}>
                        <Form.Control
                          required
                          type="number"
                          value={$.output.billingRewardValue || ''}
                          min={0}
                          // onChange={crOnChangeSet('billingRewardValue', Number)}
                          disabled={$.disableFlg}
                        />
                      </FloatingLabel>
                      <span>%</span>

                      <FloatingLabel
                        className="flex-grow-1"
                        label="上限"
                        data-testid={testid('billingRewardUpperLimit')}
                      >
                        <Form.Control
                          type="number"
                          value={$.output.billingRewardUpperLimit || ''}
                          min={0}
                          // onChange={crOnChangeSet('billingRewardUpperLimit', Number)}
                          className="me-2"
                          disabled={$.disableFlg}
                        />
                      </FloatingLabel>
                      <span>円</span>
                    </>
                  )}
                  {$.output.billingRewardType === 0 && (
                    <>
                      <FloatingLabel className="flex-grow-1" label="固定" data-testid={testid('billingRewardValue')}>
                        <Form.Control
                          type="number"
                          value={$.output.billingRewardValue || ''}
                          min={0}
                          // onChange={crOnChangeSet('billingRewardValue', Number)}
                          disabled={$.disableFlg}
                        />
                      </FloatingLabel>
                      <span>円</span>
                    </>
                  )}
                </div>
              </div>
            </Form.Group>
          )}

          <Form.Group
            className={`d-flex align-items-center border-bottom ${
              $.tabKey === TAB_KEY.BORDER && $.output.productType === PRODUCT_TYPE_RETURN ? 'pb-3' : 'py-3'
            }`}
          >
            <Form.Label className="col-4 mb-0">送客手数料(割合/下限～上限)</Form.Label>
            <div className="w-100">
              <ToggleButtonGroup
                type="radio"
                name="customerTransferFeeType"
                value={$.output.customerTransferFeeType}
                // onChange={(val) =>
                //   crMergeState({
                //     customerTransferFeeType: val,
                //     customerTransferFeeValue: undefined,
                //     customerTransferFeeLowerLimit: undefined,
                //     customerTransferFeeUpperLimit: undefined,
                //   })
                // }
                className="w-100 mb-2"
              >
                <ToggleButton
                  id="customerTransferFeeType1"
                  variant="outline-primary"
                  value={1}
                  data-testid={testid('customerTransferFeeType1')}
                >
                  割合
                </ToggleButton>
                <ToggleButton
                  id="customerTransferFeeType0"
                  variant="outline-primary"
                  value={0}
                  data-testid={testid('customerTransferFeeType0')}
                >
                  固定
                </ToggleButton>
              </ToggleButtonGroup>
              <div className="d-flex align-items-center gap-2 bg-light p-2">
                {$.output.customerTransferFeeType === 1 && (
                  <>
                    <FloatingLabel
                      className="flex-grow-1"
                      label="割合"
                      data-testid={testid('customerTransferFeeValue')}
                    >
                      <Form.Control
                        required
                        type="number"
                        value={$.output.customerTransferFeeValue || ''}
                        min={0}
                        // onChange={crOnChangeSet('customerTransferFeeValue', Number)}
                        disabled={$.disableFlg}
                      />
                    </FloatingLabel>
                    <span>%</span>

                    <FloatingLabel className="w-25" label="下限" data-testid={testid('customerTransferFeeLowerLimit')}>
                      <Form.Control
                        required
                        type="number"
                        value={$.output.customerTransferFeeLowerLimit || ''}
                        min={0}
                        // onChange={crOnChangeSet('customerTransferFeeLowerLimit', Number)}
                        className="me-2"
                        disabled={$.disableFlg}
                      />
                    </FloatingLabel>
                    <span>円&nbsp;～</span>
                    <FloatingLabel className="w-25" label="上限" data-testid={testid('customerTransferFeeUpperLimit')}>
                      <Form.Control
                        required
                        type="number"
                        value={$.output.customerTransferFeeUpperLimit || ''}
                        min={0}
                        // onChange={crOnChangeSet('customerTransferFeeUpperLimit', Number)}
                        className="me-2"
                        disabled={$.disableFlg}
                      />
                    </FloatingLabel>
                    <span>円</span>
                  </>
                )}
                {$.output.customerTransferFeeType === 0 && (
                  <>
                    <FloatingLabel
                      className="flex-grow-1"
                      label="固定"
                      data-testid={testid('customerTransferFeeValue')}
                    >
                      <Form.Control
                        required
                        type="number"
                        value={$.output.customerTransferFeeValue || ''}
                        min={0}
                        // onChange={crOnChangeSet('customerTransferFeeValue', Number)}
                        className="me-2"
                        disabled={$.disableFlg}
                      />
                    </FloatingLabel>
                    <span>円</span>
                  </>
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group
            className="d-flex align-items-center border-bottom py-3"
            data-testid={testid('monitorSpotAmount')}
          >
            <Form.Label className="col-4 mb-0">モニターSPOT</Form.Label>
            <Form.Control
              required
              type="number"
              value={$.output.monitorSpotAmount || ''}
              min={0}
              // onChange={crOnChangeSet('monitorSpotAmount', Number)}
              className="me-2"
              disabled={$.disableFlg}
            />
            円
          </Form.Group>

          <Form.Group
            className="d-flex align-items-center border-bottom py-3"
            data-testid={testid('monitorStockAmount')}
          >
            <Form.Label className="col-4 mb-0">モニターSTOCK</Form.Label>
            <Form.Control
              required
              type="number"
              value={$.output.monitorStockAmount || ''}
              min={0}
              // onChange={crOnChangeSet('monitorStockAmount', Number)}
              className="me-2"
              disabled={$.disableFlg}
            />
            円
          </Form.Group>
          <Form.Group
            className="d-flex align-items-center border-bottom py-3"
            data-testid={testid('contractBorderCount')}
          >
            <Form.Label className="col-4 mb-0">送客数</Form.Label>
            <Form.Control
              required
              type="number"
              value={$.output.contractBorderCount || ''}
              min={0}
              // onChange={crOnChangeSet('contractBorderCount', Number)}
              disabled={$.disableFlg}
            />
          </Form.Group>

          {$.output.productType === PRODUCT_TYPE_RETURN && (
            <>
              <Form.Group className="d-flex align-items-center border-bottom py-3">
                <Form.Label className="col-4 mb-0">ギフト券(割合/上限)</Form.Label>
                <div className="w-100">
                  <ToggleButtonGroup
                    type="radio"
                    name="giftTicketRewardType"
                    data-testid={testid('giftTicketRewardType')}
                    value={$.output.giftTicketRewardType}
                    // onChange={(val) => {
                    //   crMergeState({ giftTicketRewardType: val, giftTicketRewardUpperLimit: undefined });
                    // }}
                    className="w-100 mb-2"
                  >
                    <ToggleButton
                      id="giftTicketRewardType1"
                      variant="outline-primary"
                      value={1}
                      data-testid={testid('giftTicketRewardType1')}
                    >
                      割合
                    </ToggleButton>
                    <ToggleButton id="giftTicketRewardType0" variant="outline-primary" value={0}>
                      固定
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <div className="d-flex align-items-center gap-2 bg-light p-2">
                    <FloatingLabel
                      className="flex-grow-1"
                      label="金額"
                      data-testid={testid('giftTicketRewardUpperLimit')}
                    >
                      <Form.Control
                        type="number"
                        className="me-2"
                        value={$.output.giftTicketRewardUpperLimit || ''}
                        min={0}
                        // onChange={crOnChangeSet('giftTicketRewardUpperLimit', Number)}
                        disabled={$.disableFlg}
                      />
                    </FloatingLabel>
                    <span>円</span>
                  </div>
                </div>
              </Form.Group>

              <Form.Group
                className="d-flex align-items-center border-bottom py-3"
                data-testid={testid('giftTokenValidityPeriod')}
              >
                <Form.Label className="col-4 mb-0">ギフト券利用期間</Form.Label>
                <Form.Control
                  type="number"
                  value={$.output.giftTokenValidityPeriod || ''}
                  min={0}
                  // onChange={crOnChangeSet('giftTokenValidityPeriod', Number)}
                  className="me-2"
                  disabled={$.disableFlg}
                />
                日
              </Form.Group>
            </>
          )}

          <>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('storeImageOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">店舗画像オプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.storeImageOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('storeImageOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('imageOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">画像オプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.imageOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('imageOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('stylistNominationOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">月額スタイリスト指名オプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.stylistNominationOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('stylistNominationOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('storeStylistNominationOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">店舗月額スタイリスト指名オプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.storeStylistNominationOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('storeStylistNominationOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('winnerSegmentOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">月額当選者セグメントオプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.winnerSegmentOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('winnerSegmentOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center border-bottom py-3"
              data-testid={testid('storeWinnerSegmentOptionAmount')}
            >
              <Form.Label className="col-4 mb-0">店舗月額当選者セグメントオプション</Form.Label>
              <Form.Control
                type="number"
                value={$.output.storeWinnerSegmentOptionAmount || ''}
                min={0}
                // onChange={crOnChangeSet('storeWinnerSegmentOptionAmount', Number)}
                className="me-2"
                disabled={$.disableFlg}
              />
              円
            </Form.Group>
          </>

          <Form.Group className="d-flex pt-3">
            <Form.Label className="col-4 mb-0">金額備考</Form.Label>
            <Form.Control
              type="text"
              data-testid={testid('priceRemarks')}
              value={$.output.priceRemarks || ''}
              // onChange={crOnChangeSet('priceRemarks')}
              as="textarea"
              rows={4}
              disabled={$.disableFlg}
            />
          </Form.Group>
        </Card>
      </Form>
    </>
  );
};
