import { AxiosResponse } from 'axios';
import { faCopy, faEye, faEyeSlash, faLink, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import {
  DirectMailCopyApi,
  F3CopyApi,
  F4CopyApi,
  F5CopyApi,
  IncResultOutputResponse,
  MailResponse,
} from '../../../api-client';

export interface Props {
  directMailList: MailResponse[];
  f3List: MailResponse[];
  f4List: MailResponse[];
  f5List: MailResponse[];
  setCopyResult: React.Dispatch<React.SetStateAction<IncResultOutputResponse | undefined>>;
  topTabKey: string;
}

export const MailListTable: React.VFC<Props> = ({
  directMailList,
  setCopyResult,
  topTabKey,
  f3List,
  f4List,
  f5List,
}) => {
  const directMailCopyApi = new DirectMailCopyApi();
  const f3MailCopyApi = new F3CopyApi();
  const f4MailCopyApi = new F4CopyApi();
  const f5MailCopyApi = new F5CopyApi();

  const history = useHistory();

  const copyTooltip = (
    <Tooltip id="button-tooltip" style={{ maxWidth: '25rem' }}>
      レコードのコピー
    </Tooltip>
  );

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours());
    return parsedate;
  };

  // 「ダイレクトメール」の場合のTableContents
  const tableContents1 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>NO</th>
          <th>メール件名</th>
          <th>配信日時</th>
          <th>配信モード</th>
          <th>状態</th>
          <th>完了日時</th>
          <th>配信予定数</th>
          <th>配信件数</th>
          <th style={{ textAlign: 'center' }}> </th>
        </tr>
      </thead>
      <tbody>
        {directMailList?.map((row: MailResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.id}</td>
              <td className="text-secondary">
                <Button
                  className="ps-0 pt-0"
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: `${Url.KEISAI.DIRECTMAIL_DETAIL}`,
                      state: { id: row?.id },
                    });
                  }}
                >
                  <p className="mb-0" style={{ borderBottom: 'solid' }}>
                    {row?.emailTitle}
                  </p>
                </Button>
              </td>
              <td>
                {row.sendScheduleTimeStamp == null ? '―' : diff(new Date(row.sendScheduleTimeStamp)).toLocaleString()}
              </td>
              <td>{row.mode === 0 ? '本番配信' : '配信停止'}</td>
              <td>
                {row.status === 0 && '未送信'}
                {row.status === 1 && '送信済み'}
                {row.status === 2 && '停止'}
                {row.status === 3 && 'エラー'}
                {row.status === 4 && '送信中'}
                {row.status === 5 && '緊急停止'}
              </td>
              <td>
                {row.sendCompleteTimeStamp == null ? '―' : diff(new Date(row.sendCompleteTimeStamp)).toLocaleString()}
              </td>
              <td>{row.sendScheduleCount}件</td>
              <td>{row.sendCompleteCount}件</td>
              <td style={{ textAlign: 'center' }}>
                <OverlayTrigger trigger={['hover', 'hover']} placement="top-end" overlay={copyTooltip} rootClose>
                  <Button
                    data-testid="copy"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      directMailCopyApi.directMailCopy(row.id).then((res: AxiosResponse<IncResultOutputResponse>) => {
                        setCopyResult(res.data);
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 「F3」の場合のTableContents
  const tableContents2 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>NO</th>
          <th>メール件名</th>
          <th>配信日時</th>
          <th>配信モード</th>
          <th>状態</th>
          <th>完了日時</th>
          <th>配信予定数</th>
          <th>配信件数</th>
          <th style={{ textAlign: 'center' }}> </th>
        </tr>
      </thead>
      <tbody>
        {f3List?.map((row: MailResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.id}</td>
              <td className="text-secondary">
                <Button
                  className="ps-0 pt-0"
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: `${Url.KEISAI.F3MAIL_DETAIL}`,
                      state: { id: row?.id },
                    });
                  }}
                >
                  <p className="mb-0" style={{ borderBottom: 'solid' }}>
                    {row?.emailTitle}
                  </p>
                </Button>
              </td>
              <td>
                {row.sendScheduleTimeStamp == null ? '―' : diff(new Date(row.sendScheduleTimeStamp)).toLocaleString()}
              </td>
              <td>{row.mode === 0 ? '本番配信' : '配信停止'}</td>
              <td>
                {row.status === 0 && '未送信'}
                {row.status === 1 && '送信済み'}
                {row.status === 2 && '停止'}
                {row.status === 3 && 'エラー'}
                {row.status === 4 && '送信中'}
                {row.status === 5 && '緊急停止'}
              </td>
              <td>
                {row.sendCompleteTimeStamp == null ? '―' : diff(new Date(row.sendCompleteTimeStamp)).toLocaleString()}
              </td>
              <td>{row.sendScheduleCount}件</td>
              <td>{row.sendCompleteCount}件</td>
              <td style={{ textAlign: 'center' }}>
                <OverlayTrigger trigger={['hover', 'hover']} placement="top-end" overlay={copyTooltip} rootClose>
                  <Button
                    data-testid="copy"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      f3MailCopyApi.f3Copy(row.id).then((res: AxiosResponse<IncResultOutputResponse>) => {
                        setCopyResult(res.data);
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 「F4」の場合のTableContents
  const tableContents3 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>NO</th>
          <th>メール件名</th>
          <th>配信日時</th>
          <th>配信モード</th>
          <th>状態</th>
          <th>完了日時</th>
          <th>配信予定数</th>
          <th>配信件数</th>
          <th style={{ textAlign: 'center' }}> </th>
        </tr>
      </thead>
      <tbody>
        {f4List?.map((row: MailResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.id}</td>
              <td className="text-secondary">
                <Button
                  className="ps-0 pt-0"
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: `${Url.KEISAI.F4MAIL_DETAIL}`,
                      state: { id: row?.id },
                    });
                  }}
                >
                  <p className="mb-0" style={{ borderBottom: 'solid' }}>
                    {row?.emailTitle}
                  </p>
                </Button>
              </td>
              <td>
                {row.sendScheduleTimeStamp == null ? '―' : diff(new Date(row.sendScheduleTimeStamp)).toLocaleString()}
              </td>
              <td>{row.mode === 0 ? '本番配信' : '配信停止'}</td>
              <td>
                {row.status === 0 && '未送信'}
                {row.status === 1 && '送信済み'}
                {row.status === 2 && '停止'}
                {row.status === 3 && 'エラー'}
                {row.status === 4 && '送信中'}
                {row.status === 5 && '緊急停止'}
              </td>
              <td>
                {row.sendCompleteTimeStamp == null ? '―' : diff(new Date(row.sendCompleteTimeStamp)).toLocaleString()}
              </td>
              <td>{row.sendScheduleCount}件</td>
              <td>{row.sendCompleteCount}件</td>
              <td style={{ textAlign: 'center' }}>
                <OverlayTrigger trigger={['hover', 'hover']} placement="top-end" overlay={copyTooltip} rootClose>
                  <Button
                    data-testid="copy"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      f4MailCopyApi.f4Copy(row.id).then((res: AxiosResponse<IncResultOutputResponse>) => {
                        setCopyResult(res.data);
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 「F5」の場合のTableContents
  const tableContents4 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>NO</th>
          <th>メール件名</th>
          <th>配信日時</th>
          <th>配信モード</th>
          <th>状態</th>
          <th>完了日時</th>
          <th>配信予定数</th>
          <th>配信件数</th>
          <th style={{ textAlign: 'center' }}> </th>
        </tr>
      </thead>
      <tbody>
        {f5List?.map((row: MailResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.id}</td>
              <td className="text-secondary">
                <Button
                  className="ps-0 pt-0"
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: `${Url.KEISAI.F5MAIL_DETAIL}`,
                      state: { id: row?.id },
                    });
                  }}
                >
                  <p className="mb-0" style={{ borderBottom: 'solid' }}>
                    {row?.emailTitle}
                  </p>
                </Button>
              </td>
              <td>
                {row.sendScheduleTimeStamp == null ? '―' : diff(new Date(row.sendScheduleTimeStamp)).toLocaleString()}
              </td>
              <td>{row.mode === 0 ? '本番配信' : '配信停止'}</td>
              <td>
                {row.status === 0 && '未送信'}
                {row.status === 1 && '送信済み'}
                {row.status === 2 && '停止'}
                {row.status === 3 && 'エラー'}
                {row.status === 4 && '送信中'}
                {row.status === 5 && '緊急停止'}
              </td>
              <td>
                {row.sendCompleteTimeStamp == null ? '―' : diff(new Date(row.sendCompleteTimeStamp)).toLocaleString()}
              </td>
              <td>{row.sendScheduleCount}件</td>
              <td>{row.sendCompleteCount}件</td>
              <td style={{ textAlign: 'center' }}>
                <OverlayTrigger trigger={['hover', 'hover']} placement="top-end" overlay={copyTooltip} rootClose>
                  <Button
                    data-testid="copy"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      f5MailCopyApi.f5Copy(row.id).then((res: AxiosResponse<IncResultOutputResponse>) => {
                        setCopyResult(res.data);
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  return (
    <>
      <Table>
        {topTabKey === '1' && tableContents1}
        {topTabKey === '2' && tableContents2}
        {topTabKey === '3' && tableContents3}
        {topTabKey === '4' && tableContents4}
      </Table>
    </>
  );
};
