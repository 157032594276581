import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { uuid } from '../../../../../utils/functions';
import { DisplayPointType } from '../../../../pages/Correction/constants';
import { getMonitorRuleName } from '../../../../pages/Correction/utils';
import { CommonPanel } from './CommonPanel';
import { MONITOR_RULE_TYPE } from '../../../../../Constants';

export const MonitorRuleSection: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  return (
    <Card body>
      {($.res_correctionCheckDisplay?.monitorRuleList ?? []).map(
        (monitorRule) =>
          // 調査証明の場合は除外する。別の項目があるため
          monitorRule.type !== MONITOR_RULE_TYPE.INVESTIGATION_EVIDENCE && (
            <Fragment key={uuid()}>
              <CommonPanel
                label={monitorRule.typeName}
                displayPoint={{ type: DisplayPointType.MONITOR_RULE, targetId: monitorRule.monitorRuleId }}
                displayPointMonitorRuleType={{ type: DisplayPointType.MONITOR_RULE_TYPE, targetId: monitorRule.type }}
                monitorRule={monitorRule}
              />
              <hr />
            </Fragment>
          )
      )}
    </Card>
  );
};
