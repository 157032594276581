import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldPathValue } from 'react-hook-form/dist/types/utils';
import { FieldError, Path, UnpackNestedValue, useController, useFormContext } from 'react-hook-form';
import sass from '../../scss/molecules/billingCommon.module.scss';

interface Props<T> {
  className?: string;
  registerName: Path<T>;
  labelName: string;
}

/**
 * FormProviderでラップしてから使う
 * @param className
 * @param registerName
 * @param labelName
 * @constructor
 */
export const BillingCommonCheckBoxWithLabel = <T,>({ className, registerName, labelName }: Props<T>) => {
  const { register, control, setValue } = useFormContext<T>();
  const {
    fieldState: { error },
  } = useController({ name: registerName, control: control });
  return (
    <Col className="col-6">
      {error !== undefined && <span className={sass.errorMessage}>{(error as FieldError).message}</span>}
      <Form.Group className={`d-flex justify-content-start ${className}`}>
        <Form.Label htmlFor={registerName.toString()} style={{ margin: '0.5rem', width: '37%' }}>
          {labelName}
        </Form.Label>
        <Form.Check
          id={registerName.toString()}
          type="switch"
          style={{ marginTop: '0.5rem' }}
          {...register(registerName)}
          onChange={(e) => setValue(registerName, e.target.checked as UnpackNestedValue<FieldPathValue<any, Path<T>>>)}
        />
      </Form.Group>
    </Col>
  );
};
